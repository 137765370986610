@charset "utf-8";
.m-txt-fs12{
  font-size: 12px;

  @include sp{
    @include vw(20);
    line-height: 2;
  }
}

.m-txt-fs13{
  font-size: 14px;
  line-height: 1.7;

  @include sp{
    @include vw(24);
    line-height: 2;
  }
}

.m-txt-fs14{
  font-size: 14px;
  line-height: 1.7;

  @include sp{
    @include vw(24);
    line-height: 2;
  }
}

.m-txt-fs14B{
  font-size: 14px;
  line-height: 1.7;

  @include sp{
    @include vw(26);
    line-height: 2;
    letter-spacing: 0.05em;
  }
}

.m-txt-fs14C{
  font-size: 14px;
  line-height: 1.7;

  @include sp{
    @include vw(26);
    line-height: 2;
  }
}

.m-txt-fs14D{
  font-size: 14px;
  line-height: 2.14;

  @include sp{
    @include vw(26);
    line-height: 1.75;
  }
}

.m-txt-fs14E{
  font-size: 14px;
  line-height: 1.7;

  @include sp{
    @include vw(26);
    line-height: 2;
  }
}

.m-txt-fs14EL{
  font-size: 14px;
  line-height: 1.7;
  text-align: left;

  @include sp{
    @include vw(26);
    line-height: 2;
  }
}

.m-txt-fs14F{
  font-size: 14px;
  line-height: 1.7;

  @include sp{
    @include vw(24);
    line-height: 1.75;
  }
}

.m-txt-fs15{
  font-size: 15px;
  line-height: 2;
  letter-spacing: 0.05em;

  @include sp{
    @include vw(26);
    line-height: 1.6;
  }
}

.m-txt-fs15B{
  font-size: 15px;
  line-height: 1.4;

  @include sp{
    @include vw(24);
    line-height: 1.41;
  }
}

.m-txt-fs15C{
  font-size: 15px;
  line-height: 2;

  @include sp{
    @include vw(24);
    line-height: 1.5;
  }
}

.m-txt-fs15D{
  font-size: 15px;
  line-height: 2;

  @include sp{
    @include vw(24);
    line-height: 1.66;
  }
}

.m-txt-fs15E{
  font-size: 15px;
  line-height: 1.875;

  @include sp{
    @include vw(26);
    line-height: 2;
  }
}

.m-txt-fs15F{
  font-size: 15px;
  line-height: 2;
  &--cm{
    font-family:"Gill Sans Nova W01 Light"
  }

  @include sp{
    @include vw(26);
    line-height: 1.66;
    letter-spacing: 0.05em;
  }
}

.m-txt-fs15G{
  font-size: 15px;
  line-height: 2.8;

  @include sp{
    @include vw(26);
    line-height: 1.61;
  }
}

.m-txt-fs15H{
  font-size: 15px;
  line-height: 1.6;

  @include sp{
    @include vw(26);
    line-height: 1.61;
  }
}

.m-txt-fs15I{
  font-size: 15px;
  line-height: 2;

  @include sp{
    @include vw(26);
    line-height: 1.5;
  }
}

.m-txt-fs15J{
  font-size: 15px;
  line-height: 2;
  font-weight:bold;
  @include sp{
    @include vw(26);
    line-height: 1.5;
  }
}

.m-txt-fs15K{
  font-size: 15px;
  font-weight:bold;
  @include sp{
    @include vw(24);
  }
  &--white{
    color: $color_04;
  }
}

.m-txt-fs16{
  font-size: 16px;

  @include sp{
    @include vw(30);
  }
}

.m-txt-fs16B{
  font-size: 16px;
  line-height: 1.875;

  @include sp{
    @include vw(24);
  }
}

.m-txt-fs16C{
  font-size: 16px;
  line-height: 1.875;

  @include sp{
    @include vw(28);
    line-height: 1.5;
  }
}

.m-txt-fs16D{
  font-size: 16px;

  @include sp{
    @include vw(26);
    line-height: 1.66;
  }
}

.m-txt-fs16E{
  font-size: 16px;
  line-height: 1.875;

  @include sp{
    @include vw(26);
    line-height: 1.653;
    letter-spacing: 0.05em;
  }
}

.m-txt-fs16F{
  font-size: 16px;
  line-height: 1.5;

  @include sp{
    @include vw(26);
  }
}

.m-txt-fs16G{
  font-size: 16px;

  @include sp{
    @include vw(26);
  }
}

.m-txt-fs16H{
  font-size: 16px;

  @include sp{
    margin-top: 2px;
    @include vw(30);
  }
}

.m-txt-fs18{
  font-size: 18px;

  @include sp{
    @include vw(30); 
  }
}

.m-txt-fs20{
  font-size: 20px;

  @include sp{
    @include vw(30); 
  }
}

.m-txt-fs20B{
  font-size: 20px;

  @include sp{
    @include vw(28);
  }
}

.m-txt-fs20D{
  font-size: 20px;

  @include sp{
    @include vw(34);
  }
}

.m-txt-fs20E{
  font-size: 20px;

  @include sp{
    @include vw(26);
  }
}

.m-txt-fs20F{
  font-size: 20px;

  @include sp{
    @include vw(26);
    line-height: 1.8em
  }
}

.m-txt-fs24{
  font-size: 24px;

  @include sp{
    @include vw(30);
  }
}

.m-txt-fs24B{
  font-size: 24px;

  @include sp{
    @include vw(40);
  }
}

.m-txt-fs28{
  font-size: 28px;

  @include sp{
    @include vw(30);
  }
}

.m-txt-fs28B{
  font-size: 28px;

  @include sp{
    @include vw(40);
  }
}

.m-txt-fs30{
  font-size: 30px;

  @include sp{
    @include vw(40);
  }
}

.m-txt-fs30B{
  font-size: 30px;

  @include sp{
    @include vw(50);
  }
}

.m-txt-fs30C{
  font-size: 30px;

  @include sp{
    @include vw(38);
  }
}

.m-txt-bold{
  font-weight: bold;
}

.m-txt-center{
  text-align: center;
}

.m-txt-left{
  text-align: left;
}

.m-txt-right{
  text-align: right;
}

.m-txt-spRight{
  @include sp{
    text-align: right;
  }
}

.m-txt-spCenter{
  @include sp{
    text-align: center;
  }
}

.m-txt-indent{
  padding-left: 1em;
  text-indent: -1em;
}