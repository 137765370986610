@charset "utf-8";
.m-interviewBox{
    display: flex;
    width: 100%;

    @include sp{
        display: block;
    }

    &__link{
        display: inline-block;
        height: 100%;
        width: 100%;

        @include sp{
            height: 407/750*100vw;
        }

        &:hover{
            opacity: 1;
        }
    }

    &__left{
        width: 50%;
        height: 355px;

        // 画面大きい時は隅まで表示
        @media screen and (max-width: 1600px) {
            background-position: center 63%;
            background-size: 104%;
        }

        img{
            width: 100%;
        }

        &--coffeemaker01{
            background-image: url(/assets/img/enjoyment/coffeemaker/coffeemaker_interview01.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            @include sp{
                width: 100%;
                height: 428/750*100vw;
                background-image: url(/assets/img/enjoyment/coffeemaker/coffeemaker_interview01_sp.jpg);
            }
        }

        &--coffeemaker02{
            background-image: url(/assets/img/enjoyment/coffeemaker/coffeemaker_interview02.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            @include sp{
                width: 100%;
                height: 428/750*100vw;
                background-image: url(/assets/img/enjoyment/coffeemaker/coffeemaker_interview02_sp.jpg);
            }
        }
    }

    &__right{
        background-color: $color_07;
        width: 50%;
        position: relative;
        height: 355px;

        @include sp{
            width: 100%;
            height: 407/750*100vw;
        }
    }

    &__rightInner{
        padding: 11% 13% 0 10%;

        @include sp{
            padding: 40/750*100vw 69/750*100vw 114/750*100vw 51/750*100vw;
            
        }
    }

    &__rightMore{
        position: absolute;
        right: 120px;
        bottom: 48/1366*100vw;

        @include sp{
            bottom: 45/750*100vw;
            right: 160/750*100vw;
        }
    }

    &__lineLink{
        position: relative;
        color: $color_04;
        cursor: pointer;
        transition:0.5s all;
        text-align: right;
        width: 90px;
        display: inline-block;
        font-size: 15px;

        @include sp{
            @include vw(24);
            width: 160/750*100vw;
        }

        &::after{
            content: '';
            position: absolute;
            top: 50%;
            right: -60px;
            width: 100%;
            height: 1px;
            transform: scaleX(0.4);
            transform-origin: right top;
            background-color: $color_04;
            transition: all .3s ease;

            @include sp{
                right: -90/750*100vw;
            }
        }

        &.is-active{
            letter-spacing: 0.2em;
            transition:0.5s all;

            &::after{
                transform: scaleX(0.5);
                background-color: $color_02;
            }
        }
    }
}