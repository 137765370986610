@charset "utf-8";

.p-dp0{
    padding: 80px 0 160px;

    @include sp{
        padding: 100/750*100vw 0 180/750*100vw;
    }

    &__type{
        padding-bottom: 30px;

        &:last-of-type{
            padding-bottom: 0;
        }
    }

    &__sec{
        margin-bottom: 70px;

        &:last-of-type{
            margin-bottom: 0;
        }

        @include sp{
            
        }
    }

    &__secInner{
        margin-top: 40px;
    }

    &__flex{
        display: flex;
        justify-content: space-between;

        @include sp{
            display: block;
        }
    }

    &__lead{
        font-size: 14px;

        @include sp{
            @include vw(26);
        }
    }
}

.p-dp{
    padding: 0px;

    @include sp{
        padding: 30/750*100vw 0 0;
    }
}

.p-dpBody{
    max-width: 580px;

    margin: 0 auto;

    @include sp{
        max-width: 650/750*100vw;
    }

    &__sec{
        position: relative;
    }

    &__flex{
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include sp{
            display: block;
        }
    }
    
    &__boxCont{
        padding-left: 55px;
        width: 100%;

        @include sp{
            width: 100%;
            padding-left: 62/750*100vw;
        }

        &--top{
            padding-left: 35px;

            @include sp{
                padding-left: 62/750*100vw;
            }
        }
    }
    &__box{
        padding-bottom: 25px;

        @include sp{
            padding-bottom: 80/750*100vw;
        }

        &--last{
            @include sp{
                padding-bottom: 160/750*100vw;
            }
        }
    }

    &__head1{
        position: relative;
        &--hd1{
            padding-left: 5px;
            padding-bottom: 20px;
            font-size: 22px;
            font-weight: bold;

            @include sp{
                @include vw(34);
            }
    
        }

        &--hd2{
            margin-left: 20px;
            padding-bottom: 10px;
            font-size: 20px;
            font-weight: bold;

            @include sp{
                @include vw(30);
            }
        }

        a{
            font-size: 20px;
            font-weight: bold;

            @include sp{
                @include vw(28);
            }
        }
    }    

    &__list{
        margin-top: 16px;
    }

    &__item2{
        position: relative;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
        margin-left:35px;

        @include sp{
            @include vw(28);
            margin-bottom: 24/750*100vw;
            padding-left: 24/750*100vw;
        }
    }

    ul li {
        list-style-type: disc;
        }
    ul li::before {
    display: inline;
    }

}