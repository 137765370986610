@charset "utf-8";

.p-sitemap{
    padding: 100px 0 160px;

    @include sp{
        padding: 137/750*100vw 0 0;
    }
}

.p-sitemapBody{
    max-width: 948px;
    margin: 0 auto;

    @include sp{
        max-width: 650/750*100vw;
    }

    &__sec{
        position: relative;
        &::before{
            content: '';
            border-left: 1px solid #000;
            position: absolute;
            left: 0;
            top: 16px;
            width: 1px;
            height: 100%;

            @include sp{
                border-left: 1px solid #707070;
            }
        }
    }

    &__flex{
        display: flex;
        justify-content: space-between;

        @include sp{
            display: block;
        }
    }
    
    &__boxCont{
        padding-left: 55px;
        width: 50%;

        &:nth-of-type(2) {
            padding-left: 110px;
          }

        @include sp{
            width: 100%;
            padding-left: 62/750*100vw;
        }

        &--top{
            padding-left: 35px;

            @include sp{
                padding-left: 62/750*100vw;
            }
        }
    }
    &__box{
        padding-bottom: 60px;

        @include sp{
            padding-bottom: 80/750*100vw;
        }

        &--last{
            @include sp{
                padding-bottom: 160/750*100vw;
            }
        }
    }
    &__head{
        position: relative;


        font-size: 20px;
        font-weight: bold;
        @include sp{
            @include vw(34);
        }

        &::before{
            content: '';
            position: absolute;
            top: 16px;
            left: -55px;
            transform: translateY(-50%);
            width: 40px;
            height: 1px;
            background: #000;

            @include sp{
                left: -60/750*100vw;
                top: 28/750*100vw;
                width: 39/750*100vw;
                background-color: #707070;
            }
        }

        &--top{
            &::after{
                @include sp{
                    content: '';
                    height: 1px;
                    width: 39/750*100vw;
                    background-color: #707070;
                    position: absolute;
                    top: 28/750*100vw;
                    left: -100/750*100vw;
                }
            }
        }

        a{
            font-size: 20px;
            font-weight: bold;

            @include sp{
                @include vw(34);
            }
        }

    }

    &__list{
        margin-top: 16px;
    }
    &__item{
        position: relative;
        font-size: 16px;
        font-weight: bold;
        padding-left: 12px;
        margin-bottom: 12px;

        @include sp{
            @include vw(30);
            margin-bottom: 24/750*100vw;
            padding-left: 24/750*100vw;
        }

            &::before{
            content: '-';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

    }
}

// 生産終了ページ

.p-sitemap_dp_TopNavi {
    max-width: 850px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;  
    flex-wrap: wrap;
    align-items: stretch;
    @include sp{
        width: 90%;
    }

    &__li {
        width: 25%;
        padding: 9px;
        // background-color: $color_07;
        @include sp{
            width: 50%;
            padding: 1px;
        }
    }

    &__link {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        color: $color_04;
        background-color: $color_07;
        @include sp{
            padding: 0.5rem 0.2rem;
        }
    }
}

.p-sitemap_dp{
    padding: 0 0 80px;

    @include sp{
        padding: 30/750*100vw 0 80/750*100vw;
    }
}

.p-sitemap_dpp{
    padding: 0 0 80px;

    @include sp{
        padding: 30/750*100vw 0 80/750*100vw;
    }
}


.p-sitemap_dp_Body {
    max-width: 948px;
    margin: 0 auto;

    @include sp{
        max-width: 650/750*100vw;
    }

    &__scrollbar {
        @include sp{
            width: 100%;
            height: 10px;
            overflow-x: scroll;
            overflow-y: hidden;
            margin-top: 10px;
            // -webkit-overflow-scrolling: touch; // スマホでスクロールバーが常に表示される
            // overflow-scrolling: touch; // スマホでスクロールバーが常に表示される
        }
        
        .inner {
            @include sp{
                width: 500px;
                height: 1px;
            }
        }
    }

    
    &__tableWrp {
        width: 100%;
        @include sp{
            width: 100%;
            overflow-x: scroll;
            padding-bottom: 15px; // スクロールバーを外側へ出す処理
            // -webkit-overflow-scrolling: touch; // スマホでスクロールバーが常に表示される
            // overflow-scrolling: touch; // スマホでスクロールバーが常に表示される
        }

        .inner {
            @include sp{
                width: 500px;
                // height: 200px;
                margin-bottom: 10px;
                background-color: #F9F8F6;
            }
        }
    }

    &__table {
        width: 100%;
        border-collapse:collapse;
        border-spacing: 0;
        table-layout: fixed;

        @include sp{
            width: 140vw;
            left: -1;
        }
        
        th {
            padding: 0.3rem 0.7rem;
            text-align: left;
            vertical-align: middle;
            background-color:#F0F0F0;
            border: 1px solid #C7C7C7;

            &.pronum {
                width: 31%;
                @include sp{
                    width: 25%;
                }
            }

            &.support {
                width: 17%;
                @include sp{
                    width: 25%;
                }
            }

            &.download {
                width: 17%;
                @include sp{
                    width: 23%;
                }
            }
        }

        td {
            padding: 0.3rem 0.7rem;
            vertical-align: middle;
            background-color: #fff;
            border: 1px solid #C7C7C7;
        }

        .productName_top {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
            background: none;
            border-left: none;
            border-right: none;
            background-color:#F0F0F0;

            @include sp{
                width: 27%;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-left: 1px solid #C7C7C7;
                border-right: 1px solid #C7C7C7;
                background-color:#F0F0F0;
                z-index: -1;
            }
        }

        .productName {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
            background: none;
            border-left: none;
            border-right: none;

            @include sp{
                width: 27%;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-left: 1px solid #C7C7C7;
                border-right: 1px solid #C7C7C7;
                background: #fff;
                z-index: -1;
            }
        }

        .tdnowrap {
            white-space: nowrap;
        }
    }

    // スクロールバーのスタイル
    // .p-sitemap_dp_Body__scrollbar::-webkit-scrollbar,
    // .p-sitemap_dp_Body__tableWrp::-webkit-scrollbar {
    //     height: 5px;
    // }
    // .p-sitemap_dp_Body__scrollbar::-webkit-scrollbar-track,
    // .p-sitemap_dp_Body__tableWrp::-webkit-scrollbar-track {
    //     border-radius: 2px;
    // }
    // .p-sitemap_dp_Body__scrollbar::-webkit-scrollbar-thumb,
    // .p-sitemap_dp_Body__tableWrp::-webkit-scrollbar-thumb {
    //     border-radius: 2px;
    //     background-color: #e7e7e7;
    // }

    // リンク先、固定ヘッダによるズレの調整（1番目用）
    &__link-location-adjust {
        position: relative;
        display: block;
        margin-top: -140px;
        padding-top: 140px;
        width: 1px; // 前要素の選択不可を回避
        z-index: -100;

        @include sp{
            // margin-top: -140px;
            // padding-top: 140px;
        }
        
    }

    // リンク先、固定ヘッダによるズレの調整（2番目以降用）
    &__link-location-adjust2 {
        position: relative;
        display: block;
        margin-top: -70px;
        padding-top: 70px;
        width: 1px; // 前要素の選択不可を回避
        z-index: -100;

        @include sp{
            margin-top: -110px;
            padding-top: 110px;
        }
        
    }

    
}

.p-sitemap_dpp_Body {
    max-width: 948px;
    margin: 0 auto;

    @include sp{
        max-width: 650/750*100vw;
    }

    &__scrollbar {
        @include sp{
            width: 100%;
            height: 10px;
            overflow-x: scroll;
            overflow-y: hidden;
            margin-top: 10px;
        }
        
        .inner {
            @include sp{
                width: 500px;
                height: 1px;
            }
        }
    }

    
    &__tableWrp {
        width: 100%;
        @include sp{
            width: 100%;
            overflow-x: scroll;
            padding-bottom: 15px; // スクロールバーを外側へ出す処理
        }

        .inner {
            @include sp{
                width: 500px;
                margin-bottom: 10px;
                background-color: #F9F8F6;
            }
        }
    }

    &__table {
        width: 100%;
        border-collapse:collapse;
        border-spacing: 0;
        table-layout: fixed;

        @include sp{
            width: 111vw;
            left: -1;
        }
        
        th {
            padding: 0.3rem 0.7rem;
            text-align: left;
            vertical-align: middle;
            background-color:#F0F0F0;
            border: 1px solid #C7C7C7;

            &.productName_pro {
                width: 40%;
                @include sp{
                    width: 34%;
                }
            }
            
            &.download_pro {
                width: 17%;
                @include sp{
                    width: 32%;
                }
            }
        }

        td {
            padding: 0.3rem 0.7rem;
            vertical-align: middle;
            background-color: #fff;
            border: 1px solid #C7C7C7;
        }

        .brand {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
            background: none;
            border-left: none;
            border-right: none;
            background-color:#F0F0F0;

            @include sp{
                width: 34%;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-left: 1px solid #C7C7C7;
                border-right: 1px solid #C7C7C7;
                background-color:#F0F0F0;
                z-index: -1;
            }
        }

        .brandName {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
            background: none;
            border-left: none;
            border-right: none;

            @include sp{
                width: 34%;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-left: 1px solid #C7C7C7;
                border-right: 1px solid #C7C7C7;
                background: #fff;
                z-index: -1;
            }
        }

        .tdnowrap {
            white-space: nowrap;
        }
    }


    // リンク先、固定ヘッダによるズレの調整（1番目用）
    &__link-location-adjust {
        position: relative;
        display: block;
        margin-top: -140px;
        padding-top: 140px;
        width: 1px; // 前要素の選択不可を回避
        z-index: -100;

        
    }

    // リンク先、固定ヘッダによるズレの調整（2番目以降用）
    &__link-location-adjust2 {
        position: relative;
        display: block;
        margin-top: -70px;
        padding-top: 70px;
        width: 1px; // 前要素の選択不可を回避
        z-index: -100;

        @include sp{
            margin-top: -110px;
            padding-top: 110px;
        }
        
    }

    
}

