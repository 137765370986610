@charset "utf-8";

.p-prodList{
    display: flex;
    position: relative;
    padding-top: 80px;

    @include sp{
        padding-top: 0;
    }

    &--dc{
        background: url(/assets/img/common/bg_dc.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;

        @include sp{
            background: url(/assets/img/common/bg_dc_sp.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
            padding-bottom: 800/750*100vw;
        }
    }

    &--mk{
        background: url(/assets/img/common/bg_mk.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right bottom;

        @include sp{
            background: url(/assets/img/common/bg_mk_sp.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right bottom;
            padding-bottom: 800/750*100vw;
        }
    }

    &--zh{
        background: url(/assets/img/common/bg_zh.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;

        @include sp{
            background: url(/assets/img/common/bg_zh_sp.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left top;
        }
    }

    &--pp{
        background: url(/assets/img/common/bg_pp.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right top;

        @include sp{
            background: url(/assets/img/common/bg_pp_sp.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: right top;
            padding-bottom: 800/750*100vw;
        }
    }
    // 検索(左)
    &__search{
        width: 354px;
        position: sticky;
        top: 77px;
        height: 110vh;

        @include sp{
            position: fixed;
            z-index: 99;
            width: 666/750*100vw;
            height: 200vh;
            top: 370/750*100vw;
            transition: all 0.7s;
            transform: translate(-666/750*100vw);

            &.is-open{
                transform: translate(0);
            }
        }
    }

    &__searchCont{
        background-color: #fff;
    }

    &__searchScroll{
        overflow-y: scroll;
        height: 100vh;
        background-color: #fff;

        @include sp{
            overflow:visible;
            height: 200vh;
            border-right: none;
            margin-right: 0;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 282px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #C7C7C7;
            height: 282px;
            width: 6px;
            margin-right: 6.5px;
            border: solid 5px transparent;
        }

    }

    &__searchInner{
        width: 262px;
        margin: 0 auto;
        padding: 77px 0 80px;
        background-color: #fff;

        @include sp{
            padding: 0 10/750*100vw 244/750*100vw 0;
            width: 100%;
            overflow-y: scroll;
            height: 200vh;        }
    }

    &__searchBg{
        @include sp{
            display: none;
            background-color: rgba(0,0,0,.65);
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }

    &__searchTti{
        @include sp{
            border-bottom: 1px solid $color_06;
            width: 666/750*100vw;
            padding: 30/750*100vw 0 25/750*100vw 50/750*100vw;
            position: relative;
            text-align: left;
        }
    }

    &__closeBtn{
        display: inline-block;
        width: 70/670*100vw;
        height: 70/670*100vw;
        position: absolute;
        right: 30/670*100vw;
        top: 30%;
        -webkit-tap-highlight-color:rgba(0,0,0,0);

        span{
            display: inline-block;
            vertical-align: middle;
            color: $color_05;
            line-height: 1;
            width: 70/670*100vw;
            height: 1px;
            background: currentColor;
            border-radius: 0.1em;
            transform: rotate(45deg);

            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: inherit;
                border-radius: inherit;
                transform: rotate(90deg);
            }
        }
    }

    &__searchItemCont{
        @include sp{
            padding-top: 40/750*100vw;
            padding-bottom: 600/750*100vw;
            width: 100%;
            margin: 0 auto;
            overflow-y: scroll;
            height: 100vh;
            border-right: 1px solid #C7C7C7;
        }
    }

    &__searchItem{
        margin-bottom: 10px;

        @include sp{
            margin-bottom:18/750*100vw;
        }
    }

    // &__open{
    //     display: block;
    //     position: absolute;
    //     top: 24px;
    //     transform: translateX(257px);

    //     @include sp{
    //         top: 83/750*100vw;
    //         transform: translateX(470/750*100vw);
    //     }
    // }

    // 商品一覧(右)

    &__list{
        width: calc(100% - 354px);
        padding: 0 58px 193px 70px;
        position: relative;

        @include sp{
            width: 100%;
            padding: 150/750*100vw 0 160/750*100vw;
        }

        &--pager{
            @include sp{
                padding-top: 150/750*100vw;

            }
        }
    }

    &__pager{
        position: absolute;

        &--top{
            top: -50px;
            @include sp{
                top: 280/750*100vw;
                left: 30/750*100vw;
            }
        }

        &--bottom{
            bottom: 160px;

            @include sp{
                bottom: 80/750*100vw;
                left: 30/750*100vw;
            }
        }
    }

    &__breadcrumb{
        position: absolute;
        top: -80px;
        right: 40px;

        @include sp{
            top: 0;
            right: 30/750*100vw;
        }
    }

    &__listInner{
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include sp{
            width: 690/750*100vw;
            margin: 0 auto;
        }
    }

    // 見出し
    &__headTxt{
        display: flex;
        flex-flow: column;
        font-size: 18px;
        margin-bottom: 30px;
        font-weight: lighter;

        &--dc{
            color:#fff;
        }

        h1{
            font-size: 18px;
            font-weight: 700;
            }
        h2{
            font-size: 14px;
            font-weight: normal;
            }
        a {
            font-size: 14px;
            text-decoration: underline;
            }

        &--wh{
            color:#fff;
            a {
              font-size: 14px;
              color: #fff;
              text-decoration: underline;
            }
            p {
                font-size: 14px;
                color: #fff;
                margin-top:30px;
              }
            }

        @media screen and (max-width: 750px){
            width:92vw;
            margin:0 auto 30px;
            @include sp{
                margin:  0vw auto 30/750*100vw;
            }


            h1{
                @include vw(30);
                font-weight: 700;
            }
            h2{
                @include vw(20);
                font-weight: normal;
            }
            a {
                font-size: 2.66667vw;
            }
            p {
                font-size: 2.66667vw;
                margin-top:10vw;
            }
        }
    }

    .selectdiv {
        position: relative;
        float: left;
        min-width: 380px;
        margin: 0 0 30px;

        @include sp{
            min-width: 640/750*100vw;
            margin: 0vw 0vw 30/750*100vw 30/750*100vw;
        }

      }

      select::-ms-expand {
        display: none;
      }

      .selectdiv:after {
        content: '▼';
        font: 15px $font-family-basic;
        color: #333;
        right: 12px;
        top: 15px;
        padding: 0 0 2px;
        position: absolute;
        pointer-events: none;
      }

      .selectdiv select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: block;
        width: 100%;
        max-width: 380px;
        height: 40px;
        float: left;
        margin: 5px 0px;
        padding: 0px 15px;
        font-size: 14px;
        line-height: 1.75;
        color: #333;
        background-color: #ffffff;
        background-image: none;
        border: 1px solid #cccccc;
        -ms-word-break: normal;
        word-break: normal;

        @include sp{
            @include vw(24);
            max-width: 640/750*100vw;
            width: 100%;
            padding: 0px 15/750*100vw;
        }
      }
}
