@charset "utf-8";

.l-flexA{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include sp{
        display: block;
    }

    &--spReverse{
        @include sp{
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &--mill{
        align-items: center
    }
}

.l-flexB{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include sp{
        flex-wrap: wrap;
    }
}

.l-flexC{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include sp{
        flex-wrap: nowrap;
    }
}

.l-flexD{
    display: flex;
    align-items: center;
}

.l-flexE{
    display: flex;
    align-items: flex-start;

    @include sp{
        display: block;
    }
}