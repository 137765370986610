@charset "utf-8";

.l-footer{
    background-color: $color_05;

    &__inner {
        position: relative;
        width: 940px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 100px;

        @include sp{
            padding-top: 50px;
        }

        @include sp{
            width: 100%;
        }
    }

    &__logoFlag {
        position: absolute;
        top: 0;
        right: -100px;

        @include sp{
            right: 0;
        }

        img {
            width: 100px;

            @include sp{
                width: 80px;
            }
        }
    }

    &__melittaLogo {
        width: 248px;
        margin-left: auto;
        margin-right: auto;

        @include sp{
            width: 130px;
        }

        img {
            width: 100%;
        }
    }

    &__onlineshop {
        width: 300px;
        margin: 120px auto 0;

        @include sp{
            width: 275px;
            margin: 45px auto 0;
            // display: none;
        }


        a {
            position: relative;
            display: block;
            width: 300px;
            padding: .8em 0 .5em;
            text-align: center;
            border: 1px solid #707070;
            background-color: #fff;
            border-radius: 100vh;
            color: #000;
            font-weight: bold;
            font-size: 15px;
            transition: all .3s;

            @include sp{
                width: 275px;
                padding: .8em 0 .5em;
                font-size: 12px;
            }

            &:hover {
                color: #fff;
                background-color: #D0121B;

                span:before {
                    background-image: url("/assets/img/common/icon_cart_red.png");
                    @include sp{
                        background-image: url("/assets/img/common/icon_cart.svg");
                    }
                }

                span:after {
                    background-image: url("/assets/img/common/menu_arw1_wh.svg");
                }
            }

            span:before {
                position: absolute;
                top: -50px;
                left: calc(50% - 21px);
                content: "";
                display: inline-block;
                width: 42px;
                height: 50px;
                background-image: url("/assets/img/common/icon_cart_wh_from_red.png");
                
                background-repeat: no-repeat;
                background-size: contain;
                transition: all .3s;

                @include sp{
                    top: 12px;
                    left: 30px;
                    width: 27px;
                    height: 25px;
                    background-image: url("/assets/img/common/icon_cart_black.svg");
                }
            }

            span:after {
                position: absolute;
                top: calc(50% - 5px);
                right: 30px;
                content: "";
                display: inline-block;
                width: 18px;
                height: 18px;
                background-image: url("/assets/img/common/menu_arw1.svg");
                background-repeat: no-repeat;
                transition: all .3s;
            }
        }
    }

    &__onlineshopSP {
        display: none;
        @include sp{
            width: 275px;
            margin: 45px auto 0;
            display: block;
        }

        a {
            position: relative;
            display: block;
            width: 275px;
            padding: .8em 0 .5em;
            text-align: center;
            border: 1px solid #707070;
            background-color: #fff;
            border-radius: 100vh;
            color: #000;
            font-weight: bold;
            font-size: 12px;

            span:before {
                position: absolute;
                top: 12px;
                left: 30px;
                content: "";
                display: inline-block;
                width: 27px;
                height: 25px;
                background-image: url("/assets/img/common/icon_cart_black.svg");
                background-repeat: no-repeat;
                background-size: contain;
            }

            span:after {
                position: absolute;
                top: calc(50% - 5px);
                right: 30px;
                content: "";
                display: inline-block;
                width: 18px;
                height: 18px;
                background-image: url("/assets/img/common/menu_arw1.svg");
                background-repeat: no-repeat;
            }

        }
    }

    &__sns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 190px;
        margin: 80px auto 0;

        @include sp{
            margin-top: 40px;
            gap: 50/750*100vw;
        }


        &__item{
            img {
                width: 100%;
            }

            &--fb{
                width: 14px;
                @include sp{
                    width: 36/750*100vw;
                }
            }

            &--inst{
                width: 25px;
                @include sp{
                    width: 66/750*100vw;
                }
            }

            &--yt{
                width: 35px;
                @include sp{
                    width: 91/750*100vw;
                }
            }

            &--tw{
                width: 26px;
                @include sp{
                    width: 66/750*100vw;
                }
            }

        }
    }

   
    // コピーライト
    &__copy {
        margin-top: 10px;
        padding: 35px 0;
        text-align: center;
        border-top: 1px solid #707070;
        color: #FFF;

        a {
            font-size: 17px;
            color: #FFF;

            @include sp{
                font-size: 13px;
            }
        }

        p {
            margin-top: 30px;
            font-size: 15px;
        }
    }
}

// ナビゲーション
.l-footerNavi {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
        "areaA areaC"
        "areaB areaD";
    width: 740px;
    grid-column-gap: 110px;
    margin: 80px auto 0;
    color: #FFF;

    @include sp{
        width: 95vw;
        margin-top: 40px;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;
        grid-template-areas:
        "areaA"
        "areaB"
        "areaC"
        "areaD";
        grid-column-gap: 0;
    }

    > ul {
        padding-bottom: 70px;

        @include sp{
            padding-bottom: 30px;
        }
    }

    &__navi1 {
        grid-area: areaA;

        @include sp{
            width: 95vw;
            margin-left: auto;
            margin-right: auto;
        }

        li {
            width: 380px;
            @include sp{
                width: 95vw;
            }
        }
    }

    &__navi2 {
        grid-area: areaB;
        @include sp{
            width: 95vw;
        }

        li {
            width: 380px;
            @include sp{
                width: 95vw;
            }
        }
    }

    &__navi3 {
        grid-area: areaC;
        width: 250px;
        @include sp{
            width: 95vw;
        }
    }

    &__navi4 {
        grid-area: areaD;
        width: 250px;
        @include sp{
            width: 95vw;
        }
    }

    &__ttl1 {
        padding-bottom: 8px;
        font-size: 17px;
        font-weight: bold;
        border-bottom: 1px solid #707070;

        a {
            color: #FFF;
        }
    }

    &__inner {
        display: flex;
        gap: 30px;
        padding-left: 20px;

        @include sp{
            padding-left: 0;
            gap: 0;
        }

        > dl {
            margin-top: 25px;

            dt {
                margin-bottom: 1em;
                font-size: 16px;
                font-weight: bold;

                @include sp{
                    font-size: 14px;
                }
            }

            dd {
                margin-bottom: 0.3em;

            }
        }

        &--left {
            width: 53%;
        }

        &--right {
            width: 47%;
        }


        > ul {
            margin-top: 25px;

            li {
                margin-bottom: 0.3em;

            }
        }

        a {
            font-size: 14px;
            color: #FFF;

            @include sp{
                font-size: 12px;
            }
        }
    }

}
