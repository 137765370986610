@charset "utf-8";

.p-brochure{
    padding: 100px 0 100px;

    @include sp{
        padding: 137/750*100vw 0 0;
    }
}

.p-brochureBody{
    max-width: 948px;
    margin: 0 auto;

    @include sp{
        max-width: 650/750*100vw;
    }

    &__sec{
        position: relative;
    }

    &__flex{
        display: flex;
        justify-content: space-between;

        @include sp{
            display: block;
        }
    }
    
    &__boxCont{
        padding-left: 55px;
        width: 50%;

        @include sp{
            width: 100%;
            padding-left: 62/750*100vw;
        }

        &--top{
            padding-left: 35px;

            @include sp{
                padding-left: 62/750*100vw;
            }
        }
    }
    &__box{
        padding-bottom: 30px;

        @include sp{
            padding-bottom: 80/750*100vw;
        }

        &--last{
            @include sp{
                padding-bottom: 160/750*100vw;
            }
        }
    }
    &__head{
        position: relative;


        font-size: 20px;
        font-weight: bold;
        @include sp{
            @include vw(34);
        }

        &::before{
            content: '';
            position: absolute;
            top: 16px;
            left: -55px;
            transform: translateY(-50%);
            width: 40px;
            height: 1px;
            background: #000;

            @include sp{
                left: -60/750*100vw;
                top: 28/750*100vw;
                width: 39/750*100vw;
                background-color: #707070;
            }
        }

        &--top{
            &::after{
                @include sp{
                    content: '';
                    height: 1px;
                    width: 39/750*100vw;
                    background-color: #707070;
                    position: absolute;
                    top: 28/750*100vw;
                    left: -100/750*100vw;
                }
            }
        }

        a{
            font-size: 20px;
            font-weight: bold;

            @include sp{
                @include vw(34);
            }
        }

    }

    &__list{
        margin-top: 16px;
    }
    &__item{
        position: relative;
        font-size: 16px;
        font-weight: bold;
        padding-left: 12px;
        margin-bottom: 12px;

        &--mb0{
            margin-bottom: 0px;
        }

        // &:nth-of-type(1){
        //     margin-bottom: 12px;
        // }

        @include sp{
            @include vw(30);
            margin-bottom: 24/750*100vw;
            padding-left: 24/750*100vw;
        }

            &::before{
            content: '-';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

    }
}