@charset "utf-8";

// 余白

.m-mb100{
  margin-bottom: 100px;

  @include sp{
    margin-bottom: 100/750*100vw;
  }
}

.m-mb80{
  margin-bottom: 80px;

  @include sp{
    margin-bottom: 80/750*100vw;
  }
}

.m-mb63{
  margin-bottom: 63px;

  @include sp{
    margin-bottom: 60/750*100vw;
  }
}

.m-mb60{
  margin-bottom: 60px;

  @include sp{
    margin-bottom: 60/750*100vw;
  }
}

.m-mb50{
  margin-bottom: 50px;

  @include sp{
    margin-bottom: 50/750*100vw;
  }
}

.m-mb40{
  margin-bottom: 40px;

  @include sp{
    margin-bottom: 40/750*100vw;
  }
}

.m-mb40B{
  margin-bottom: 40px;

  @include sp{
    margin-bottom: 60/750*100vw;
  }
}

.m-mb40C{
  margin-bottom: 40px;

  @include sp{
    margin-bottom: 30/750*100vw;
  }
}

.m-mb40D{
  margin-bottom: 40px;

  @include sp{
    margin-bottom: 80/750*100vw;
  }
}

.m-mb40E{
  margin-bottom: 40px;

  @include sp{
    margin-bottom: 0/750*100vw;
  }
}

.m-mb32{
  margin-bottom: 32px;

  @include sp{
    margin-bottom: 24/750*100vw;
  }
}

.m-mb32B{
  margin-bottom: 32/1366*100vw;

  @include sp{
    margin-bottom: 24/750*100vw;
  }
}

.m-mb30-sp{
  @include sp{
    margin-bottom: 30/750*100vw;
  }
}

.m-mb24{
  margin-bottom: 24px;

  @include sp{
    margin-bottom: 39/750*100vw;
  }
}

.m-mb24B{
  margin-bottom: 24px;

  @include sp{
    margin-bottom: 20/750*100vw;
  }
}

.m-mb24-sp{

  @include sp{
    margin-bottom: 24/750*100vw;
  }
}


.m-mb16{
  margin-bottom: 16px;

  @include sp{
    margin-bottom: 40/750*100vw;
  }
}

.m-mb16B{
  margin-bottom: 16px;

  @include sp{
    margin-bottom: 16/750*100vw;
  }
}

.m-mb16C{
  margin-bottom: 16px;

  @include sp{
    margin-bottom: 30/750*100vw;
  }
}

.m-mb16D{
  margin-bottom: 16px;

  @include sp{
    margin-bottom: 24/750*100vw;
  }
}


.m-mb14{
  margin-bottom: 14px;

  @include sp{
    margin-bottom: 30/750*100vw;
  }
}

.m-mb10{
  margin-bottom: 10px;

  @include sp{
    margin-bottom: 30/750*100vw;
  }
}

.m-mb10B{
  margin-bottom: 10px;

  @include sp{
    margin-bottom: 20/750*100vw;
  }
}

.m-mb10C{
  margin-bottom: 10px;

  @include sp{
    margin-bottom: 40/750*100vw;
  }
}

.m-mb10D{
  margin-bottom: 10px;

  @include sp{
    margin-bottom: 10/750*100vw;
  }
}
.m-mb20{
  margin-bottom: 20px;

  @include sp{
    margin-bottom: 30/750*100vw;
  }
}

.m-mb8{
  margin-bottom: 8px;

  @include sp{
    margin-bottom: 8/750*100vw;
  }
}

.m-mb5{
  margin-bottom: 5px;

  @include sp{
    margin-bottom: 16/750*100vw;
  }
}

.m-mb15{
  margin-bottom: 15px;

  @include sp{
    margin-bottom: 16/750*100vw;
  }
}


.m-mt80{
  margin-top: 80px;

  @include sp{
    margin-top: 80/750*100vw;
  }
}

.m-mt30{
  margin-top: 30px;

  @include sp{
    margin-top: 30/750*100vw;
  }
}

.m-mt24{
  margin-top: 24px;

  @include sp{
    margin-top: 24/750*100vw;
  }
}

.m-mt16{
  margin-top: 16px;

  @include sp{
    margin-top: 16/750*100vw;
  }
}

.m-mt16-sp{
  @include sp{
    margin-top: 16/750*100vw;
  }
}

.m-mt10{
  margin-top: 10px;

  @include sp{
    margin-top: 0vw;
  }
}

.m-pt80{
  padding-top: 80px;

  @include sp{
    padding-top: 80/750*100vw;
  }
}

.m-ml30{
  @include sp{
    margin-left: 30/750*100vw;
  }
}

.m-pt16{
  padding-top: 16px;

  @include sp{
    padding-top: 0vw;
  }
}

.m-pb10{
  padding-bottom: 10px;

  @include sp{
    padding-bottom: 10/750*100vw;
  }
}

.m-pb15{
  // padding-bottom: 15px;

  @include sp{
    padding-bottom: 30/750*100vw;
  }
}

.m-pb16{
  padding-bottom: 16px;

  @include sp{
    padding-bottom: 0vw;
  }
}

.m-pb20{
  padding-bottom: 20px;

  @include sp{
    padding-bottom: 20/750*100vw;
  }
}
.m-pb30{
  padding-bottom: 30px;

  @include sp{
    padding-bottom: 30/750*100vw;
  }
}

.m-pb50{
  padding-bottom: 50px;

  @include sp{
    padding-bottom: 50/750*100vw;
  }
}

.m-pb80{
  padding-bottom: 80px;

  @include sp{
    padding-bottom: 160/750*100vw;
  }
}

.m-p0{
  padding: 0px !important;
}

// SPで下の余白0
.m-mb0-sp{
  @include sp{
    margin-bottom: 0 !important;
  }
}

.m-pb0-sp{
  @include sp{
    padding-bottom: 0 !important;
  }
}
