@charset "utf-8";

.p-404{
    text-align: center;

    &__inner{
        padding: 80px 0 160px;
        @include sp{
            padding: 100/750*100vw 0 160/750*100vw;
        }
    }
    &__txtCont{
        margin-bottom: 64px;
        @include sp{
            margin-bottom: 80/750*100vw;
        }
    }
    &__ttl{
        @extend .u-ft-gs;
        color: #C1C1C1;
        font-size: 60px;
        margin-bottom: 6px;
        @include sp{
            @include vw(80);
            margin-bottom: 12/750*100vw;
        }
    }
    &__subTtl{
        color: #C1C1C1;
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 24px;
        @include sp{
            @include vw(40);
            margin-bottom: 24/750*100vw;
        }
    }
    &__txt{
        font-size: 15px;
        letter-spacing: 0.05em;
        line-height: 2;
        @include sp{
            @include vw(26);
        }
    }

    &__link {
        text-decoration: underline;
    }
    
    &__btn{
        background-color: #CF0A2C;
        color: #ffffff;
        display: inline-block;
        font-size: 15px;
        font-weight: medium;
        letter-spacing: 0.1em;
        padding: 22px;
        width: 279px;
        @include sp{
            @include vw(26);
            padding: 34/750*100vw;
            width: 486/750*100vw;
        }
    }
}