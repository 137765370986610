@charset "utf-8";

.p-topKv {
    margin-top: 80px;
    height: calc(80vh + 25px); // スライダードット分の高さ追加

    @include sp{
        width:100vw;
        height: calc(100vw + 25px); // スライダードット分の高さ追加
    }

    &__video {
        overflow: hidden;

        @include sp{
            width:100vw;
            height:100vw;
        }

        video {
            object-fit: cover;
            object-position: 0% 50%;
            height: 80vh;
            transform: scale(1.0);

            @include sp{
                width:100vw;
                height:90vw;
                object-position: 50% 50%;
                object-fit: cover;
                aspect-ratio: 1 / 1;
                transform: scale(1.6);
            }
        }
    }

    &__img {
        height: 100%;
        overflow: hidden;
        position: relative;

        @include sp{
            width:100vw;
            height:100vw;
        }

        &--txtBox01 {
            position: absolute;
            bottom: 30px;
            left: 40px;
            color: #fff;
            // font-weight: bold;
            // text-shadow: 0 0 5px #000;

            @include sp{
                bottom: 5vw;
                left: 3vw;
            }

            &--txt01 {
                font-family:"Gill Sans Nova W05 SemiBold";
                font-size: 38px;
                line-height: 1;
                
                @include sp{
                    @include vw(40);
                }
            }

            &--txt02 {
                font-family:"Gill Sans Nova W05 SemiBold";
                font-size: 78px;
                line-height: 1.3;
                letter-spacing: .2rem;

                @include sp{
                    @include vw(65);
                    line-height: 1.4;
                    letter-spacing: 0.05rem;
                }

            }

            &--txt03 {
                margin-left: -8px;
                font-family:"Hiragino Kaku Gothic Pro";
                font-weight: bold;
                font-size: 23px;
                line-height: 1.7;
                letter-spacing: .15rem;

                @include sp{
                    margin-left: -5px;
                    @include vw(26);
                    letter-spacing: 0;
                }
                
            }

        }
        &--txtBox02 {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 40px;
            color: rgb(68, 68, 68);
            // font-weight: bold;
            // text-shadow: 0 0 5px #000;

            @include sp{
                top: auto;
                bottom: 5vw;
                left: 3vw;
                transform: translateY(0%);
            }

            &--txt01 {
                font-family:"Gill Sans Nova W05 SemiBold";
                font-size: 38px;
                line-height: 1;
                
                @include sp{
                    @include vw(40);
                }
            }

            &--txt02 {
                font-family:"Gill Sans Nova W05 SemiBold";
                font-size: 78px;
                line-height: 1.3;
                letter-spacing: .15rem;

                @include sp{
                    @include vw(65);
                    line-height: 1;
                    letter-spacing: 0.05rem;
                }

                span {
                    color: rgb(207, 10, 44);

                    @include sp{
                        @include vw(90);
                    }
                }
            }

            &--txt03 {
                font-family:"Gill Sans Nova W05 SemiBold";
                font-size: 38px;
                line-height: 1.7;

                @include sp{
                    @include vw(40);
                    letter-spacing: 0;
                }
                
            }

        }

        &--txtBox03 {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 40px;
            color: #fff;

            @include sp{
                left: 3vw;
            }

            &--txt01 {
                font-weight: bold;
                font-size: 30px;
                line-height: 1;
                
                @include sp{
                    @include vw(40);
                }
            }

            &--txt02 {
                font-family:"Gill Sans Nova W05 SemiBold";
                font-size: 78px;
                line-height: 1.3;
                letter-spacing: .2rem;

                @include sp{
                    @include vw(65);
                    line-height: 1.4;
                    letter-spacing: 0.05rem;
                }

            }

            &--txt03 {
                font-weight: bold;
                font-size: 23px;
                line-height: 1.7;
                letter-spacing: .15rem;

                @include sp{
                    @include vw(26);
                    letter-spacing: 0;
                }
                
            }
        }

        a:hover {
            opacity: 1;
        }

        #mv_aroma img{
            object-position: left 33%;
        }

        img {
            width: 100%;
            object-fit: cover;
            object-position: left bottom;
            height: 80vh;

            @include sp{
                width:100vw;
                height:100vw;
                transform: scale(1.0);
            }

        }
    }
}

.p-topKv__slider {
    .slick-dots {
        padding-top: 10px;
        background-size: cover;
    }

    .slick-dots li button:before {
        font-size: 12px;
        color: #000;
    }

    .slick-dots li.slick-active button:before {
        opacity: 1;
    }
}


.p-topMain{
    // padding-top: 150px;
    text-align: center;

    @include sp{
        // padding-top: 50/750*100vw;
    }
}

.p-topLink{
    display: flex;
    flex-wrap: wrap;
    width: 990px;
    margin: 0 auto 100px;
    position: relative;

    @include sp{
        display: block;
        width: 690/750*100vw;
        margin: 0 auto 160/750*100vw;
    }

    .m-linkBox{
        margin-bottom: 6px;
        position: relative;

        @include sp{
            margin-bottom: 10/750*100vw;
        }

        &:nth-of-type(even){
            margin-right: 6px;

            @include sp{
                margin-right: 0;
            }
        }

        @include pc{
            // &:nth-last-of-type(2){
            //     margin-bottom: 0;
            // }

            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }

    &__more{

        @include sp{
            position: absolute;
            transform: translateX(640/750*100vw);
            top: 308/750*100vw;
        }
    }

}

.p-topTopic{
    width: 990px;
    margin: 0 auto 150px;
    border-top: 1px solid $color_06;
    position: relative;

    @include sp{
        width: 690/750*100vw;
        margin: 0 auto 260/750*100vw;
    }

    &__inner{
        width: 100%;
    }

    &__item{
        display: flex;
        align-items: center;
        border-bottom: 1px solid $color_06;
        padding: 24px 0;
        position: relative;
        text-align: left;
        @include sp{
            display: block;
            padding: 26/750*100vw 50/750*100vw 32/750*100vw;
        }

        &::after{
            content: '';
            border-top: solid 1px $color_05;
            border-right: solid 1px $color_05;
            width: 7px;
            height: 7px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            transition: .3s;

            @include sp{
                width: 15/750*100vw;
                height: 15/750*100vw;
            }
        }

        &:hover{
            &::after{
                right: 10px;
                border-top: solid 1px $color_02;
                border-right: solid 1px $color_02;
                transition: .3s;
            }
        }
    }

    &__date{
        color: #C7C7C7;
        font-size: 16px;

        @include sp{
            @include vw(28);
            margin-bottom: 17/750*100vw;
        }
    }

    &__ttl{
        position: absolute;
        left: 100px;
        padding-right: 100px;
        font-size: 14px;
        font-weight:lighter;

        @include sp{
            @include vw(26);
            position: static;
            padding-right: initial;
        }
    }

    &__more{
        position: absolute;
        bottom: -60px;
        right: 60px;

        @include sp{
            bottom: -100/750*100vw;
            right: 100/750*100vw;
        }
    }
}

.p-topCont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1700px;
    margin: 0 auto 160px;

    @include sp{
        width: 100%;
        display: block;
        margin-bottom: 160/750*100vw;
    }
}


.p-topContents{
    display: flex;
    flex-wrap: wrap;
    width: 990px;
    margin: 0 auto 154px;
    position: relative;
    justify-content: space-between;

    @include sp{
        display: block;
        width: 690/750*100vw;
        margin: 0 auto 160/750*100vw;
    }
    .contents_box{
        width: 49%;
        margin-bottom: 6px;
        position: relative;

        @include sp{
            width: 100%;
            margin-bottom: 10/750*100vw;
        }

        &:nth-of-type(even){
            margin-right: 6px;

            @include sp{
                margin-right: 0;
            }
        }
        &:nth-of-type(odd){
            margin-left: 13px;

            @include sp{
                margin-left: 0;
            }
        }

        @include pc{
            &:nth-last-of-type(2){
                margin-bottom: 0;
            }

            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
  }

  .p-newProducts{
    position: relative;
    width: 990px;
    display: flex;
    margin: 0 auto 100px;

    img {
        width: calc(100% - 10px);
    }

    @include sp{
        display: block;
        width: 690/750*100vw;
        margin: 0 auto 160/750*100vw;
    }

    .banner_box{
        // width:50%;
        @include sp{
            margin-bottom: 20/750*100vw;

            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
}

.p-topBg50th {
    width: 100%;
    padding-top: 10px;
    margin-bottom: 70px;
}

.p-topConcept50th {
    // width: 924px;
    width: 940px;
    margin: 0 auto;
    padding-top: 30px;

    @include sp{
        width: 92vw;
    }

    &__title {
        font-size: 28px;
        line-height: 2;
        text-align: center;
        font-weight: 700;
        

        @include sp{
            @include vw(28);
        }
    }

    &__txtpht {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        @include sp{
            width: 100%;
            flex-direction: column;
            margin-top: 5vh;
        }
    }

    &__txt {
        flex-basis: 60%;
        text-align: left;
        font-size: 15px;
        line-height: 3rem;

        @include sp{
            width: 95%;
            @include vw(23);
            text-align: center;
            line-height: 2rem;
            
        }
    }

    &__pht {
        flex-basis: 40%;

        @include sp{
            width: 80%;
            margin-top: 3vh;
        }

        img {
            width: 100%;
            
        }
    }
    


}

.p-topAnniversary50th {
    // width: 924px;
    width: 940px;
    margin: 100px auto 0;
    @include sp{
        width: 92vw;
        margin-top: 50px;
    }

    &__bnr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
        margin-top: 30px;

        @include sp{
            flex-direction: column;
            gap: 2vh;
        }
    }
}

.p-topNewProduct {
    width: 940px;
    margin: 100px auto 0;

    @include sp{
        width: 92vw;
        margin-top: 50px;
    }

    &__bnrBox {
        display: flex;
        align-items: center;
        gap: 40px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        @include sp{
            flex-direction: column;
        }

        &--inner {
            width: 310px;

            @include sp{
                width: calc(92vw * 0.8);
            }

            img {
                width: 100%;
            }
        } 
    }
}

.p-topHistory50th {
    // width: 924px;
    width: 940px;
    margin: 100px auto 0;
    @include sp{
        width: 92vw;
        margin-top: 50px;
    }

    &__zoomBox {
        display: block;
        position: relative;
        width: 100%;
        margin-top: 30px;
        overflow: hidden;

        img {
            width: 100%;
            transition: .5s;
        }

        &:hover{
            img{
                transform: scale(1.1);
                transition: .5s;
            }

            .p-topHistory50th__zoomBox--txt {
                letter-spacing: 0.2em;

                @include sp{
                    letter-spacing: 0.2em;
                }

                &:after {

                    transform: translateX(10px);

                    @include sp{
                        transform: translateX(3px);
                    }
                }
            }

            .p-topHistory50th__zoomBox--txtborder {
                &:after {
                    width: 80px;

                    @include sp{
                        width: 40px;
                    }
                }
            }
        }
    }

    &__zoomBox--txtbox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        color: #fff;

    }

    &__zoomBox--txtborder {
        &:after {
            content: "";
            display: block;
            width: 64px;
            height: 1px;
            margin: 10px auto 0;
            border-bottom: 1px solid #fff;
            transition: .5s;

            @include sp{
                width: 30px;
                margin-top: 0;
            }
        }
    }

    &__zoomBox--txt {
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.12em;
        transition: .5s;

        @include sp{
            font-size: 14px;
        }

        &:before {
            content: "";
            width: 21px;
            margin-right: 60px;
            display: inline-block;

            @include sp{
                width: 15px;
                margin-right: 5px;
            }
        }

        &:after {
            content: "";
            width: 21px;
            height: 15px;
            margin-left: 60px;
            display: inline-block;
            background-image: url(/assets/img/top/anv50th_history_arw.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transition: .5s;

            @include sp{
                width: 15px;
                margin-left: 5px;
                background-size: 15px;
            }

        }
    }
}

// Products of Melitta
.p-topProduct {
    width: 940px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;

    @include sp{
        width: 92vw;
    }

    &__cateTitle {
        display: block;
        width: 940px;
        overflow: hidden;
        position: relative;

        @include sp{
            width: 92vw;
        }

        &:hover {
            .p-topProduct__cateTitle--img {
                transform: scale(1.1);
            }

            .p-topProduct__cateTitle--txt {
                letter-spacing: 0.2em;

                &:after {
                    width: 100px;

                    @include sp{
                        width: 40px;
                    }
                }
            }
        }

        &--img {
            transition: .3s;

            @include sp{
                width: 92vw;
            }
        }

        &--txt {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            font-weight: bold;
            color: #fff;
            font-size: 20px;
            letter-spacing: 0.1em;
            transition: .3s;

            @include sp{
                @include vw(23);
            }

            &:after {
                content: "";
                width: 80px;
                height: 10px;
                display: block;
                margin: 0 auto;
                border-bottom: 1px solid #fff;
                transition: .3s;

                @include sp{
                    width: 40px;
                    height: 5px;
                }
            }
        }
    }

    &__menu1 {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
            width: 277px;

            @include sp{
                width: 50%;
            }
        }

        // 家庭用
        &--img {
            margin-bottom: 10px;
            overflow: hidden;
            transition: .3s;

            @include sp{
                margin-bottom: 5px;
            }
    
            img {
                display: block;
                width: 100%;
                transition: .3s;;
            }
        }

        // 業務用
        &--img2 {
            margin-bottom: 10px;
            overflow: hidden;
            transition: .3s;
            border: 1px solid #EAEAEA;

            @include sp{
                margin-bottom: 5px;
            }
    
            img {
                display: block;
                width: 100%;
                transition: .3s;;
            }
        }

        &--txt {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;

            @include sp{
                margin-bottom: 15px;
            }
    
            p {
                width: calc(100% - 21px);
                text-align: left;
                font-size: 16px;
                font-weight: bold;
                transition: .3s;

                @include sp{
                    width: calc(100% - 25px);
                    @include vw(23);
                    line-height: 1.2em;
                }

            }
    
            span {
                display: inline-block;
                width: 21px;
                height: 10px;
                background-image: url(/assets/img/top/arw_black.png);
                background-repeat: no-repeat;
                background-size: contain;

                @include sp{
                    width: 15px;
                    margin-right: 10px;
                }
            }
        }

        a:hover {
            .p-topProduct__menu1--img img {
                transform: scale(1.1);
            }

            .p-topProduct__menu1--img2 img {
                transform: scale(1.1);
            }

            .p-topProduct__menu1--txt p {
                letter-spacing: 0.06em;
            }
        }
    }
}

// Products of Melitta ここまで

// News
.p-topNews {
    margin-bottom: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
    @extend .u-bg-f2f2f2;

    @include sp{
        padding-top: 8vh;
        padding-bottom: 8vh;
    }

    &__itemBox {
        width: 880px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;

        @include sp{
            width: 92vw;
        }
        
        a {
            width: 100%;
            // border: 1px solid red;
            display: block;


            &:hover {
                opacity: .6;
                .p-topNews__itemBox--arw span {
                    transform: translate(10px, 0);
                    @include sp{
                        transform: translate(0, 0);
                    }
                }
            }
        }

        &--inner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 1.5em 0;
            align-items: center;
            font-size: 15px;
            border-bottom: 1px solid #ddd;
            @include sp{
                flex-direction: column;
                align-items: left;
                padding: 2em 0;
                @include vw(23);
            }
        }

        &--data {
            width: 120px;
            text-align: left;

            @include sp{
                width: 100%;
                margin-bottom: 1em;
            }
        }

        &--content {
            width: calc(100% - 170px);
            text-align: left;

            @include sp{
                width: 100%;
            }
        }

        &--arw {
            width: 50px;

            @include sp{
                width: 100%;
                text-align: right;
            }
            span {
                width: 21px;
                height: 10px;
                display: inline-block;
                background-image: url(/assets/img/top/arw_black.png);
                background-repeat: no-repeat;
                background-size: contain;
                transition: all .3s;

            }
        }
    }
}

// News ここまで

// コラム
.p-topColumn {
    width: 940px;
    margin: 20px auto 80px;
    
    @include sp{
        width: 100%;
    }

}

.p-topColumnSlider {
    width: 940px;
    display: flex;
    gap: 30px;
    @include sp{
        width: 100%;
        margin-right: 0;
        margin-left: auto;
        gap: 0;
    }
    &--slide {
        flex-basis: 33.33%;

        a:hover {
            img {
                transform: scale(1.1);
            }
        }
        
    }
    
    &--inner {
        @include sp{
            padding: 10px;
        }
    }

    .slick-list {
        @include sp{
            // padding: 0 10% 0 0 !important;
        }
    }

    .slick-slide {
        @include sp{
            // margin-right: 0px;
        }
    }


    &__imgBox {
        width: 100%;
        margin-bottom: 20px;
        overflow: hidden;

        img {
            width: 100%;
            transition: .5s;
        }
    }

    // &__category {
    //     width: fit-content;
    //     text-align: left;
    //     margin-bottom: 20px;
    //     padding: 1em 4em;
    //     border-radius: 100vh;
    //     font-size: 11px;
    //     background-color: #F4F3F4;
    // }

    &__txt {
        text-align: left;
        font-size: 15px;
        line-height: 1.8;
        // font-weight: bold;

        @include sp{
            @include vw(23);
        }
    }

    &__arw {
        width: 24px;
        height: 7px;
        margin: 0 0 0 auto;
        background-image: url(/assets/img/top/arw_black.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
}
// コラムここまで

// About
.p-topAbout {
    width: 940px;
    margin: 20px auto 150px;
    
    @include sp{
        width: 100%;
        margin-bottom: 5vh;
    }
}

.p-topAboutSlider {
    width: 940px;
    display: flex;
    gap: 30px;
    @include sp{
        width: 100%;
        margin-right: 0;
        margin-left: auto;
        gap: 0;
    }
    &--slide {
        flex-basis: 33.33%;

        a:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
    
    &--inner {
        @include sp{
            padding: 10px;
        }
    }

    .slick-list {
        @include sp{
            // padding: 0 10% 0 0 !important;
        }
    }

    .slick-slide {
        @include sp{
            // margin-right: 0px;
        }
    }


    &__imgBox {
        width: 100%;
        margin-bottom: 20px;
        overflow: hidden;

        img {
            width: 100%;
            transition: .5s;
        }
    }

    &__ttlBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    &__ttl {
        font-size: 16px;
        font-weight: bold;
    }

    &__arw {
        width: 24px;
        height: 7px;
        background-image: url(/assets/img/top/arw_black.png);
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__txt {
        text-align: left;
        font-size: 15px;
        line-height: 1.8;

        @include sp{
            @include vw(23);
        }
    }
}

// Aboutここまで

.p-topGoToListBtn1 {
    a {
        display: inline-block;
        // position: relative;
        @include sp{
            width: 90vw;
        }
    }

    &__inner {
        display: inline-block;
        width: 330px;
        margin-left: auto;
        margin-right: auto;
        padding: 1.3em 0;
        text-align: center;
        border-radius: 100vh;
        border: 1px solid #000;
        background-color: #fff;
        font-size: 18px;
        font-weight: bold;
        transition: all .5s;

        @include sp{
            width: 90vw;
            font-size: 15px;
        }

        &:after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 10px;
            margin-left: 15px;
            background-image: url(/assets/img/top/arw_black.png);
            background-repeat: no-repeat;
            background-size: contain;
            transition: all .5s;
        }

        &:hover {
            color: #fff;
            background-color: #000;

            &:after {
                background-image: url(/assets/img/top/arw_wh.png);
            }
        }

    }
}

.p-topGoToListBtn2 {
    a {
        display: inline-block;
        position: relative;
        @include sp{
            width: 90vw;
        }
    }

    &__inner {
        width: 330px;
        margin-left: auto;
        margin-right: auto;
        padding: 1.3em 0;
        text-align: center;
        border-radius: 100vh;
        border: 1px solid #000;
        background-color: #fff;
        font-size: 18px;
        font-weight: bold;
        transition: all .5s;

        @include sp{
            width: 90vw;
            font-size: 15px;
        }
    
        &:after {
            content: "";
            position: absolute;
            top: calc(50% - 5px);
            right: 30px;
            display: inline-block;
            width: 20px;
            height: 10px;
            background-image: url(/assets/img/top/arw_black.png);
            background-repeat: no-repeat;
            background-size: contain;
            transition: all .5s;
        }

        &:hover {
            color: #fff;
            background-color: #000;

            &:after {
                background-image: url(/assets/img/top/arw_wh.png);
            }
        }
    }
}


.p-topjFadeIn {
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
    transition: opacity 1s,visibility 1s, transform .5s;
}

.p-topScroll {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}
