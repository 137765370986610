@charset "utf-8";

.m-zoomBox{

    &:hover{
        opacity: 1;
    }

    &__inner{
        position: relative;
        max-width: 850px;
        min-width: 49%;

        @include sp{
        width: 100%;
        }

        &:first-of-type{
            margin-right: 6px;

            @include sp{
                margin-right: 0;
                margin-bottom: 10/750*100vw;
            }
            
        }
    }

    &__img{
        overflow: hidden;

        img{
            width: 100%;
            transition: .5s;
        }

        &.is-active{
            img{
                transform: scale(1.1);
                transition: .5s;
            }
        }
    }

    &__txt{
        position: absolute;
        white-space: nowrap;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    &__ttl{
        color: $color_04;
        font-size: 24px;

        @include sp{
            @include vw(40);
        }
    }

    &__subTtl{
        color: $color_04;
        font-size: 16px;
        white-space: nowrap;
        font-weight:normal;
        
        @include sp{
            @include vw(28);
        }
    }

    &__more{
        position: absolute;
        bottom: 58px;
        right: 146px;

        @include sp{
            bottom: 40/750*100vw;
            right: 144/750*100vw;
        }
    }

    &__lineLink{
        color: $color_04;
        cursor: pointer;
        transition:0.5s all;
        text-align: right;
        width: 90px;
        display: inline-block;
        font-size: 20px;

        @include sp{
            @include vw(30);
            width: 160/750*100vw;
        }

        &::after{
            content: '';
            position: absolute;
            top: 50%;
            right: -60px;
            width: 100%;
            height: 1px;
            transform: scaleX(0.4);
            transform-origin: right top;
            background-color: $color_04;
            transition: all .3s ease;

            @include sp{
                right: -90/750*100vw;
            }
        }

        &.is-active{
            letter-spacing: 0.2em;
            transition:0.5s all;

            &::after{
                transform: scaleX(0.5);
                background-color: $color_02;
            }
        }
    }
}

// 長方形
.m-zoomBoxB{
    max-width:492px;
    width: 100%;
    margin-bottom: 6px;

    &:hover{
        opacity: 1;
    }

    &:nth-last-of-type(2){
        margin-bottom: 0;
    }

    &:last-of-type{
        margin-bottom: 0;
    }

    &--wide{
        width: 100%;
        max-width: 990px;
        @include sp{
            max-width: 100%;
            height: 502/750*100vw;
        }
    }

    &__inner{
        position: relative;
        text-align: center;

        @include sp{
            width: 100%;
        }

        &:first-of-type{

            @include sp{
                margin-right: 0;
                margin-bottom: 10/750*100vw;
            }
            
        }
    }

    &__img{
        overflow: hidden;

        img{
            width: 100%;
            transition: .5s;
        }

        &.is-active{
            img{
                transform: scale(1.1);
                transition: .5s;
            }
        }
    }

    &__txt{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
    }

    &__ttl{
        color: $color_04;
        font-size: 24px;

        @include sp{
            @include vw(40);
        }
    }

    &__subTtl{
        color: $color_04;
        font-size: 16px;
        white-space: nowrap;

        @include sp{
            @include vw(28);
        }
    }

    &__note{
        text-align: left;
        @include pc{
            font-size: 15px;
            line-height: 2;
            padding-top: 16px;
            white-space: nowrap;
        }
    }

    &__memo{
        color: $color_04;
        display: block;
    }
}
