@charset "utf-8";


// .linkBtn{
    
//     &.select{
//          z-index: 11;
//     }
// }

.m-mainSlide {
    position: relative;

.image {
    display: block;
    width: 100%;
    height: 100%;
}


/* slide-box */
.slide-box {
    position: relative;
    margin: 0;
    padding: 0;
    width: 578px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

/* slide */
.slide {
    position: relative;
    margin: 0;
    padding: 0;
    height: 358px;
    border: 1px solid $color_06;
    background-color: $color_04;

    @media screen and (max-width: 767px) {
        height: 425/750*100vw;
    }
}

.slide.bgBlack {
    background-color: rgba(65, 64, 66, 1);
}  

.slide .item {
    position: relative;
    height: 100%;
    color: transparent;
}

.slide .item a {
    display: block;
}

.slide .item::before {
    display: block;
    // padding-top: 39%;
    content: "";
}

.slide .item .image {
    position: relative;
    overflow: hidden;
}

.slide .item img {
    position: absolute;
    top: 0;
    // bottom: 0;
    // right: 0;
    left: 0;
    margin: auto;
    width: auto;
    height: 100%;

    left: 50%;

    
    top: 50%;
    transform: translate(-50%,-50%);

    @media screen and (max-width: 767px) {
        height: 428/750*100vw;
    }
}

.slide>.slick-list,
.slide>.slick-list>.slick-track,
.slide>.slick-list>.slick-track>.slick-slide>div {
    position: relative;
    height: 100%;
}

/* slide-navigation */
.slide-navigation {
    line-height: 0;
    position: relative;
    margin: 0;
    padding-top: 16px;
    width: 505px;

    @media screen and (max-width: 767px) {
        width: 600/750*100vw;
        padding-top: 19/750*100vw;
    }
}

.slide-navigation .item {
    position: relative;
    cursor: pointer;
}

.slide-navigation .item .image {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 87px;
    height: 55px;
    border: 2px solid $color_06;
    color: transparent;
    background-color: $color_04;

    @media screen and (max-width: 767px) {
        width: 105/750*100vw;
        height: 66/750*100vw;
    }
}

// 選択中
.slide-navigation .slick-current .image {
    border: 2px solid $color_07;
}

.slide-navigation .item .image::before {
    display: block;
    padding-top: 35%;
    content: "";
}

.slide-navigation .item .image::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.5;
    // background: #000;
}

.slide-navigation .slick-current .image::after {
    opacity: 0;
}

.slide-navigation .item .image {
    position: relative;
    overflow: hidden;
}

.slide-navigation .item .image img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    width: auto;
    height: 55px;
    text-align: center;
    transform: translate(-50%,-50%);

    @media screen and (max-width: 767px) {
        height: 66/750*100vw;
    }
}

@media screen and (max-width: 767px) {

    .slide-navigation .item .image::before {
        padding-top: 50%;
    }
}

/* slick */

.slick-vertical .slick-slide {
    border: 0 solid transparent;
}

.slick-arrow {
    z-index: 998;
}

.slick-prev {
    top: 25px;
    bottom: 0;
    left: auto;
    right: -158px;
    margin: auto;
}

.slick-next {
    top: 25px;
    bottom: 0;
    left: auto;
    right: -60px;
    margin: auto;
}

.slick-initialized {

    .slick-next,
    .slick-prev {
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        background: 0 0;
        color: transparent;
        padding: 0;
        border: none;
        outline: 0;
    }

    // メイン画像内のアイコン
    .slick-prev {
        left: -35px;
        right: auto;

        @media screen and (max-width: 767px) {
            left: 40/750*100vw;
        }

        &::before {
            content: '';
            display: block;
            width: 35px;
            height: 35px;
            transform: rotate(45deg);

            @media screen and (max-width: 767px) {

                width: 40/690*100vw;
                height: 40/690*100vw;
            }
        }
    }

    .slick-next {
        right: -20px;

        @media screen and (max-width: 767px) {
            right: 55/750*100vw;
            left: auto;
        }

        &::before {
            content: '';
            display: block;
            width: 35px;
            height: 35px;
            transform: rotate(45deg);

            @media screen and (max-width: 767px) {
                width: 40/690*100vw;
                height: 40/690*100vw;
            }
        }
    }
}


.slide-navigation {

    // サムネイル内のアイコン
    .slick-prev {
        left: 0;
        top: 25px;
        bottom: 0;
        right: -550px;

        @media screen and (max-width: 767px) {
            left: auto;
            right: -60/750*100vw;
            top: 60/750*100vw;
        }

        &::before {
            width: 25px;
            height: 25px;
            transform: rotate(45deg);

            @media screen and (max-width: 767px) {
                width: 20/750*100vw;
                height: 20/750*100vw;
            }
        }
    }

    .slick-next {
        top: 25px;
        bottom: 0;
        left: 0;
        right: -600px;

        @media screen and (max-width: 767px) {
            left: auto;
            right: -90/750*100vw;
            top: 60/750*100vw;
        }
        &::before {
            width: 25px;
            height: 25px;
            transform: rotate(45deg);
            // opacity: 0.75;

            @media screen and (max-width: 767px) {
                width: 20/750*100vw;
                height: 20/750*100vw;
            }
        }
    }
    .slick-next.slick-disabled:before{
        opacity: 0.75; 
    }
}


.redImage {
    display: none;
}
}

.item .slick-slide .slick-current .slick-active {
.image {
    border: solid 1px red;
}
}


.m-mainSlide {
    .slick-slider .slick-track {
        margin-left: 0 !important;
    }
}

// 矢印の色

// アイコン黒
.m-mainSlide--bk{
    .slick-initialized {
    
        // メイン画像内のアイコン
        .slick-prev {
            &::before {
                border-bottom: solid 1px $color_07;
                border-left: solid 1px $color_07;
            }
        }
    
        .slick-next {
    
            &::before {
                border-top: solid 1px $color_07;
                border-right: solid 1px $color_07;
            }
        }
    }

    .slide-navigation {
        // サムネイル内のアイコン
        .slick-prev {
            &::before {
                border-bottom: solid 1px $color_07;
                border-left: solid 1px $color_07;
            }
        }
    
        .slick-next {
            &::before {
                border-top: solid 1px $color_07;
                border-right: solid 1px $color_07;
            }
        }
    }
}


// アイコン白
.m-mainSlide--wh{
    .slick-initialized {
    
        // メイン画像内のアイコン
        .slick-prev {
            &::before {
                border-bottom: solid 1px $color_04;
                border-left: solid 1px $color_04;
            }
        }
    
        .slick-next {
    
            &::before {
                border-top: solid 1px $color_04;
                border-right: solid 1px $color_04;
            }
        }
    }

    .slide-navigation {
        // サムネイル内のアイコン
        .slick-prev {
            &::before {
                border-bottom: solid 1px $color_04;
                border-left: solid 1px $color_04;
            }
        }
    
        .slick-next {
            &::before {
                border-top: solid 1px $color_04;
                border-right: solid 1px $color_04;
            }
        }
    }
}