@charset "utf-8";

.m-breadcrumbCont{
    position: relative;
    width: 100%;
}

.m-breadcrumb{
    color: $color_07;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 32px;
    padding-bottom: 30px;
    z-index: 1;

    @media screen and (min-width:751px) and ( max-width:1300px){
            
        padding-left:64/750*100vw;//60px;
    }

    @include sp{
        padding-top: 35/750*100vw;
        padding-bottom: 64/750*100vw;
        flex-wrap: wrap;
    }

    &--white{
        color: $color_04;

        .m-breadcrumb__item{
            a{
                color: $color_04;
            }
        }
    }

    &--right{
        position: absolute;
        top: 0;
        right: 40px;

        @include sp{
            right: 30/750*100vw;
        }
    }

    &__item{
        font-size: 12px;
        white-space: nowrap;

        @include sp{
            @include vw(22);
        }

        // 文字数が多くて2行になる時
        &--2lines{
            white-space: normal;
        }

        &--spRight{
            @include sp{
                text-align: right;
            }
        }

        a{
            color: $color_07;
            font-size: 12px;

            @include sp{
                @include vw(22);
            }
        }

        &--spLong{
            @include sp{
                @include vw(20);
            }
        }        

        &--spLong a{
            @include sp{
                @include vw(20);
            }
        }

        &:nth-last-of-type(n+2){
            margin-right: 14px;
            padding-right: 14px;
            position: relative;

            @include sp{
                margin-right: 18/750*100vw;
                padding-right: 18/750*100vw;
            }
        
            &:before{
                position: absolute;
                display: block;
                content: '＞';
                top: 50%;
                right: -10px;
                transform: translateY(-50%)
            }

            @include sp{
                &:before{
                    right: -15/750*100vw;
                }
            }
        }
    }
}


.m-breadcrumb--2line{
    display: flex;

    @include sp{
        display: inline-block;
        text-align: right;
    }

    .kaigyou{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        margin-right: 14px;
        padding-right: 14px;

        @include sp{
            margin-right: 18/750*100vw;
            padding-right: 18/750*100vw;
        }

        &:after{
            position: absolute;
            display: block;
            content: '＞';
            top: 50%;
            right: -10px;
            transform: translateY(-50%);
            font-size: 12px;

            @include sp{
                @include vw(22);
            }
        }

        @include sp{
            &:after{
                @include vw(22);
                right: -15/750*100vw;
            }
        }
    }
}