@charset "utf-8";

.m-lineLinkCont{
    text-align: right;
    padding-right: 65px;
    padding-top: 15px;

    @include sp{
        padding-right: 100/750*100vw;
    }

    &--left{
        text-align: left;
    }

    &--leftSpRight{
        text-align: left;

        @include sp{
            text-align: right;
        }
    }
}

.m-lineLinkScont{
    text-align: right;
}


// ホバーで線が伸びるリンク
.m-lineLink{
    position: relative;
    cursor: pointer;
    transition:0.5s all;
    text-align: right;
    width: 90px;
    display: inline-block;
    font-size: 16px;
    margin-left: -40px;

    @include sp{
        @include vw(30);
        width: 160/750*100vw;
        margin-left: -70/750*100vw;
    }

    &::after{
        content: '';
        position: absolute;
        top: 50%;
        right: -60px;
        width: 100%;
        height: 1px;
        transform: scaleX(0.4);
        transform-origin: right top;
        background-color: #000;
        transition: all .3s ease;

        @include sp{
            right: -90/750*100vw;
        }
    }

    &:hover{
        letter-spacing: 0.2em;
        transition:0.5s all;

        &::after{
            transform: scaleX(0.5);
            background-color: $color_02;
        }
    }

    &.is-active{
        letter-spacing: 0.2em;
        transition:0.5s all;

        &::after{
            transform: scaleX(0.5);
            background-color: $color_02;
        }
    }
}

.m-lineLinkS{
    position: relative;
    cursor: pointer;
    transition:0.5s all;
    text-align: right;
    width: 90px;
    display: inline-block;
    font-size: 15px;

    @include sp{
        @include vw(30);
        width: 160/750*100vw;
    }

    &::after{
        content: '';
        position: absolute;
        top: 50%;
        right: -60px;
        width: 100%;
        height: 1px;
        transform: scaleX(0.4);
        transform-origin: right top;
        background-color: #000;
        transition: all .3s ease;

        @include sp{
            right: -90/750*100vw;
        }
    }

    &:hover{
        letter-spacing: 0.2em;
        transition:0.5s all;

        &::after{
            transform: scaleX(0.5);
            background-color: $color_02;
        }
    }

    &.is-active{
        letter-spacing: 0.2em;
        transition:0.5s all;

        &::after{
            transform: scaleX(0.5);
            background-color: $color_02;
        }
    }

    &--narrow{
        &:hover{
            letter-spacing: 0.1em;
            transition:0.5s all;
        }

    }
}


// ホバーで線の色だけ変わるリンク
.m-lineLinkColor{
    position: relative;
    cursor: pointer;
    transition:0.5s all;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 0.1em;
    font-family: $font-family-basic;

    @include sp{
        @include vw(30);

        // &:not(:last-of-type){
        //     margin-bottom: 43/750*100vw;
        // }
    }

    &::after{
        content: '';
        position: absolute;
        top: 50%;
        right: -40px;
        width: 28px;
        height: 1px;
        background-color: #000;
        transition: all .3s ease;

        @include sp{
            right: -70/750*100vw;
        }
    }

    &:hover{

        &::after{
            background-color: $color_02;
        }
    }

    a{
        display: inline-block;
        width: 100%;
        opacity: 1;
    }

    // 白
    &--wh{

        a{
            color: $color_04;
        }

        &::after{
            content: '';
            position: absolute;
            top: 50%;
            right: -40px;
            width: 28px;
            height: 1px;
            background-color: #ffffff;
            transition: all .3s ease;
    
            @include sp{
                right: -70/750*100vw;
            }
        }
    }

    // 太字
    &--bold{
        a{
            font-weight: bold;
        }
    }

}

// 縦 SP用
.m-lineLinkVerSp{
    display: none;

    @include sp{
        @include vw(24);
        height: 110/750*100vw;
        color: #fff;
        position: relative;
        cursor: pointer;
        transition:0.5s all;
        text-align: left;
        display: inline-block;
        writing-mode: vertical-rl;

        &::before{
            content: 'OPEN';
        }
    
        &.is-active{
            letter-spacing: 0.2em;
            transition:0.5s all;
    
            &::before{
                content: 'CLOSE';
            }
        }

        &::after{

            @include sp{
                content: '';
                position: absolute;
                bottom: -7/750*100vw;
                width: 1px;
                background: #fff;
                height: 120/750*100vw;
                right: 41/750*100vw;
            }
        }

    }
}

// 縦 PC用 文字替え&間隔広がるアニメーション
.m-lineLinkVer{
    color: #fff;
    position: relative;
    cursor: pointer;
    transition:0.5s all;
    text-align: left;
    display: inline-block;
    writing-mode: vertical-rl;

    @include sp{
        @include vw(24);
        height: 110/750*100vw;
    }

    &::before{
        content: 'OPEN';
        position: absolute;
    }

    &::after{
            content: '';
            position: absolute;
            
            width: 1px;
            background: #fff;
            right: 26px;
            top: 0px;
            height: 70px;

            @include sp{
                right: 41/750*100vw;
                height: 120/750*100vw;
                bottom: -7/750*100vw;
            }
    }

    &.is-active{
        letter-spacing: 0.2em;
        transition:0.5s all;

        &::before{
            content: 'CLOSE';
        }
    }

    
}