@charset "utf-8";

.p-goldstandard{
    padding: 0px 0 0;

    @include sp{
        padding: 160/750*100vw 0 0;
    }

    &__slide{
        padding-bottom: 200px;

        @include sp{
            padding-bottom: 220/750*100vw;
        }
    }
}

.p-goldstandardlogo{
    display:flex;
    width:150px;
    margin:0px auto;
    padding-top: 80px;
    justify-content: space-around;
    img{
        width:100%;
    }
    @include sp{
        padding-top: 80/750*100vw;
        width:450/750*100vw;
        img{
            width:68%;
        }   
    }
}

.p-goldstandardBgImage{
    position: relative;
    padding:0 0 100px;
    background: url(/assets/img/melitta-philosophy/goldstandard/lead_bg_goldstandard.jpg) no-repeat center center / cover;
    height: auto;
    
    @include sp{
        padding:0 0 80/750*100vw;
    }
}

.p-goldstandardLead{

    &__inner{
        text-align: center;
        padding: 160px 0 240px;

        @include sp{
            padding: 160/750*100vw 0;
        }
    }

    &__Ttl{
        text-align: center;
        margin-bottom: 40px;
        padding-top: 40px;

        @include sp{
            padding-top: 40/750*100vw;
            margin-bottom: 40/750*100vw;
        }
    }

    &__txt{
        text-align: left;
        margin-bottom: 40px;

        @include sp{
            margin-bottom: 40/750*100vw;
        }
    }
}

.box{
    width:1150px;
    margin:0 auto;
    
    @media screen and (max-width: 1200px) {
        width:90%;
    }
}

.box0{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    align-items: stretch;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
}

.box1{
    width: 49%;
    height:260px;

    display: flex;
    flex-direction: column;

    padding: 2em 3em; // 枠の中
    margin: 1em 0;      // 枠の外
    border: solid 1px rgb(144,112,16); // 枠の色
    background-color: rgba(3, 3, 3, 0.5); // 背景の透過
    
    @media screen and (max-width: 1200px) {
        width:100%;
        height:auto;
    }
    
    @include sp{
        width:100%;
        flex-direction: column;
        padding: 1em 1em; // 枠の中
    }

}

.box2{
    display: flex;
    gap:30px;
    align-items: center;
    width: 100%;
    height:50px;
    img{
        width:70px;
        height:70px;
    }

    @include sp{
        gap:30/750*100vw;
        flex-direction: column;
        img{
            width:100%;
            height:100%;
        }
    }
}

.box3{
    display: flex;
    margin-top:20px;
    @include sp{
        margin-top:100/750*100vw;
    }

}
.box1 p {
    margin: 0; 
    padding: 0;
}

.p-goldcup{
    width:990px;
    padding: 0px 0 0;
    margin: 100px auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px) {
        width:90%;
        margin: 50/750*100vw auto;
        padding: 60/750*100vw 0 0;
        justify-content: center
    }
}

.p-goldcup_container{
    width:990px;
    display:flex;
    flex-direction: column;
    @media screen and (max-width: 1200px) {
        // padding: 160/750*100vw 0 0;
    }
}

.p-goldcup_container_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height:auto;
    @include sp{
        flex-wrap: nowrap;
        flex-direction: column;
        gap:30/750*100vw;    
    }
  }
  
  .p-goldcup_container_box:nth-child(odd) {
        @include sp{
            flex-direction: column-reverse;    
        }
  }
  
  .p-goldcup_container_box img {
    display: block;
    width: 48%;
    height: auto;
    @include sp{
        width: 90%;
        height: auto;
        }
  }

  .p-goldcup_container_box:not(:last-child) {
    margin-bottom:50px;
    @include sp{
        margin-bottom: 30/750*100vw;
    }
  }
  
  .goldcup_box {
    width: 48%;
    @include sp{
        width: 90%;
        height: auto;
        }
  }

  .p-goldstandardTxtArea{
    position:relative;
    padding:0px 0 100px;
    width:100%;
    @include sp{
        padding:100/750*100vw 30/750*100vw;    
    }

    &__ttl{
        color: rgb(167,130,30);
    }

    &__inner{
        max-width: 990px;
        width: 100%;
        text-align: center;
        margin: 0 auto;
    }
  }

