@charset "utf-8";

.p-system{
    padding: 130px 0 0;

    @include sp{
        padding: 160/750*100vw 0 0;
    }

    &__ttl{
        @extend .m-ttlC;
        @extend .u-ft-gs;
        text-align: center;
        margin-bottom: 6px;

        @include sp{
            margin-bottom: 16/750*100vw;
        }
    }

    &__subTtl{
        @extend .m-txt-bold;
        @extend .m-txt-fs20;
        @extend .m-mb40;
        text-align: center;
    }

    .m-zoomBoxB{
        @include sp{
            display: block;
            &:first-of-type{
                margin-bottom: 80/750*100vw;
            }
        }
    }
}

.p-systemTtl{
    padding-bottom: 100px;
    text-align: center;

    @include sp{
        padding-bottom: 100/750*100vw;
    }
}

.p-systemAbout{

    &__block{

        &:first-of-type{
            margin-bottom: 80px;

            @include sp{
                margin-bottom: 40/750*100vw;
            }
        }

        &--flex{
            display: flex;
            justify-content: space-between
        }

        @include sp{
            &--flex{
                display: flex;
                flex-direction: column;
            }
        }
    }

    &__sec{
        margin-bottom: 160px;
        @include sp{
            margin-bottom: 160/750*100vw;
        }
    }

    &__head{
        @extend .m-txt-fs20;
        @extend .m-mb16B;
    }

    &__img01{
        @extend .m-mb40;
        width: 100%;

        img{
            width: 100%;
        }
    }

    &__img02{
        @extend .m-mb40;
        max-width: 475px;
        width: 100%;

        @include sp{
            order: 1;
            max-width: 100%;
        }
    }

    &__txtCont{

        @include pc{
            &--half{
                max-width: 475px;
            }
        }

        @include sp{
            order: 2;
        }

        img{
            width: 100%;
        }
    }

    &__txt{
        @extend .m-txt-fs15I;
    }
}

.p-systemImprovement{
    padding-top: 120px;

    @include sp{
        padding-top: 120/750*100vw;
    }

    &__ttl{
        @extend .u-ft-gs;
        font-size: 30px;
        margin-bottom: 40px;
        @include sp{
            @include vw(40);
        }
    }

    &__sec{
        margin-bottom: 80px;

        @include sp{
            margin-bottom: 80/750*100vw;
        }
    }

    &__head{
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 40px;

        @include sp{
            @include vw(30);
            display: flex;
            margin-bottom: 40/750*100vw;
        }

        span{
            @extend .u-ft-gs;
            font-size: 16px;
            margin-right: 20px;

            @include sp{
                @include vw(24);
                width: 108/750*100vw;
                white-space: nowrap;
                margin-right: 20/750*100vw;
            }
        }
    }

    &__flex{
        display: flex;
        justify-content: space-between;

        @include sp{
            display: block;
        }
    }

    &__img{
        max-width: 475px;
        margin-right: 20px;

        @include sp{
            margin-bottom: 16/750*100vw;
            margin-right: 0;
        }

        img{
            width: 100%;
        }
    }
    
    &__txtCont{
        max-width: 475px;

        @include sp{
            width: 100%;
            padding-left: 0;
        }
    }

    &__txt{
        font-size: 15px;
        line-height: 2;

        @include sp{
            @include vw(26);
            line-height: 1.5;
        }
    }

    &__list{
        max-width: 475px;

        @include sp{
            width: 100%;
        }
    }

    &__item{
        font-size: 15px;
        margin-bottom: 8px;
        line-height: 2;

        @include sp{
            @include vw(26);
            margin-bottom: 16/750*100vw;
        }
    }
}

.p-systemSlide{
    padding-bottom: 160px;

    @include sp{
        padding-bottom: 160/750*100vw;
    }

    &__ttl{
        @extend .u-ft-gs;
        font-size: 30px;
        margin-bottom: 40px;
        @include sp{
            @include vw(40);
        }
    }
}