@charset "utf-8";

.p-icecoffee {
    .m-anchorList {
        @include sp {
            margin-bottom: 0;
        }
    }

&__ttl {
    padding: 120px 0 160px;

    @include sp {
    padding: 180/750*100vw 0 160/750*100vw;
    }
}

&__sec {
    // padding: 80px 0 160px;
    padding: 80px 0 0;

    @include sp {
            // padding: 80/750*100vw 0 160/750*100vw;
            padding: 80/750*100vw 0 0;
        }
    }

    &__pageLink {
        position: relative;
        display: block;
        width: 100%;
        border-bottom: 1px solid #C7C7C7;
        font-size: 15px;
        text-align: left;
        padding: 16px 0 15px;
        line-height: 1.2;

        

        &--w475{
            @include pc{
                max-width: 475px;
            }
        }

        @include sp{
            @include vw(24);
            font-weight: bold;
            padding: 30/750*100vw 0;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;

            &::after{
                position: absolute;
                right: 16px;
                top: 50%;
                content: '';
                border-bottom: solid 2px #C7C7C7;
                border-right: solid 2px #C7C7C7;
                width: 9px;
                height: 9px;
                transform: rotate(45deg) translateY(-50%);
            }
        }
    }
}

.p-icecoffeeDetailBox {
    max-width: 478px;
    width: 100%;

    &--column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 294px;
        width: 357px;
        margin-left: 40px;

        @include sp {
            height: auto;
            width: 100%;
            margin-left: 0;
        }
    }

    &--link {
        width: 373px;
        margin-left: 37px;

        @include sp{
            width: 100%;
            margin-left: 0;
        }
    }

&__columnInner {
    @include sp {
    margin-bottom: 80/750*100vw;
    }
}
}

.p-icecoffeeDetailBoxLink {
    // display: inline-block;
    // height: 290px;
}


.p-icecoffeeVideo {
width: 475px;

@include sp {
    width: 690/750*100vw;
    margin-bottom: 23/750*100vw;
}

&__thumbCont {
    position: relative;
}

&__thumb {
    display: block;
    overflow: hidden;
    width: 475px;
    height: 290px;

    @include sp {
        width: 690/750*100vw;
        height: 420/750*100vw;
    }

    img {
        width: 100%;
        transition: all .4s ease-out;
    }

    &.is-active {
        opacity: 1;

        img {
            transform: scale(1.15);
            transition: all .4s ease-out;
        }

        .p-icecoffeeVideo__icon {
            background: url(/assets/img/common/icon_video_on.svg) no-repeat center center;
            width: 71px;
            height: 71px;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.is-active {
        opacity: 1;

        img {
            transform: scale(1.15);
            transition: all .4s ease-out;
        }

        .p-icecoffeeVideo__icon {
            background: url(/assets/img/common/icon_video_on.svg) no-repeat center center;
            width: 71px;
            height: 71px;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

&__icon {
    background: url(/assets/img/common/icon_video.svg) no-repeat center center;
    width: 71px;
    height: 71px;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include sp {
    width: 147/750*100vw;
    height: 147/750*100vw;
    }
}

&__ttl {
    margin-bottom: 40px;

    @include sp {
    margin-bottom: 24/750*100vw;
    }
}

&__head {
    margin-bottom: 10px;
}

&__lead {
    margin-bottom: 24px;
    white-space: nowrap;
}

&__txt {
    letter-spacing: 0;
}
}
