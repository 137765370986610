@charset "utf-8";

.m-table{
    width: 475px;
    margin-bottom: 40px;

    @include sp{
        width: 100%;
        margin-bottom: 60/750*100vw;
    }

    @include pc{
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    &__line{
        display: table;

        @include sp{
            width: 100%;
        }
    }

    &__block {
		display: table-cell;
        font-size: 15px;

        @include sp{
            @include vw(24);
        }

        &.head{
            background-color: $color_07;
            color: $color_04;
            padding: 15px 0 12px;
            text-align: center;
            
            &:first-of-type{
                border-right: solid 1px #C7C7C7;
            }
        }

        &.ttl{
            background-color: #C7C7C7;
            color: $color_04;
            vertical-align: middle;
            text-align: center;
            vertical-align: middle;

            @include sp{
                width: 231/750*100vw;
            }
        }

        &.txt{
            background-color: #fff;
            padding: 30px 40px;
            vertical-align: top;
            line-height: 1.6;

            @include sp{
                padding: 40/750*100vw;
            }
        }


        &.w166{
            width: 166px;

            @include sp{
                width: 248/750*100vw;
            }
        }

        &.w309{
            width: 309px;

            @include sp{
                width: 442/750*100vw;
            }
        }

        &.h371{
            height: 371px;

            @include sp{
                height: auto;
            }
        }

        &.h130{
            height: 130px;

            @include sp{
                height: auto;
            }
        }
	}
}

.m-tableB{
    border-top: 1px solid #C7C7C7;
    border-left: 1px solid #C7C7C7;
    
    &__aromafresh{
        border-top: 1px solid #C7C7C7;
        border-left: 1px solid #C7C7C7;
        
        @include sp {
            height: 1200/750*100vw;
            overflow-y: scroll;
        }
    }
    &__sticky {
        @include sp{
            z-index:2;
            position: -webkit-sticky;
            position: sticky;
            display: block;
            top: 0;
            background-color: #ffffff;
        }
    }
    &__sticky :before {
        @include sp{
            position: absolute;
            top: -1px;
            left: -1px;
            width: 100%;
            height: 100%;
            content: "";
        }
    }
 
    &__line{
        display: table;

        @include sp{
            display: block;
            width: 100%;
        }
    }

    &__flex{
        display: flex;

        @include sp{
            width: 100%;
        }

        &.link_underline {
            a {
                text-decoration: underline;
            }
        }
    }

    &__block{
        display: table-cell;
        border-right: 1px solid #C7C7C7;
        border-bottom: 1px solid #C7C7C7;
        vertical-align: middle;
        text-align: center;
        padding: 9px 0 8px;

        &.head{
            background-color: #F0F0F0;
            width: 206px;

            @include sp{
                width: 100vw;
                padding: 32/750*100vw 0;
            }
        }

        &.w392{
            width: 392px;

            @include sp{
                padding: 34/750*100vw 0 30/750*100vw;
            }
        }

        &.high{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include sp{
                padding: 26/750*100vw 36/750*100vw;
                justify-content: flex-start;
            }
        }

        &.bgColor{
            @include sp{
                background-color: #fff;
                box-shadow:0 1px 0 #fff;
            }
        }

        &.bgColor_wh{
            @include sp{
                background-color: #fff;
                box-shadow:0 1px 0 #fff;
            }
        }
        
        &.link_underline{
            a {
                text-decoration: underline;
            }
        }
    }
}

.m-tableImg{

    &__imgWrap{
        position: relative;
        overflow: hidden;
        height: 134px;
  
        @include sp{
          height: 208/750*100vw;
        }
    }
    &__img{
        width: 100%;
        height: 134px;
        object-fit:contain;
        @include sp{
            height: 208/750*100vw;
            background-color: #FFF;
          }
    }
    &__body{
        padding: 10px;
        text-align: center;
  
        @include sp{
          padding: 0/750*100vw 0;
        }
    }
    &__txt{
        color: $color_05;
        font-size: 14px;
        white-space: normal;
        @include sp{
          white-space: normal;
          text-align: center;
        }  
    }
  }
  