@charset "utf-8";

.m-pager{
        display: flex;
        text-align: center;
        align-items: center;
    
        &__item{
            margin-right: 12px;
            align-items: center;
            display: flex;
            justify-content: center;
            display: inline-block;
            border-bottom: 2px solid transparent;
    
            @include sp{
                margin-right: 20/750*100vw;
                margin-bottom: 14/750*100vw;
                border-bottom: 1px solid transparent;
            }

            &.is-active{
                border-bottom: 2px solid #CF0A2C;
                pointer-events: none;

                @include sp{
                    border-bottom: 1px solid #CF0A2C;
                }
            }
    
            a{
                height: 28px;
                width: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 14px;

                @include sp{
                    @include vw(23);
                }
            }
    
            span{
                height: 28px;
                width: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
            }

            &--prev{
    
                a {
                    position: relative;
    
                    &::after{
                        content: '前';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        transform: translateY(-50%);

                        @include sp{
                            width: 36/750*100vw;
                            height: 36/750*100vw;
                        }
                    }
                }
            }
    
            &--next{
                a {
                    position: relative;
    
                    &::after{
                        content: '次';
                        position: absolute;
                        top: 50%;
                        right: 0%;
                        width: 20px;
                        height: 20px;
                        transform: translateY(-50%);

                        @include sp{
                            width: 36/750*100vw;
                            height: 36/750*100vw;
                        }
                    }
                }
            }
        }
}