@charset "utf-8";

.p-coffeeRecipe{
    padding-bottom: 160px;

    @include sp{
        padding-bottom: 160/750*100vw;
    }

    .m-anchorList{
        @include sp{
            margin-bottom: 0;
        }
    }

    .m-video{
        @include sp{
            margin-bottom: 23/750*100vw;
        }
    }

    &__ttl{
        padding: 120px 0 100px;

        @include sp{
            padding: 180/750*100vw 0 100/750*100vw;
        }
    }

    &__kv{
        margin-bottom: 80px;

        @include sp{
            margin-bottom: 80/750*100vw;
        }
    }
}

.p-coffeeRecipeEasy{

    &__box{
        max-width: 681px;
        width: 100%;

        @include sp{
            width: 100%;
        }
    }

    &__img{
        @include sp{
            width: 384/750*100vw;
            margin: 0 auto 40/750*100vw;
        }
    }
}

.p-coffeeRecipeItemCont{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.p-coffeeRecipeItem{
    margin-bottom: 60px;

    @include sp{
        margin-bottom: 60/750*100vw;
    }

    &:hover{
        opacity: 1;
    }

    &:last-of-type{
        margin-bottom: 0;
    }

    &__head{
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 10px;

        @include sp{
            @include vw(24);
            margin-bottom: 10/750*100vw;
        }
    }

    &__img{
        margin-bottom: 16px;
        overflow: hidden;

        @include sp{
            margin-bottom: 24/750*100vw;
        }

        img{
            transition: .5s;
        }

        &.is-active{
            img{
                transform: scale(1.1);
                transition: .5s;
            }
        }
    }

    &__ttl{
        font-size: 16px;
        line-height: 1.875;
        font-weight: bold;
        margin-bottom: 10px;

        @include sp{
            @include vw(30);
            line-height: 1.5;
            margin-bottom: 10/750*100vw;
        }
    }

}

.bgBlank{
    padding-bottom: 230px;
    position: relative;

    @include sp{
        padding-bottom: 230/750*100vw;
    }
}

.bgTxt{
    color: rgba(242,242,242,1);
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    font-size: 114px;
    font-weight: 700;
    font-family: 'Gill Sans Nova W05 SemiBold"';
    bottom: -53px;
    left: 50%;
    transform: translate(-50%);

    @include sp{
        @include vw(114);
        bottom: -52/750*100vw;
    }
}