@charset "utf-8";

.floating-banner {
  width: calc(100% / 10);
  position: fixed;
  bottom: 125px;
  right: 25px;
  z-index: 9999;
  transition: 0.3s;
  cursor: pointer;
  
  @include sp {
    width: calc(100% / 3);
    bottom: 0px;
  }
}

.floating-banner.ok{
  opacity: 0;
  visibility: hidden;
  z-index: -1;}

.floating-banner:hover {
  opacity: 0.8;
}
.floating-banner_img {
  width: 100%;
}

.banner-close{
  position:absolute;
 
  top:0px;
  right:0px;

  height:35px;
  width:35px;
  border-radius:50%;
  line-height:35px;
  text-align:center;
  background-color:#444;
  font-size:24px;
  color:#fff;
  cursor:pointer;
  z-index:1;

  @include sp {
    @include vw(40);
    top:0/750*100vw;
    height:60/750*100vw;
    width:60/750*100vw;
    line-height:55/750*100vw;
  }

}