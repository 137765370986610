@charset "utf-8";

.m-prodItem{
    width: 270px;
    background-color: $color_04;
    position: relative;
    margin: 0 24px 24px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include sp{
        width: 332/750*100vw;
        margin: 0 26/750*100vw 24/750*100vw 0;

        &:nth-of-type(even){
            margin-right: 0;
        }
    }

    &:hover{
        opacity: 1;

        .m-prodItem__img{
            @include pc{
                transition: .5s;
                height: 95%;
            }
        }
    }

    &--shadow{
        box-shadow: 0px 3px 10px 0px rgba(112,112,112,0.11);
    }

    &__parts{
        @include sp{
            width: 315/750*100vw;
            &:nth-of-type(even){
                margin-right: 26/750*100vw;
            }
        }
    }

    &__logo{

        &--ml{
            &::before{
                content: '';
                position: absolute;
                background: url(/assets/img/common/icon_ml.svg);
                background-size: contain;
                background-repeat: no-repeat;
                top: 10.5px;
                left: 9px;
                width: 58px;
                height: 38px;

                @include sp{
                    width: 70/750*100vw;
                    height: 46/750*100vw;
                    top: 6/750*100vw;
                    left: 5/750*100vw;
                }
            }
        }

        &--dc{
            &::before{
                content: '';
                position: absolute;
                background: url(/assets/img/common/icon_dc.svg);
                background-size: contain;
                background-repeat: no-repeat;
                top: 10.5px;
                left: 9px;
                width: 46px;
                height: 27px;

                @include sp{
                    width: 57/750*100vw;
                    height: 33/750*100vw;
                    top: 15/750*100vw;
                    left: 12/750*100vw;
                }
            }
        }

        &--mk{
            &::before{
                content: '';
                position: absolute;
                background: url(/assets/img/common/icon_mk.svg);
                background-size: contain;
                background-repeat: no-repeat;
                top: 10.5px;
                left: 9px;
                width: 48px;
                height: 27px;

                @include sp{
                    width: 57/750*100vw;
                    height: 33/750*100vw;
                    top: 13/750*100vw;
                    left: 11/750*100vw;
                }
            }
        }

        &--ps{
            &::before{
                content: '';
                position: absolute;
                background: url(/assets/img/common/icon_ps.svg);
                background-size: contain;
                background-repeat: no-repeat;
                top: 16px;
                left: 10px;
                width: 73.5px;
                height: 8px;

                @include sp{
                    width: 92/750*100vw;
                    height: 9/750*100vw;
                    top: 21/750*100vw;
                    left: 17/750*100vw;
                }
            }
        }

        &--zh{
            &::before{
                content: '';
                position: absolute;
                background: url(/assets/img/common/icon_zh.svg);
                background-size: contain;
                background-repeat: no-repeat;
                top: 15px;
                left: 11px;
                width: 61px;
                height: 24px;

                @include sp{
                    width: 91/750*100vw;
                    height: 36/750*100vw;
                    top: 11/750*100vw;
                    left: 11/750*100vw;
                }
            }
        }

        &--pp{
            &::before{
                content: '';
                position: absolute;
                background: url(/assets/img/common/icon_pp.svg);
                background-size: contain;
                background-repeat: no-repeat;
                top: 10.5px;
                left: 9px;
                width: 48px;
                height: 27px;

                @include sp{
                    width: 57/750*100vw;
                    height: 33/750*100vw;
                    top: 13/750*100vw;
                    left: 11/750*100vw;
                }
            }
        }

        &--lf{
            &::before{
                content: '';
                position: absolute;
                background: url(/assets/img/common/icon_lf.svg);
                background-size: contain;
                background-repeat: no-repeat;
                top: 12px;
                left: 10px;
                width: 90px;
                height: 14px;

                @include sp{
                    width: 106/750*100vw;
                    height: 16/750*100vw;
                    top: 21/750*100vw;
                    left: 17/750*100vw;
                }
            }
        }

    }

    &__cont{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        // height: 350px;
    }

    &__imgWrap{
        position: relative;
        overflow: hidden;
        height: 168px;

        @include sp{
            height: 208/750*100vw;
        }
    }

    &__img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: .5s;
        height: 85%;

        @include sp{
            width: auto;
        }
    }

    &__body{
        padding: 0 13px 8px;

        @include sp{
            padding: 10/750*100vw 16/750*100vw 32/750*100vw;
        }
    }

    // &__bottom{
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-between;
    //     height: 80px;

    //     @include sp{
    //         height: 120/750*100vw;
    //     }
    // }

    &__ttl{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 1.4;

        @include sp{
            @include vw(30);
            margin-bottom: 10/750*100vw;
            line-height: 1.25;
        }
    }

    &__subTtl{
        font-size: 14px !important;
        display: block;
        line-height: 1.5;

        @include sp{
            @include vw(22);
            margin-bottom: 6/750*100vw;
            line-height: 1.15;
        }
    }

    &__type{
        color: $color_07;
        font-weight: bold;
        font-size: 12px;
        line-height: 1.4;
        height: 36px;

        @include sp{
            @include vw(24);
            line-height: 1.1;
            height: 108/750*100vw;
        }
    }

    &__price{
        font-size: 16px;
        font-weight: bold;

        @include sp{
            @include vw(24);
        }
    }

    &__link{
        margin-top:20px;
        @include sp{
            margin-left:20px;
        }

        p{
            font-size: 16px;
            font-weight:bold;
            color: #fff;
            @include sp{
                @include vw(27);
            }
        }
        a{
            font-size: 14px;
            color: #fff;
            @include sp{
                @include vw(20);
            }
        }

    }
}
