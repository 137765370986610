@charset "utf-8";

.p-pro{
    // padding: 80px 0 160px;

    @include sp{
        padding: 0 0 180/750*100vw;
    }

    &__brandSec{
        padding: 80px 0 70px;

        @include sp{
            padding: 80/750*100vw 0 116/750*100vw;
        }
    }

    &__type{
        padding-bottom: 30px;

        &:last-of-type{
            padding-bottom: 0;
        }
    }

    &__sec{
        margin-bottom: 70px;

        &:last-of-type{
            margin-bottom: 0;
        }

        @include sp{
            margin-bottom: 100/750*100vw;
        }
    }


}
