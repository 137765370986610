@charset "utf-8";

.m-linkBox {
    width:492px;
    position:relative;
    overflow:hidden;

    @include sp{
        width: 690/750*100vw;
    }

    &--wide{
        width: 990px;
        position: relative;

        @include sp{
            width: 690/750*100vw;
        }
    }

    &__icon{
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
        display: block;
        border-bottom: solid 3px $color_04;
        border-right: solid 3px $color_04;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        transition: .5s;

        @include sp{
            transition: .3s;
            top: 370/750*100vw;
        }

        &.is-active{
            bottom: 0;
            transition: .5s;

            @include sp{
                transition: .3s;
                top: 370/750*100vw;
                transform:translateX(-50%) rotate(-135deg);
            }
        }
    }

    &__img{
        width: 100%;

        img{
            width: 100%;
        }
    }

    &__slide{
        width: 100%;
        height: 100%;
        transition: .5s;
        opacity: 0;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;

        &--ml{
            background-color: #c7c7c7;

            &.is-open{
                &::after{
                    // content: '';
                    // position: absolute;
                    // background-color: $color_06;
                    // height: 279px;
                    // width: 1px;
                    // left: 50%;
                    // top: 80px;
                    // z-index: 2;

                    @include sp{
                        display: none;
                    }
                }
            }

        }

        &--dc{
            background-color: #F6C631;
        }

        &--ps{
            background-color: #D2E4DD;
        }

        &--mk{
            background-color: #F20C19;
        }

        &--zh{
            background-color: $color_05;
        }

        &--pp{
            background-color: $color_11;
        }
        // SP
        @include sp{
            position: relative;
            opacity: 1;
            left: auto;
            top: auto;
            display: none;
            transition: none;
        }

        &.is-open{
            opacity: 1;
        }
    }

    &__logo{
        position: absolute;

        @include sp{
            display: none;
        }


        &--ml{
            width: 63px;
            top: 24px;
            left: 50%;
            transform: translateX(-50%);

            img{
                width: 100%;
            }
        }

        &--dc{
            top: 24px;
            left: 50%;
            transform: translateX(-50%);
        }

        &--ps{
            top: 41px;
            left: 50%;
            transform: translateX(-50%);
        }

        &--mk{
            top: 24px;
            left: 50%;
            transform: translateX(-50%);
        }

        &--zh{
            top: 24px;
            left: 50%;
            transform: translateX(-50%);
        }

        &--pp{
          width: 63px;
            top: 24px;
            left: 50%;
            transform: translateX(-50%);
        }

    }

    &__slideCont{
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        &--ml{
            display: flex;
            justify-content: space-between;
            position: static;
            width: 883px;
            margin: 78px auto 0;
            transform: none;

            @include sp{
                margin: 0;
                display: block;
                width: 100%;
            }
        }

        &--dc{
            top: 60%;
        }

        &--ps{
            top: 55%;
        }

        &--mk{
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            // width: 384px;
            // margin: 0 auto;
            // top: 58%;
            top: 55%;

            @include sp{
                padding-top: 0;
                display: block;
                width: 100%;
            }

            .m-linkBox__slideItem{
                width: 48%;

                @include sp{
                    width: 100%;
                }
            }
        }

        &--zh{
            top: 55%;
        }

        &--pp{
            top: 55%;
        }

        @include sp{
            background-color: #C7C7C7;
            top: 0;
            position: static;
            top: auto;
            bottom: auto;
            transform: none;
        }
    }

    &__slideInner{

    }

    &__slideBoxCont{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include sp{
            display: none;

            &.is-open{
                display: block;
            }
        }

        &--01{
            width: 466px;

            @include sp{
                width: 100%;
            }
        }

        &--02{
            width: 384px;

            @include sp{
                width: 100%;
            }
        }
    }

    &__slideBox{
        width: 50%;

        @include sp{
            width: 100%;
        }

        &--ml{
            margin-left: -24px;

            @include sp{
                margin-left: 0;
            }
        }
    }

    &__slideTtlCont{
        text-align: center;
        @include sp{
            background: $color_07;
            color: $color_04;
            text-align: left;
        }
    }

    &__slideTtl{
        font-size: 18px;
        margin-bottom: 19px;

        @include sp{
            @include vw(32);
            margin-bottom: 0;
            padding: 49/750*100vw 0 48/750*100vw 50/750*100vw;
            position: relative;
            border-bottom: 1px solid $color_04;
        }

        span{
            color: #747474;
            font-size: 10px;
            display: block;

            @include sp{
            }
        }

        .slideIcon{
            position: absolute;
            right: 40/750*100vw;
            top: 50/750*100vw;
            width: 40/750*100vw;
            height: 40/750*100vw;
            -webkit-tap-highlight-color:rgba(0,0,0,0);

            &:before{
                display: block;
                content: "";
                position: absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
                margin:auto;
                width: 100%;
                height: 1px;
                background-color: $color_04;
                transition: .2s;
                transform: rotate(90deg);
            }

            &:after{
                display: block;
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 100%;
                height: 1px;
                background-color: $color_04;
                transition: .3s;
            }

            &.is-active{
                &:before{
                    transform: rotate(0deg);
                }
                &:after{
                    background-color: transparent;
                }
            }
        }
    }

    &__slideItem{
        display: block;
        margin-bottom: 26px;
        text-align: left;
        white-space: nowrap;
        margin-bottom:30px;

        @include sp{
            margin-bottom: 0;
        }

        &:last-of-type{
            // margin-bottom: 0;
        }
    }

    &__slideItem a{
        font-size: 16px;
        display: inline-block;
        position: relative;

        @include sp{
            @include vw(24);
            display: block;
            height: 115/750*100vw;
            text-align: left;
            padding: 40/750*100vw 0 100/750*100vw 50/750*100vw;
            border-bottom: 1px solid $color_04;
        }

        &.pc-white{
            color: $color_04;

            @include sp{
                color: inherit;
            }
        }

        &.pc-gray{
            color: rgb(65,64,66);

            @include sp{
                color: inherit;
            }
        }

        &::after {
            position: absolute;
            bottom: 0px;
            left: 0;
            content: '';
            width: 100%;
            height: 2px;
            background: rgb(207,10,44);
            transform: scale(0, 1);
            transform-origin: center top;
            transition: .5s;
            opacity: 0;

            @include sp{
                content: 'none';
            }
        }

        span{
            color: #747474;
            font-size: 10px;
            position: absolute;
            left: 0;
            bottom: -16px;

            @include sp{
                left: 53/750*100vw;
                bottom: 30/750*100vw;
            }

            &.pc-white{
                color: $color_04;

                @include sp{
                    color: inherit;
                }
            }
        }
    }
}


// 商品一覧ページ用
.m-linkBoxB {
    width: 262px;
    cursor: pointer;
    position: relative;

    @include sp{
        width: 484/750*100vw;
        margin: auto;
    }

    &__icon{
        position: absolute;
        top: 90px;
        left: 48%;
        content: '';
        border-bottom: solid 1px $color_07;
        border-right: solid 1px $color_07;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        transition: .3s;

        @include sp{
            top: 170/750*100vw;
        }

        &.is-active{
            transform: rotate(-135deg);
            transition: .3s;
        }

        &--wh{
            border-bottom: solid 1px $color_04;
        border-right: solid 1px $color_04;
        }
    }

    &__img{
        width: 100%;
    }

    &__slide{
        display: none;

        &.is-open{
            top: 0;
            opacity: 1;
        }
    }

    &__slideHead{
        font-size: 12px;
        background-color: $color_07;
        color: $color_04;
        cursor: default;
        padding: 24px 28px;
        border-bottom: 1px solid $color_06;

        @include sp{
            @include vw(22);
        }
    }

    &__slideCategory{
        position: relative;
        background-color: $color_07;
        padding: 9.5px 0 7.5px 19px;
        border-bottom: 1px solid $color_06;

        p{
            color: $color_04;
        }

        &.is-active{
            &:before{
                transform: rotate(0deg);
            }
            &:after{
                background-color: transparent;
            }
        }
    }

    &__slideItemCont{
        display: none;
    }

    &__slideIcon{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        -webkit-tap-highlight-color:rgba(0,0,0,0);

        &:before{
            display: block;
            content: "";
            position: absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            margin:auto;
            width: 100%;
            height: 1px;
            transition: .2s;
            transform: rotate(90deg);
            background-color: #fff;
        }

        &:after{
            display: block;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 100%;
            height: 1px;
            transition: .3s;
            background-color: #fff;
        }

        &.is-active{
            &:before{
                transform: rotate(0deg);
            }
            &:after{
                background-color: transparent;
            }
        }

    }

    &__slideItem{
        background-color: #000;
        text-align: left;
        border-bottom: 1px solid $color_06;

        &::after {
            position: absolute;
            bottom: 0px;
            left: 0;
            content: '';
            width: 100%;
            height: 2px;
            background: rgb(207,10,44);
            transform: scale(0, 1);
            transform-origin: center top;
            transition: .5s;
            opacity: 0;
        }

        a{
            color: $color_04;
            display: block;
            padding: 9.5px 0 7.5px 19px;

            @include pc{
                font-size: 15px;
            }

            @include sp{
                @include vw(28);
                padding: 20/750*100vw 0 18/750*100vw 20/750*100vw;
            }
        }
        &--small{
            a{
                @include pc{
                    font-size: 14px;
                }
            }
        }
    }
}
