@charset "utf-8";

.m-message{
    &__inner{
        text-align: center;
        padding: 85px 0 160px;
        max-width: 942px;
        margin: 0 auto;

        @include sp{
            padding: 100/750*100vw 0 160/750*100vw;
            max-width: 650/750*100vw;
        }
    }

    &__logo{
        margin-bottom: 35px;

        @include sp{
            width: 247/750*100vw;
            margin: 0 auto 60/750*100vw;
        }
    }

    &__txtCont{
        margin-bottom: 60px;
    }

    &__ttl{
        line-height: 1.43;
        margin-bottom: 40px;

        @include sp{
            margin-bottom: 61/750*100vw;
        }
    }

    &__txt{
        line-height: 1.875;
    }
}