@charset "utf-8";

.p-grinder{
    &__ttl{
        padding: 120px 0 97px;

        @include sp{
            padding: 180/750*100vw 0 80/750*100vw;
        }
    }
}

.p-grinderPoint{
    margin-bottom: 40px;
    @include sp {
        margin-bottom: 40/750*100vw;
    }

    &__ttl{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 16px;

        @include sp {
            @include vw(40);
            margin-bottom: 40/750*100vw;
        }


    }
    &__descTtl{
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 8px;
    }
    &__txtCont{
        margin-bottom: 40px;
    }
    &__txt{
        font-size: 15px;
        line-height: 2;
        &--add{
            @include sp {
                @include vw(28);
                margin:0vw 50/750*100vw;
            }
        }
        @include sp {
            @include vw(28);
        }
    }
    &__cont{
        background-color: #e3e3e3;
        padding: 60px 0;
        margin-bottom: 40px;
        width: 100%;

        @include sp {
            padding: 60/750*100vw 0 ;
        }

    }
    &__inner{
        max-width: 900px;
        margin: 0 auto;
    }
    &__grinder{
        &:not(:last-of-type){
            margin-bottom: 40px;
        }
    }
    &__flex{
        width: 900px;
        &--01{
            display: flex;
            justify-content: space-between;

            @include sp {
                width:100%;
                flex-wrap:wrap;
                justify-content: space-evenly;
                margin: 0 auto;
            }
        }

        &--02{
            display: flex;
            align-items: center;

            @include sp {
                width:100%;
                flex-wrap:wrap;
                justify-content: center;
                flex-direction:column;
            }
        }

        &--LineUp{
            display: flex;
            justify-content: space-evenly;

            @include sp {
                width:100%;
                flex-wrap:wrap;
            }
        }
    }

    &__box{
        display:flex;
        @include sp {
            width:45%;
 
         }
    }
    
    &__imgBox{
        display:flex;
        flex-direction: column;
        align-items:center;
        margin-bottom:15px;
        text-align: center;

        @include sp {
           width:100%;

        }

    }
    &__imgBoxTtl{
        margin-bottom: 15px;;
    }

    &__img{
        @include sp {
            width:100%;
        }
 
        &--mb{
            margin-bottom: 15px;

        }
        &--typeimg{
            width:85%;
        }

        &--typeimg_2{
            display: block;
            margin-left: auto;
            margin-right: auto;
            @include sp {
                width:100%;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    &__imgBox_2{
        display: block;
        margin-left: auto;
        margin-right: auto;
        @include sp {
            width:55%;
            margin-left: 0;
            margin-right: 0;
        }
}
    &__img_mill{
        img{
        width:250px;
        height:250px;
        @include sp {
            width:100%;
            height:100%;
        }

        }
        &--mb{
            margin-bottom: 15px;

        }
        @include sp {
            width:100%;
 
        }
    }

    &__type{
        max-width: 412px;
        margin-right: 40px;
        @include sp {
            max-width:85%;
            margin-right: 0px;

        }
    }
    &__head{
        display:flex;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;

        &--01{
            @include sp {
                padding-left:20/750*100vw;
            }
        }

        @include sp {
            @include vw(36);
            width:90%;
            margin-left: 3vw;
        }
    }
    &__lineUp_head{
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    &__numTtl{
        font-size: 16px;
        font-weight: bold;

        @include sp {
            @include vw(30);
            text-align: left;
            width:90%;
            margin-left: 3vw;
        }
        
        &--mb{
            margin-bottom: 16px;
        }
        &--typeName{
            font-size: 20px;
            font-weight: bold;
            text-align: center;

            @include sp {
                @include vw(40);

            }
        }
        &--milltypeName{
            font-size: 18px;
            font-weight: bold;
            text-align: center;

            @include sp {
                @include vw(32);

            }
                
        }
        span{
            font-size: 16px;
            display: inline-block;
            margin-right: 16px;

            @include sp {
                @include vw(28);
                text-align: left;
                margin-left: -16/750*100vw;
                margin-right: 16/750*100vw;
            }

        }

        &--lpName{
            text-align: center;
            font-weight:normal;
            @include sp {
                @include vw(28);
                text-align: center;
                margin-left: 0vw;
                margin-right: 0vw;
                width: 100%;
            }

            span{
                font-size: 15px;
                display: inline-block;
                margin-right: 0px;
    
                @include sp {
                    @include vw(24);
                    text-align: center;
                    margin-left: 0vw;
                    margin-right: 0vw;
                    width: 100%;
                }
    
            }
    
    
        }


    }
    &__lead{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    &__shadowCont{
        @extend .u-shadow02;
        @extend .u-bg-ffffff;
        padding: 40px 30px 40px 40px;
        margin-bottom: 10px;
    }
    &__data{
    }
    &__noteCont{
        margin-bottom: 40px;
    }
    &__btn {
        display: block;
        width:220px;
        background-color: #444;
        padding: 10px;
        text-decoration: none;
        color: #fff;
        @include sp {
            @include vw(24);
            width:250/750*100vw;
        }
    }
}

.p-grinderData{
    &:not(:last-of-type){
        border-bottom: 1px solid #C7C7C7;
        margin-bottom: 16px;
        padding-bottom: 16px;
    }
    &__ttl{
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
    }
    &__txt{
        font-size: 14px;
    }
    &__flex{
        display: flex;
        justify-content: space-between;
    }
    &__q{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 30px;
        span{
            @extend .u-ft-gs;
            font-size: 20px;
            display: inline-block;
            margin-right: 11px;
        }
    }
    &__img01{
        max-width: 584px;
        margin: 0 auto;
    }
    &__img02{
        max-width: 657px;
        margin: 0 auto;
    }
    &__img03{
        max-width: 614px;
        margin: 0 auto;
    }
}

.p-grinderImgItem{
    @extend .u-shadow;
    background: $color_04;
    display: flex;
    justify-content: space-between;
    
    @include sp {
        align-items: center;
    }

    &__listCont{
        padding: 40px;
    }
    &__ttl{
        color: #CF0A2C;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    &__list{
        li{
            font-weight: bold;
            font-size: 15px;
            &:not(:last-of-type){
                margin-bottom: 18px;
            }
            span{
                @extend .u-ft-gs;
                font-size: 16px;
                display: inline-block;
                margin-right: 18px;
            }
        }
    }
    &__img{
        width: 284px;
        img{
            width: 100%;
        }
    }
}
.p-grinderPoint_sub{
    padding: 80px 0 0px;

    @include sp{
        padding: 50/750*100vw 0vw 0vw;
    }

    &__block{
        display: flex;
        flex-wrap:wrap;
        justify-content: space-between;

        &--etc{
            flex-direction: column;
        }

        &--aroma{
            padding: 50px 50px;

            @include sp{
                padding: 50/750*100vw 50/750*100vw
            }
        }
    }

    &__selectType{
        display: flex;
        flex-wrap:wrap;
        width:100%;
        flex-direction: column;
        align-content:flex-start;
        @include sp{
            flex-direction: column;
            width:100%;
        }
    }

    &__sec{
        padding-bottom: 0px;

        &:last-of-type{
            padding-bottom: 0;
        }
    }

    &__txtCont{
        max-width: 100%;
        &--578{
            max-width: 690px;
            @include sp{
                max-width: 100%;
            }
        }
        ul li {
            margin-left: 1rem;
            text-indent: -1rem;
          }
        ul li::before {
        display: inline;
        content: "・";
        }
        a{
            text-decoration: underline;
        }
    }
}