@charset "utf-8";

.m-kv{
    display: flex;
    position: relative;

    @include sp{
        display: block;
    }

    &__flag{
        position: absolute;
        left: 0;
        top: 124px;
        width: 55px;

        @include sp{
            top: 123/750*100vw;
            width: 70/750*100vw;
        }

        img{
            width: 100%;
        }
    }

    &__logo{
        background-color: $color_04;
        position: relative;
        width: 50%;

        @include sp{
            width: 100%;
            height: 342/750*100vw;
        }

        &--dc{
            background-color: $color_08;
        }

        &--mk{
            background-color: $color_09;
        }

        &--ps{
            background-color: $color_10;
        }

        &--zh{
            background-color: $color_05;
        }
        &--pp{
            background-color: $color_11;
        }

        &--lf{
            background-color: #8193A1;
            @include sp {
                display: none;
            }
        }
    }

    &__logoImg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin: auto;

        &--ml{
            width: 380px;
        }

        &--dc{
            width: 229px;
        }

        &--mk{
            width: 272px;
        }

        &--mk{
            width: 190px;
        }

        img{
            width: 100%;
        }

        &--pp{
            width: 190px;
        }
    }

    &__img{

        @media screen and (max-width: 1100px) {
            padding-left: 60px;
        }

        @include sp{
            padding-left: 0;
        }

        // 下層ページのkV背景画像
        &--ml{
            background: url(/assets/img/kv/kv_ml.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;
            background-position: center 15%;

            @include sp{
                background: url(/assets/img/kv/kv_ml_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        &--dc{
            background: url(/assets/img/kv/kv_dc.jpg) no-repeat center left;
            background-size: cover;
            width: 50%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_dc_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        &--mk{
            background: url(/assets/img/kv/kv_mk.jpg) no-repeat center left;
            background-size: cover;
            width: 50%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_mk_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        &--ps{
            background: url(/assets/img/kv/kv_ps.jpg) no-repeat top left;
            background-size: cover;
            width: 50%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_ps_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        &--zh{
            background: url(/assets/img/kv/kv_zh.jpg) no-repeat center left;
            background-size: cover;
            width: 50%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_zh_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        &--pp{
            background: url(/assets/img/kv/kv_pp.jpg) no-repeat center left;
            background-size: cover;
            width: 50%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_pp_sp.png) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        &--lf{
            background: url(/assets/img/kv/kv_lf.jpg) no-repeat center left;
            background-size: cover;
            width: 50%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_lf_sp.jpg) no-repeat center left;
                width: 100%;
                height: 50.13333vw;
                background-size: contain;
            }
        }

        // 採用情報
        &--recruit{
            background: url(/assets/img/kv/kv_recruit.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_recruit_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

         // 採用情報 営業（正社員・中途採用）
        &--recruit02{
            background: url(/assets/img/kv/kv_recruit02.jpg) no-repeat top left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_recruit_sp02.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

         // 採用情報 営業 マネージャー（正社員）
        &--recruit03{
            background: url(/assets/img/kv/kv_recruit03.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_recruit_sp03.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }


        // 個人情報保護方針
        &--privacy{
            background: url(/assets/img/kv/kv_privacy-policy.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_privacy-policy_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // 特定商取引に関する法律に基づく表示
        &--trade{
            background: url(/assets/img/kv/kv_trade-law.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_trade-law_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // カスタマーサポート
        &--support{
            background: url(/assets/img/kv/kv_support.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_support_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // 修理・故障
        &--afterSupport{
            background: url(/assets/img/kv/kv_after-support.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_after-support_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // 取扱説明書ダウンロード
        &--manual{
            background: url(/assets/img/kv/kv_manual.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_manual_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // 生産終了製品
        &--dp{
            background: url(/assets/img/kv/kv_discontinued-product.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_discontinued-product_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // 業務用販売終了製品
        &--dpp{
            background: url(/assets/img/kv/kv_discontinued-product-pro.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_discontinued-product-pro_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // 業務用製品情報ダウンロード
        &--pro{
            background: url(/assets/img/kv/kv_pro.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_pro_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // Melitta Club会員登録
        &--mailmag{
            background: url(/assets/img/kv/kv_mailmag.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_mailmag_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // トピックス一覧
        &--news{
            background: url(/assets/img/kv/kv_news.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_news_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // 営業所一覧
        &--office{
            background: url(/assets/img/kv/kv_office.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_office_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // よくあるご質問
        &--faq{
            background: url(/assets/img/kv/kv_faq.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_faq_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // サイトマップ
        &--sitemap{
            background: url(/assets/img/kv/kv_sitemap.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_sitemap_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // coffee-recipe.html メリタ製品を使ってつくるアレンジコーヒーレシピ
        &--coffeeRecipe{
            background: url(/assets/img/kv/kv_coffee-recipe.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_coffee-recipe_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }

        // history filter
        &--historyFilter{
            background: url(/assets/img/kv/history_filter_kv.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/history_filter_kv_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: cover;
            }
        }

        &--campaign{
            background: url(/assets/img/kv/kv_campaign.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 342px;

            @include sp{
                background: url(/assets/img/kv/kv_campaign_sp.jpg) no-repeat center left;
                width: 100%;
                height: 376/750*100vw;
                background-size: contain;
            }
        }
    }

    // 下層ページのロゴ
    &__box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;

        @include sp{
            width: 100%;
        }
    }

    &__leftBox{
        position: relative;
    }

    &__leftInner{
        margin: 0 auto;
        max-width: 990px;
        padding-top: 129px;
        width: 100%;

        @include sp{
            max-width: 100%;
            padding-top: 133/750*100vw;
            padding-left: 119/750*100vw;
        }

        &--2line{
            padding-top: 108px;

            @include sp{
                padding-top: 106/750*100vw;
            }
        }
    }

    &__leftTtl{
        font-size: 40px;
        line-height: 1.425;

        @include sp{
            @include vw(50);
            line-height:1.08;
        }
    }

    &__leftSub{
        font-size: 18px;
        font-weight: bold;
        line-height: 2.2;

        @include sp{
            @include vw(26);
        }
    }

    &__ttl{
        font-size: 40px;
        line-height: 1.4;

        @include sp{
            @include vw(40);
        }

        &--mb20{
            margin-bottom: 20px;

            @include sp{
                margin-bottom: 20/750*100vw;
            }
        }
    }

    &__mlLogo{
        margin: 0 auto;
        width: 236px;

        @include sp{
            width: 236/750*100vw;
        }

        &--mb12{
            margin-bottom: 12px;

            @include sp{
                margin-bottom: 12/750*100vw;
            }
        }

        &--w126{
            width: 126px;

            @include sp{
                width: 126/750*100vw;
            }
        }

        img{
            width: 100%;
        }

    }

}

// 高さ500px
.m-kvB{
    display: flex;
    position: relative;

    @include sp{
        display: block;
    }

    &__flag{
        position: absolute;
        left: 0;
        top: 203px;
        width: 55px;

        @include sp{
            top: 185/750*100vw;
            width: 70/750*100vw;
        }

        img{
            width: 100%;
        }
    }

    &__logo{
        background-color: $color_04;
        position: relative;
        width: 50%;

        @include sp{
            width: 100%;
            height: 342/750*100vw;
        }
    }

    &__logoImg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin: auto;

        img{
            width: 100%;
        }
    }

    &__img{

        @media screen and (max-width: 1100px) {
            padding-left: 60px;
        }

        @include sp{
            padding-left: 0;
        }

        // 本当に美味しいコーヒーを楽しむために
        &--enjoyment{
            background: url(/assets/img/kv/kv_enjoyment.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_enjoyment_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: contain;
            }
        }

        // コーヒーメーカーの選び方
        &--coffeemaker{
            background: url(/assets/img/kv/kv_coffermaker.png) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_coffeemaker_sp.png) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // ENJOYMENT 各記事KV

        &--enjoymentArticle {
            width: 100%;
            height: 500px;
            overflow: hidden;

            @include sp{
                width: 100%;
                height: 500/750*100vw;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                width: 100%;
                height: 500px;

                @include sp{
                    height: 500/750*100vw;
                }
            }
        }


        // コーヒーミル・グラインダーの選び方
        &--grinder{
            background: url(/assets/img/kv/kv_grinder.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_grinder_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // 美味しさの秘密／メリタ式抽出
        &--filtrationSystem{
            background: url(/assets/img/kv/kv_filtration-system.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_filtration-system_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // 環境貢献／FSC
        &--fsc{
            background: url(/assets/img/kv/kv_fsc.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_fsc_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // 美味しさの進化／プレミアムフィルター
        &--coffeefilter{
            background: url(/assets/img/kv/kv_coffeefilter.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_coffeefilter_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // MELITTA INTERVIEW 1
        &--coffeemakerInterview01{
            background: url(/assets/img/kv/kv_coffeemaker_interview01.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_coffeemaker_interview01_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // MELITTA INTERVIEW 2
        &--coffeemakerInterview02{
            background: url(/assets/img/kv/kv_coffeemaker_interview02.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_coffeemaker_interview02_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // アイスコーヒー
        &--icecoffee{
            background: url(/assets/img/kv/kv_icecoffee.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_icecoffee_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // コーヒーレシピ
        &--coffeerecipe{
            background: url(/assets/img/kv/kv_coffeerecipe.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_coffeerecipe_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // コーヒーレシピ下層
        &--coffeerecipeUnder{
            background: url(/assets/img/kv/kv_coffeerecipe_under.jpg) no-repeat center left;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_coffeerecipe_under_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // ペーパードリップコーヒーはメリタから始まった
        &--about{
            background: url(/assets/img/kv/kv_about.jpg) no-repeat right top;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_about_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // メリタとコーヒー進化のストーリー
        &--history{
            background: url(/assets/img/kv/kv_history.jpg) no-repeat right top;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_history_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // メリタとコーヒー進化のストーリー（リニューアル）
        &--history2{
            background: url(/assets/img/kv/kv_history2.jpg) no-repeat right top;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_history2_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // 美味しさの世界基準/メリタ ゴールドスタンダード
        &--goldstandard{
            background: url(/assets/img/kv/kv_goldstandard.jpg) no-repeat right top;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_goldstandard_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // おいしさの秘密 メリタ式抽出
        &--filtration_system{
            background: url(/assets/img/melitta-philosophy/filtration_system/kv_filtration_system.jpg) no-repeat right top;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/melitta-philosophy/filtration_system/kv_filtration_system_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }

        // おいしさの理由 独自のフィルターペーパー技術
        &--filter_paper{
            background: url(/assets/img/melitta-philosophy/filter_paper/kv_filter_paper.jpg) no-repeat right top;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/melitta-philosophy/filter_paper/kv_filter_paper_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }        

        // MELITTA PHILOSOPHY メリタのこだわり
        &--philosophy{
            background: url(/assets/img/kv/kv_philosophy.jpg) no-repeat right top;
            background-size: cover;
            width: 100%;
            height: 500px;

            @include sp{
                background: url(/assets/img/kv/kv_philosophy_sp.jpg) no-repeat center left;
                width: 100%;
                height: 500/750*100vw;
                background-size: cover;
            }
        }


    }

    &__leftBox{
        position: relative;
    }

    &__leftInner{
        margin: 0 auto;
        max-width: 990px;
        padding-top: 208px;
        width: 100%;

        @include sp{
            max-width: 100%;
            padding-top: 194/750*100vw;
            padding-left: 119/750*100vw;
        }

        &--2line{
            padding-top: 172px;

            @include sp{
                padding-top: 109/750*100vw;
            }
        }

        &--center {
            text-align: center;
            @include sp{
                padding-left: 40/750*100vw;
            }
        }

        &--num{
            padding-top: 190px;

            @include sp{
                padding-top: 171/750*100vw;
            }
        }
    }

    &__leftTtl{
        font-size: 40px;
        line-height: 1.425;
        margin-bottom: 10px;

        @include sp{
            @include vw(50);
            line-height:1.08;
            margin-bottom: 12/750*100vw;
        }
    }

    &__leftTtlNum{
        @extend .u-ft-gs;
        font-size: 70px;
        line-height: 1;
        display: inline-block;
        margin-left: 18px;
        position: relative;

        &::after{
            content: '';
            width: 26px;
            height: 5px;
            background-color: #CF0A2C;
            position: absolute;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
        }

        @include sp{
            @include vw(80);
            margin-left: 17/750*100vw;
            line-height: 0.5;

            &:after{
                height: 7/750*100vw;
                width: 38/750*100vw;
                bottom: -30/750*100vw;
            }
        }
    }

    &__leftSub{
        font-size: 18px;
        font-weight: bold;
        line-height: 2.2;

        @include sp{
            @include vw(26);
            line-height: 1.53;
        }
    }

    &__ttl{
        font-size: 40px;
        line-height: 1.4;

        @include sp{
            @include vw(40);
        }

        &--mb20{
            margin-bottom: 20px;

            @include sp{
                margin-bottom: 20/750*100vw;
            }
        }
    }

}
