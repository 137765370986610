@charset "utf-8";
.l-content01{
    margin: 0 auto;
    max-width: 1286px;

    @include sp {
        padding: 0;
        width: 690/750*100vw;
    }
}

.l-content02{
    position:relative;
    margin: 0 auto;
    max-width: 990px;

    @include sp {
        width: 690/750*100vw;
    }
}