@charset "utf-8";

.u-bg-ffffff {
    background-color: #ffffff !important;
}

.u-bg-000000 {
    background-color: #000000 !important;
}

.u-bg-444444 {
    background-color: #444444 !important;
}

// メリタ用グレー
.u-bg-d9d9d9 {
    background-color: #d9d9d9 !important;
}

.u-bg-fcfcfc {
    background-color: #FCFCFC !important;
}

.u-bg-c7c7c7 {
    background-color: #c7c7c7 !important;
}

.u-bg-f2f2f2 {
    background-color: #f2f2f2 !important;
}

.u-bg-e3e3e3 {
    background-color: #e3e3e3 !important;
}

// ダラコルテ用黄色
.u-bg-f6c631 {
    background-color: #f6c631 !important;
}

// ダラコルテ用黒
.u-bg-2e2e2e {
    background-color: #2e2e2e !important;
}

// ポアステディ用グレー
.u-bg-fbfbfb {
    background-color: #fbfbfb !important
}

.u-bg-d2e4dd {
    background-color: #D2E4DD !important
}

// マールクーニック・ザッセンハウス用赤
.u-bg-f20c19 {
    background-color: #F20C19 !important
}

// マールクーニック用グレー
.u-bg-ebebeb {
    background-color: #ebebeb !important
}

//ラテアートファクトリー用グレー
.u-bg-8193A1{
    background-color: #8193A1 !important
}


// SPのみ
.u-bg-fcfcfc-sp {
    @include sp{
        background-color: #F20C19 !important;
    }
}
// パックプレス・オレンジ
.u-bg-E76908 {
    background-color: #E76908 !important
}
