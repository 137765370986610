@charset "utf-8";

.m-prodInfoSlide{

    .swiper-wrapper{
        @include sp{
            display: block;
        }
    }

    &__inner{
        @include sp{
            width: 690/750*100vw;
            margin: 0 auto;
        }
    }

    .swiper-slide{
        &:last-of-type{
            .m-prodInfoSlide__item{

                @include sp{
                    margin-bottom: 0;
                }
            }
        }
    }

    &__item{
        position: relative;
        max-width: 372px;

        @include sp{
            margin-bottom: 80/750*100vw;
        }
    }

    &__img{
        margin-bottom: 16px;

        @include sp{
            margin-bottom: 24/750*100vw;
        }
    }

    &__txtCont{
        margin-bottom: 40px;

        @include sp{
            margin-bottom: 80/750*100vw;
        }
    }

    &__subTtl{
        @extend .m-txt-bold;
        font-size: 15px;

        @include sp{
            @include vw(30);
        }
    }

    &__ttl{
        @extend .m-txt-bold;
        font-size: 15px;
        margin-bottom: 16px;

        @include sp{
            @include vw(30);
            margin-bottom: 24/750*100vw;
        }
    }

    &__txt{
        font-size: 14px;
        margin-bottom: 24px;

        @include sp{
            @include vw(24);
            margin-bottom: 40/750*100vw;
        }
    }

    &__btn{
        @include sp{
            margin: 0 auto;
        }
    }

    &__more{
        padding-right: 60px;
        // position: absolute;
        // bottom: 110px;
        // right: 100px;
        // bottom: 0;
        // right: 0;

        @include sp{
            bottom: 180/750*100vw;
            right: 100/750*100vw;
        }
    }

    &__link{

        &:hover{
            .m-lineLinkS{
                letter-spacing: 0.2em;
                transition:0.5s all;
        
                &::after{
                    transform: scaleX(0.5);
                    background-color: $color_02;
                }
            }
        }
    }
}