@charset "utf-8";

.p-prod{
    padding-bottom: 84px;

    .spNone{
        @include sp{
         display: none;
        }
    }
    .logoP{
        margin-bottom: 15px;
        @include sp{
        margin-bottom: 15px;
        }
    }
    .logoP img{
        @include sp{
         width: auto;
         height: auto;
        }
    }


    &__bg{

        &--dc{
            background: url(/assets/img/common/bg_dc_prod.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom left;
            width: 100%;
            height: auto;

            @include sp{
                background: url(/assets/img/common/bg_dc_prod_sp.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: bottom left;
            }
        }

        &--mk{
            background: url(/assets/img/common/bg_mk_prod.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom left;
            width: 100%;
            height: auto;

            @include sp{
                background: url(/assets/img/common/bg_mk_prod_sp.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: bottom left;
            }
        }
        &--pp{
            background: url(/assets/img/common/bg_pp_prod.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom left;
            width: 100%;
            height: auto;

            @include sp{
                background: url(/assets/img/common/bg_pp_prod_sp.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: bottom left;
            }
        }
        &.Ttl_none {
          padding-bottom: 80px;
        }
    }

    &__mainSlideCont{
        display: flex;

        @include sp{
            display: block;
        }
    }
    &__infoSlideCont{
        display: flex;
        margin-bottom: 20px;
        padding-bottom: 40px;
        border-bottom: 1px solid $color_06;

        &--ak{
            flex-direction: column;
        }
        @include sp{
            display: block;
            margin-bottom: 40/750*100vw;
            padding-bottom: 80/750*100vw;
            height: 770/750*100vw;

            &--ak{
                height: 650/750*100vw;
            }
        }
    }

    &__infoSlideCont.addProvPdNone{
        margin-bottom: 0;
        @include sp{
            margin-bottom: 0;
            height: auto;
        }
    }


    &__logo{
        width: 45px;
        position: absolute;
        top: 250px;
        right: -45px;
        z-index: 1;

        @include sp{
            display: none;
        }

        img{
            width: 100%;
        }
    }

    &__discontinued{
        position: absolute;
        top: 11px;
        left: 10px;
        font-size: 16px;
        color: $color_02;
        text-align: left;
        line-height: 1.25;

        @include sp{
            @include vw(30);
        }

        &--small{
            font-size: 16px;

            @include sp{
                @include vw(30);
            }
        }
    }

    &__wpLayout {

        display: grid;
        grid-template-columns: 578px 1fr;
        grid-template-rows: max-content 1fr auto auto;

        grid-template-areas:
            "area2 area1"
            "area2 area3"
            "area4 area4"
            "area5 area5";

        @include sp{
            grid-template-columns: 100%;
            grid-template-rows: max-content max-content max-content max-content max-content;
            grid-template-areas:
                "area1"
                "area2"
                "area3"
                "area4"
                "area5";
        }
    }

    &__wpName {
        grid-area: area1;
    }

    &__wpSlide {
        grid-area: area2;
    }

    &__wpDetail {
        grid-area: area3;
    }

    &__wpTtl {
        grid-area: area4;
    }

    &__wpFreeSpace {
        grid-area: area5;
    }

}

// H1tag対応
.p-prodH1{
    margin-left: 80px;
    padding-bottom: 32px;
    @include sp{
        width:100%;
        margin-left: 0;
    }
}
.p-prodH1-2{
    margin-left: 80px;
    @include sp{
        width:100%;
        margin-left: 0;
    }
}
.smart-wrap{
    display: inline;
    @include sp{
      display: none;
    }
  }


// 商品情報
.p-prodDetail{
    margin-left: 80px;

    &--ml{
        top:135px;
    }
    &--ml2{
        top:146px;
    }
    &--ml3{
        top:135px;
    }
    &--ml4{
        top:156px;
    }

    &--dc{
        top:218px;
    }

    &--dc2{
        top:195px;
    }

    &--dc3{
        top:156px;
    }

    &--ps{
        top:175px;
    }

    &--mk{
        top:223px;
    }

    &--mk2{
        top:218px;
    }

    &--mk3{
        top:217px;
    }
    &--mk4{
        top:280px;
    }
    &--mk5{
        top:135px;
    }

    &--zh{
        top:188px;
    }
    &--pp{
        top:244px;
    }

    @include sp{
        margin-left: 0;
        top:0px;
    }



    &__type{
        font-size: 14px;
        margin-bottom: 15px;
        font-weight: bold;

        @include sp{
            @include vw(24);
            margin-bottom: 14/750*100vw;
        }
    }

    &__sub{
        display: block;
        font-size: 20px;
        line-height: 1.25;

        @include sp{
            @include vw(32);
            margin-bottom: 8/750*100vw;
        }
    }

    &__name{
        font-size: 26px;
        line-height: 1.54;
        // margin-bottom: 32px;
        margin-bottom: 0;
        white-space: nowrap;

        &--disproduct{
            margin-bottom: 0px;
        }
        &___mb0{
          margin-bottom: 0px;
        }
        @include sp{
            @include vw(40);
            margin-bottom: 24/750*100vw;
            white-space: normal;
        }

        &--2lines{
            white-space: normal;
            line-height: 1.3;
        }
    }
    &__name2{
        font-size: 26px;
        line-height: 1.54;
        margin-bottom: 0;
        white-space: nowrap;

        &--disproduct{
            margin-bottom: 0px;
        }
        @include sp{
            @include vw(40);
            margin-bottom: 24/750*100vw;
            white-space: normal;
        }

        &--2lines{
            white-space: normal;
            line-height: 1.3;
        }
    }
    &__note {
      position:relative;
      font-size: 14px;
      line-height: 1.4;
    //   margin-bottom: 32px;
      text-indent: -1em;
      margin-left: 1em;
      @include sp{
          @include vw(26);
          margin-bottom: 24/750*100vw;
          margin-top: 16/750*100vw;
      }
    }
    &__note_under {
      position:relative;
      font-size: 14px;
      line-height: 1.4;
      margin-top: 8px;
      @include sp{
          @include vw(26);
          margin-top: 16/750*100vw;
      }
    }
    // パックプレス別売品 改行位置調整用
    &__note_under_for_puqpress_mini {
        position:relative;
        font-size: 12.5px;
        line-height: 1.4;
        margin-top: 8px;
        @include sp{
            @include vw(26);
            margin-top: 16/750*100vw;
        }
      }
    &__price{
        position:relative;
        font-size: 16px;
        line-height: 2.5;
        margin-bottom: 16px;

        @include sp{
            @include vw(26);
            margin-bottom: 24/750*100vw;
            margin-top: 40/750*100vw;
        }

        span{
            display: inline-block;
            padding-left: 10px;
        }
    }

    &__description{
        font-size: 15px;
        margin-bottom: 16px;

        @include sp{
            @include vw(26);
            margin-bottom: 24/750*100vw;
        }
    }

    &__btn{
        width: 332px;

        @include sp{
            width: 100%;
        }

        img{
            width: 332px;

            @include sp{
                width: 100%;
            }
        }
    }

    &__btnMini{
        width: 161px;

        @include sp{
            // width: 100%;
            width: 48%;
            // display: block;
            display: inline-block;
            text-align: center;
            // margin-bottom: 24/750*100vw;
        }

        img{
            width: 161px;

            @include sp{
                // width: 70%;
                width: 90%;
            }
        }
    }

    &__colorCont{
        display: flex;
    }
    &__colorCont28{
        display: flex;
        margin-bottom: 28px;
    }
    &__color{
        display: inline-block;
        // margin-right: 30px;
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;

        @include sp{
            @include vw(26);
            // margin-right: 70/750*100vw;
        }
    }

    &__memo{
        font-size: 14px;
        white-space: nowrap;
        margin-top: 26px;
        line-height: 1.71;
        font-weight: bold;

        &--disproduct{
            margin-top: 0px;
        }
        &--disproduct_2{
            margin-top: -28px;
        }

        &--guatemala{
            margin-top: 10px;
        }

        @include sp{
            @include vw(26);
            margin-top: 16/750*100vw;
            white-space: normal;
        }

        .red{
            color: $color_02;
        }

        .light{
            font-weight: 100;
        }
    }

    &__memo_sec{
        font-size: 18px;
        white-space: nowrap;
        margin-top: 26px;
        line-height: 1.71;
        font-weight: bold;

        @include sp{
            @include vw(36);
            margin-top: 16/750*100vw;
        }

        span{
            font-size: 12px;
            white-space: normal;
            @include sp{
                white-space: normal;
                @include vw(24);
                margin-top: 16/750*100vw;
            }

        }

    }

    &__unrelease{
        font-size: 20px;
        line-height: 1.54;
        margin-bottom: 32px;
        white-space: nowrap;

        @include sp{
            @include vw(40);
            margin-bottom: 24/750*100vw;
            white-space: normal;
        }

        &--2lines{
            white-space: normal;
            line-height: 1.3;
        }
    }

    &__manualIcon {
        margin-top: 30px;
        font-family: $font-family-basic;

        @include sp{
            margin-top: 30px;
        }
        a {
            padding: 10px 10px 15px 60px;
            color: #000;
            background-image: url(/assets/img/common/manual_icon.png);
            background-size: contain;
            background-repeat: no-repeat;
            @include sp{
                padding-left: 50px;
            }
        }
    }

    &__quickStartIcon {
        margin-top: 40px;
        font-family: $font-family-basic;

        @include sp{
            margin-top: 50/750*100vw;
        }
        a {
            padding: 10px 10px 15px 60px;
            color: #000;
            background-image: url(/assets/img/common/quickstart_icon.png);
            background-size: contain;
            background-repeat: no-repeat;
            @include sp{
                padding-left: 50px;
            }
        }  
    }
}

// 色
.p-prodColor{
    display: flex;
    width:360px;
    flex-wrap: wrap;

    @include sp{
        width: 100%;
    }

    &__boxLay {
        width: 90px;
        display: inline-block;
        padding-bottom: 1rem;

        @include sp{
            width: 25%;
        }
    }

}
.p-prodColor210{
    margin-bottom: 49px;
    display: flex;
    @include sp{
        margin-bottom: 50/750*100vw;
        display: block;
        button {
          float: left;
          &:nth-of-type(even) {
            float:none;
          }
        }

    }
}
.p-prodColor:first-of-type .mainColorBtn {
    // margin-bottom:40px;

    @include sp{
        // margin-bottom: 150/750*100vw;
    }
   }


.p-prodTtl{
    width: 990px;
    text-align: center;
    // padding: 60px 0 80px;
    padding: 50px 0 0;

    &--ml{
        padding-top:91px;
    }

    &--dc{
        padding-top:149px;
    }
    &--dc2{
        padding-top:120px;
    }
    &--dc3{
        padding-top:278px;
    }
    &--dc4{
        padding-top:180px;
    }
    &--mk{
        padding-top:131px;
    }
    &--mk2{
        padding-top:126px;
    }
    &--mk3{
        padding-top:131px;
    }
    &--mk4{
        padding-top:80px;
    }
    &--mk5{
        padding-bottom:50px;
    }

    @include sp{
        width: 100%;
        text-align: left;
        padding: 40/750*100vw 0 0;

    }

    &__txt{
        font-size: 36px;

        @include sp{
            // @include vw(60);
            @include vw(36);
        }

    }

    &__logo{
        width: 280px;
        padding: 20px 0px;
        margin: 0 auto;
        img{
            width: 100%;
        }
        @include sp{
            img{
                width: 100%;
            }
        }

    }

}

.p-prodFreeSpace{
    width: 990px;
    padding-top: 50px;

    @include sp{
        width: 100%;
    }

}

// 色_2
.p-prodColor_2{
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    width:500px;
    height:250px;

    @media screen and (min-width :1200px) and (max-width:1500px){
        width:350px;
        height:320px;
        margin-bottom: 10px;
    }
    @media screen and (min-width: 990px) and (max-width:1200px){
        width:250px;
        height:440px;
        margin-bottom: 10px;
    }
    @include sp{
        margin-bottom: 205/750*100vw;
        margin-left:-20/750*100vw;
        justify-content: space-evenly
    }
}

// 色_3
.p-prodColor_3{
    margin-bottom: 60px;
    display: flex;

    @media screen and (min-width :990px) and (max-width:1500px){
        width:250px;
        height:250px;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    @include sp{
        width:250px;
        height:250px;
        flex-wrap: wrap;
        margin-bottom: 80/750*100vw;
        margin-left:-20/750*100vw;
        justify-content: space-evenly
    }
}

// 色_4
.p-prodColor_4{
    margin-bottom: 60px;
    display: flex;

    @media screen and (min-width :1200px) and (max-width:1800px){
        width:340px;
        height:210px;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    @media screen and (min-width: 990px) and (max-width:1200px){
        width:250px;
        height:250px;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    @include sp{
        margin-bottom: 50/750*100vw;
        flex-wrap: wrap;
    }
}

// MOVIE
.p-prodMovie{
    background-color: $color_07;
    padding: 10px 0 10px;

    @include sp{
        padding: 0px;

    }

    &__inner{
        width: 990px;
        margin: 0 auto;

        @include sp{
            width: 588/750*100vw;
        }
    }

    &__list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include sp{
            display: block;
        }
    }
    &__list_center{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include sp{
            display: block;
        }
    }
    &__item{

        @include sp{
            margin-bottom: 38/750*100vw;
        }
    }

    &__box{
        background-color:#2e2e2e;
        width: 470px;
        height: 315px;

        @include sp{
            width: 100%;
            height: 100%;
        }
    }
    &__box265{
        background-color:#2e2e2e;
        width: 470px;
        height: 265px;

        @include sp{
            width: 100%;
            height: 100%;
        }
    }
    
}

// short MOVIE
.p-prodMovieShort{
    &__box{
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        @include sp {
            width: 588/750*100vw;
        }
    }

    iframe {
        width: 300px;
        height: 533px;
        @include sp {
            width: 588/750*100vw;
            height: 1044/750*100vw;
        }
    }
}


// FEATURES
.p-prodFeatures{
    background-color: $color_07;
    padding: 58px 0 60px;

    @include sp{
        padding: 78/750*100vw 0 80/750*100vw;
    }

    &__inner{
        width: 990px;
        margin: 0 auto;

        @include sp{
            width: 580/750*100vw;
        }
    }

    &__list{
        display: flex;
        justify-content: space-between;

        @include sp{
            display: block;
        }
    }

    &__item{

        @include sp{
            margin-bottom: 38/750*100vw;
        }
    }

    &__box{
        background-color: $color_04;
        width: 300px;
        height: 300px;

        @include sp{
            width: 588/750*100vw;
            height: 588/750*100vw;
        }
    }

    &__head{
        color: $color_04;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;

        @include sp{
            @include vw(30);
            margin-bottom: 17/750*100vw;
        }
    }

    &__head_bk{
        color: $color_05;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;

        @include sp{
            @include vw(30);
            margin-bottom: 17/750*100vw;
        }
    }
    &__head_gy{
        color: #414042;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;

        @include sp{
            @include vw(30);
            margin-bottom: 17/750*100vw;
        }
    }
}


// PRODUCT INFORMATION
.p-prodInfo{
    padding: 58px 0 0;

    @include sp{
        padding: 78/750*100vw 0 80/750*100vw;
    }

    &__detailCont{
        display: flex;
        justify-content: space-between;

        @include sp{
            position: relative;
            flex-direction: column;
        }
    }

    &__detailImg{
        display: inline-block;
        position: relative;
        border: 1px solid $color_06;
        width: 578px;
        height: 358px;
        overflow: hidden;
        background-color: $color_04;

        &--ak{
            border: 0px solid #fff;
        }

        @include sp{
            width: 100%;
            height: 425/750*100vw;
        }
    }

    &__detail{
        width: 332px;
        &--ak{
            // margin-left:30px;
        }
        &--mv{
            width: 95%;
            padding-top: 0px;
        }
        @include sp{
            width: 100%;
            margin-left: 0;
            padding-top: 122/750*100vw;

            &--ak{
                padding: 22/750*100vw 0 0;
            }

            &--mv{
                width: 100%;
                padding-top: 0/750*100vw;
            }
            }
    }

    &__detailTxt{
        font-size: 26px;
        line-height: 1.71;
        font-weight: bold;

        @include sp{
            // @include vw(40);
            @include vw(36);
        }

        &--smalltxt{
            font-size: 18px;
            @include sp{
                @include vw(24);
            }
        }

        &--smalltxt_sec{
            font-size: 20px;
            @include sp{
                @include vw(30);
            }
        }

    }

    &__detailTxt_sec{
        font-size: 20px;
        line-height: 1.71;
        font-weight: bold;
        @include sp{
            @include vw(30);
        }
    }

    &__sec{
        padding: 24px 0 40px;
        margin-bottom: 20px;
        // padding: 0 0 20px;
        // border-top: 1px solid $color_06;
        border-bottom: 1px solid $color_06;

        @include sp{
            margin-bottom: 80/750*100vw;
            padding: 40/750*100vw 0 80/750*100vw;
        }

        &:last-of-type{
            border-bottom: 1px solid $color_06;
        }

        &--pb0{
            @include sp{
                padding: 40/750*100vw 0 0;
            }
        }
    }


    &__secBtNone{
        padding: 24px 0 40px;

        @include sp{
            padding: 40/750*100vw 0 80/750*100vw;
        }

        &:last-of-type{
            border-bottom: 1px solid $color_06;
        }
    }

    &__secTpNone{
        padding: 40px 0 40px;
        border-top: none;

        @include sp{
            padding: 40/750*100vw 0 80/750*100vw;
        }

        &:last-of-type{
            border-bottom: 1px solid $color_06;
        }

        &--pb0{
            @include sp{
                padding: 40/750*100vw 0 0;
            }
        }
    }

    &.addProvPdNone{
        padding-top: 0;
        @include sp{
            padding-bottom: 0;
        }
    }

    &__movie {
        // padding: 40px 0 40px;
        padding: 24px 0 40px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color_06;

        @include sp{
            margin-bottom: 80/750*100vw;
            // padding: 70/750*100vw 0 20/750*100vw;
            padding: 40/750*100vw 0 80/750*100vw;
        }
    }


    &__secHead{
        font-size: 20px;
        font-weight: bold;

        @include sp{
            @include vw(34);
        }
    }


    &__tableCont{
        display: flex;
        // overflow:hidden;
        @include sp{
            display: block;
        }
    }

    &__j_asterisk {
        margin-left: 1rem;
        text-indent: -1rem;
    }

    &__flowGray {
        padding: 25px;
        @include sp{
            padding: 50/750*100vw;
        }
    }

    &__manualIcon {
        font-family: $font-family-basic;
        margin-bottom: 60px;
        @include sp{
            width: 690/750*100vw;
            margin: 30/750*100vw auto 80/750*100vw;
        }
        a {
            padding: 10px 10px 15px 60px;
            color: #000;
            background-image: url(/assets/img/common/manual_icon.png);
            background-size: contain;
            background-repeat: no-repeat;
            @include sp{
                padding-left: 50px;
            }
        }
    }

    // EK43 アフターサポート申込STEP

    &__flowStep {
        display: flex;
        align-items: center;
        align-items: stretch;
        width: 100%;

        &--left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15%;
            padding: 18px;
            background-color: #e3e3e3;

            @include sp{
                width: 25%;
            }

            img {
                width: 80px;

                @include sp{
                    width: 100%;
                }
            }
            
        }

        &--right {
            width: 85%;
            padding: 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: 1px solid #e3e3e3;

            @include sp{
                width: 75%;
            }
        }
    }

    &__flowStepTxtStep {
        font-weight: 700;
        color: $color_02;
    }


    &__flowStepTxtCenter {
        text-align: center;
    }

    &__repairCircle {
        width: fit-content;
        margin: 30px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;

        @include sp{
            flex-direction: column;
            gap: 10px;
        }

    }

    &__circleWidth {
        display: block;
        width: 213px;

        img {
            width: 100%;
        }
    }

    &__repairBox {
        padding: 30px;
        border: 1px solid #e3e3e3;

        &--pdf {
            display: flex;
            align-items: center;
            gap: 10px;

            a {
                text-decoration: underline;
            }

            &:before {
                display: inline-block;
                content: "";
                width: 28px;
                height: 28px;
                background-image: url(/assets/img/common/icon_pdf.png);
                background-size: contain;
            }
        }

        &--xls {
            display: flex;
            align-items: center;
            gap: 10px;

            a {
                text-decoration: underline;
            }

            &:before {
                display: inline-block;
                content: "";
                width: 28px;
                height: 28px;
                background-image: url(/assets/img/common/icon_excel.png);
                background-size: contain;
            }
        }
    }






}

.p-prodInfo2{
    padding: 78px 0 0;

    @include sp{
        padding: 20/750*100vw 0 80/750*100vw;
    }
}

.p-prodInfo3{
    padding: 78px 0 0;

    @include sp{
        padding: 20/750*100vw 0;
    }
}

.pc-none {
  display: none;
  @include sp{
      display: block;
  }
}
.sp-none {
  display: block;
  @include sp{
      display: none;
  }
}
.p-howto{
    &__cont{
        font-size: 20px;
        margin-left: 40px;

        @include sp{
            @include vw(24);
            margin-left: 0px;
        }
    }

    &__inner{
    width: 990px;
    margin: 0 auto;

    @include sp{
        width: 580/750*100vw;
    }
    }
}
.rtu {
  &__inner {
    .rtu__img {
      width: 300px;
      display: block;
      @include sp{
        margin-left: auto;
        margin-right: auto;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .rtu__detail {
      margin-left: 30px;
      flex: 1;
      @include sp{
        margin-left: 0;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        &.main_txt {
          margin-bottom: 1em;
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
.p-prodTable{
    &__line{
        display: table;
		width: 515px;

        @include sp{
            width: 100%;
        }

    }

    &__block {
		display: table-cell;
		border: solid 1px $color_04;
        padding: 9.5px 10px;
        font-size: 14px;
        word-break: break-all;
        vertical-align: middle;
        a {
          text-decoration: underline;
        }
        @include sp{
            @include vw(24);
        }

        &.ttl{
            background-color: $color_07;
            color: $color_04;
            width: 130px;
            vertical-align: middle;

            @include sp{
                width: 231/750*100vw;
            }
        }
        ul.listWrp {
            li {
                margin-left: 1.4em;
                list-style-type: disc;
                font-size: 14px;
                @include sp{
                    @include vw(24);
                }
            }
        }

        .detailWrp {
            display: flex;

            .txt-left {
                font-size: 14px;
                @include sp{
                    @include vw(24);
                }
            }

            .txt-right {
                padding-left: 0.5em;
                font-size: 14px;
                @include sp{
                    @include vw(24);
                }
            }
        }

        .detailAst {
            text-indent: -1em;
            margin: 1em 0 0 1em;
            font-size: 0.8em;
        }
	}

    &--dc{
        .p-prodTable{
            &__block{
                border: solid 1px #2E2E2E;
                a {
                  color: #fff;
                }

                &.ttl{
                    background-color: #C7C7C7;
                    color: $color_05;
                }
            }
        }
    }

    &--mk{
        .p-prodTable{
            &__block{
                border: solid 1px #EBEBEB;

                &.ttl{
                    background-color: $color_05;
                    color: $color_04;
                }
                &.mahlkonigRed{
                    background-color: #ff0000;

                }
            }
        }
    }

    &--ps{
        .p-prodTable{
            &__block{
                border: solid 1px #FBFBFB;

                &.ttl{
                    background-color: #D9D9D9;
                    color: $color_05;
                }
            }
        }
    }

    &--lf{
        .p-prodTable{
            &__block{
                border: solid 1px #FBFBFB;

                &.ttl{
                    background-color: #D9D9D9;
                    color: $color_05;
                }
            }
        }
    }
}

.p-prodAcceList{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &--OptItem{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

    }

    &--OptItem5{
        flex-wrap: wrap;
        width:750px;
    }
    &--text{
        flex-direction: column;
        margin-left:30px;
        text-decoration: underline;
        ul li{
            // list-style: square;
            list-style: disc;
        }

    }
    &--textonly{
        flex-direction: column;
        margin-left:30px;
        text-decoration: none;
        ul li{
            // list-style: square;
            list-style: disc;
            a {
              text-decoration: underline;
            }
        }
    }

    &__item {
        width: 25%;
        @include sp{
            width: 50%;
        }
    }

    @include sp{
        flex-wrap: wrap;
        width:100%;
    }

    &__box{
        width: 216px;
        height: 216px;
        margin-left: auto;
        margin-right: auto;

        @include sp{
            width: 333/750*100vw;
            margin-bottom: 40/750*100vw;
        }
    }
}

// 取説ページ 別売品5列
.p-prodAcceList2{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &--OptItem{
        flex-wrap: wrap;
        width:900px;
    }
    &--OptItem5{
        flex-wrap: wrap;
        width:750px;
    }
    &--text{
        flex-direction: column;
        margin-left:30px;
        text-decoration: underline;
        ul li{
            list-style: square;
        }

    }
    &--textonly{
        flex-direction: column;
        margin-left:30px;
        text-decoration: none;
        ul li{
            list-style: square;
            a {
              text-decoration: underline;
            }
        }

    }
    @include sp{
        flex-wrap: wrap;
        width:100%;
    }

    &__box{
        width: 198px;
        height: 198px;

        @include sp{
            width: 333/750*100vw;
            height: auto;
            margin-bottom: 40/750*100vw;
        }
    }
}

//WP用 リッチエディタaタグ対応

.p-prodAcceList3 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    
    &__item {
        width: 25%;
        height: 216px;

        figure {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }

        @include sp{
            width: 50%;
        }
    }

}


.p-prodAcceText{
    display: flex;
    ul li{
        list-style: square;
    }
}

// カラーバリエーション
.p-prodColorVariation {
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding:40px 0;
    @include sp{
        padding-top: 10px;
        flex-flow: column;
    }

    &__left-box {
        width: 48%;
        @include sp{
            width: 100%;
        }
    }
    &__right-box {
        width: 48%;
        @include sp{
            width: 100%;
        }
    }

    &__txt1 {
        margin-bottom: 1em;
        font-weight: 700;
        font-size: 1.55rem;
        @include sp{
            margin-top: 10px;
            font-size: 1rem;
        }
    }

    &__txt2 {
        font-size: 1rem;
        text-indent: -1rem;
        margin-left: 1rem;
        @include sp{
            font-size: 0.7rem;
            text-indent: -0.7rem;
            margin-left: 0.7rem;
        }
    }
}

// PHOTO GALLERY (EK43)
.p-prodPhotoGallery{
    background-color: $color_07;
    padding: 58px 0px;
    margin: 40px 0 0;

    @include sp{
        margin-top: 0;
        padding: 78/750*100vw 0 80/750*100vw;
    }

    &__inner{
        width: 990px;
        margin: 0 auto;
        
        @include sp{
            width: 580/750*100vw;
        }

        li {
            padding: 0 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .slick-next,
        .slick-prev {
            width: 30px;
            position: absolute;
            @include sp{
                width: 20px;
            }
        }

        .slick-next:before,
        .slick-prev:before {
            position: relative;
            content: '';
            display: block;
            border-bottom: solid 2px #fff;
            border-left: solid 2px #fff;
            width: 30px;
            height: 30px;
            font-size: 0;
            @include sp{
                width: 20px;
                height: 20px;
            }
        }

        .slick-next:before {
            transform: rotate(-135deg);
        }

        .slick-prev:before {
            transform: rotate(45deg);
        }
    }
}

// PHOTO GALLERY (hasamifilter)
.p-prodPhotoGallery2{
    background-color: $color_07;
    padding: 58px 0px;
    margin: 40px 0 0;

    @include sp{
        margin-top: 0;
        padding: 78/750*100vw 0 80/750*100vw;
    }

    &__inner{
        width: 990px;
        margin: 0 auto;
        
        @include sp{
            width: 580/750*100vw;
        }

        li {
            padding: 0 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .slick-next,
        .slick-prev {
            width: 30px;
            position: absolute;
            @include sp{
                width: 20px;
            }
        }

        .slick-next:before,
        .slick-prev:before {
            position: relative;
            content: '';
            display: block;
            border-bottom: solid 2px #444;
            border-left: solid 2px #444;
            width: 30px;
            height: 30px;
            font-size: 0;
            @include sp{
                width: 20px;
                height: 20px;
            }
        }

        .slick-next:before {
            transform: rotate(-135deg);
        }

        .slick-prev:before {
            transform: rotate(45deg);
        }
    }
}


// PRODUCT NAVIGATION ONLY SP
.p-prodNavi{
    padding: 78px 0 0;
    @include sp{
        padding: 110/750*100vw 0 20/750*100vw;
    }
    &__flex-wrp {
        display: flex;  
        flex-wrap: wrap;
        align-items: stretch;
    }

    &__flex-item1,
    &__flex-item2,
    &__flex-item3 {
        display: flex;
        text-align: center;
        background-color: #e3e3e3;
        border: 1px solid #fff;
        font-size: 14px;
        line-height: 1.7;
        @include sp{
            @include vw(26);
            line-height: 1.5;
        }
    }

    &__flex-item1 {
        width: 100%;
    }
    &__flex-item2,
    &__flex-item3 {
        width: 50%;
    }

    &__flex-item-active {
        background-color: #c7c7c7;
        a {
            color: $color_05;
        }
    }
    
    &__flex-item1 a,
    &__flex-item2 a,
    &__flex-item3 a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 60/750*100vw 0;
    }

    &__flex-item1 a u,
    &__flex-item2 a u,
    &__flex-item3 a u {
        display: block;
        width: 98%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    // リンク先、固定ヘッダによるズレの調整（1番目用）
    &__link-location-adjust {
        position: relative;
        display: block;
        margin-top: -30px;
        padding-top: 30px;
        width: 1px; // 前要素の選択不可を回避

        @include sp{
            margin-top: -65px;
            padding-top: 65px;
        }
        
    }

    // リンク先、固定ヘッダによるズレの調整（2番目以降用）
    &__link-location-adjust2 {
        position: relative;
        display: block;
        margin-top: -100px;
        padding-top: 100px;
        width: 1px; // 前要素の選択不可を回避

        @include sp{
            margin-top: -65px;
            padding-top: 65px;
        }
        
    }

}