@charset "utf-8";

.m-acc{

    &__q{
        cursor: pointer;
        position: relative;

        &::before{
            content: '';
            width: 24px;
            height: 2px;
            background: #CF0A2C;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
    
            @include sp{
                width: 40/750*100vw;
                height: 2/750*100vw;
                right: 40/750*100vw;
            }
        }
    
        &::after{
            content: '';
            width: 24px;
            height: 2px;
            background: #CF0A2C;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%) rotate(90deg);
            transition: .5s;
    
            @include sp{
                width: 40/750*100vw;
                height: 2/750*100vw;
                right: 40/750*100vw;
            }
        }
    
        &.is-open{
            &::after{
                transform: translateY(-50%) rotate(0deg);
            }
        }
    }

    &__inner{
        display: flex;
        padding: 4% 10%;

        @include sp{
            align-items: flex-start;
            padding: 70/750*100vw 102/750*100vw 70/750*100vw 40/750*100vw;
        }
    }

    &__a{
        display: none;
    }

    &__num{
        width: 74px;
        line-height: 1.4;
        
        @include sp{
            width: 100/690*100vw;
            line-height: 1.05;
        }
    }

    &__txtSec{
        margin-bottom: 40px;
        @include sp{
            margin-bottom: 80/750*100vw;
            width: 100%;
        }

        &:last-of-type{
            margin-bottom: 0;
        }
    }

    &__txtCont{
        padding-top: 8px;
        max-width: 727px;

        @include sp{
            padding-top: 0;
            width: 100%;
        }

        a{
            text-decoration: underline !important;
        }
    }

    &__qTxt{
        padding-top: 8px;

        @include sp{
            padding-top: 0;
            line-height: 1.47;
            width: 100%;
        }
    }

    &__aTtl{
        line-height: 1.5;
    }

    &__aTxt{
    }

    &__item{
        text-indent: -2.5em;
        padding-left: 2.5em;
    }

}