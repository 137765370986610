@charset "utf-8";

.m-imageBox{
    margin: 0 auto;
    max-width: 990px;
    width: 100%;
    display: flex;
    position: relative;

    @include sp{
    }

    &__bg{
        height: 570px;
        transition: .5s;
        overflow: hidden;
        max-width: 784px;
        width: 100%;

            @include sp{
                height: 860/750*100vw;
                width: 690/750*100vw;
                margin: 0 auto;
            }

        &--right{
            margin-left: auto;
        }

        img{
            transition: all .4s ease-out;
        }

        &.is-active{
            img{
                transition: all .4s ease-out;
                transform: scale(1.15);
            }
        }

    }

    &__boxCont{
        display: block;
        position: absolute;

        @include sp{
            padding-top: 210px;
        }

        &:hover{
            opacity: 1;
        }

        &--right{
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @include sp{
                // margin-left: 0;
            }
        }

        &--left{
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            @include sp{
                // margin-left: 0;
            }
        }

    }

    &__box{
        width: 475px;
        display: block;
        background-color: #fff;

        @include sp{
            margin: 0 auto;
            width: 336px;
        }
    }

    &__boxInner{
        max-width: 475px;
        width: 100%;
        padding: 25% 12% 14% 13%;

        @include sp{
            padding: 64/750*100vw 81/750*100vw 64/750*100vw 51/750*100vw;
        }
    }

    &__subTtl{
        margin-bottom: 8px;

        @include sp{
            margin-bottom: 8/750*100vw;
        }
    }

    &__ttl{
        margin-bottom: 16px;

        @include sp{
            margin-bottom: 24/750*100vw;
        }
    }

    &__txt{
        margin-bottom: 40px;

        @include sp{
            margin-bottom: 40/750*100vw;
        }
    }

    &__link{
        text-align: right;
        width: 80%;
    }
}