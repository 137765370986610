@charset "utf-8";

.p-interview {
    @include pc {
        background: linear-gradient(90deg, $color_04 0%, $color_04 50%, #F2F2F2 50%, #F2F2F2 100%);
    }
    &__inner {
        @include pc {
            display: flex;
        }  
    }
    &__outline {
        background-color: $color_04;
        @include pc {
            order: 1;
            padding: 126px 42px 160px 42px;
            width: 456 /1286*100%;
        }
        @include sp{
            margin-left: -30/750*100vw;
            margin-right: -30/750*100vw;
            padding: 120/750*100vw 30/750*100vw 160/750*100vw 30/750*100vw;
        }
    }
    &__contents {
        background-color: #F2F2F2;
        @include pc {
            order: 2;
            padding: 180px 55px 160px 55px;
            width: 830 /1286*100%;
        }
        @include sp{
            margin-left: -30/750*100vw;
            margin-right: -30/750*100vw;
            padding: 200/750*100vw 30/750*100vw 160/750*100vw 30/750*100vw;
        }
    }
}

.p-interviewTtl {
    background-color: $color_04;
    @extend .m-mb40;
    @include pc {
        display: flex;
    }
    &:not(:first-child) {
        margin-top: 80px;
        @include sp{
            margin-top: 80/750*100vw;
        }
    }
    &__txt {
        align-items: center;
        display: flex;
        font-size: 20px;
        font-weight: 700;
        justify-content: center;
        line-height: 2;
        @include pc {
            flex: 1 1 auto;
            order: 1;
        }
        @media screen and (min-width: 751px) and (max-width: 1286px) {
            font-size: 20/1286*100vw;
        }
        @include sp {
            @include vw(40);
            height: 300/750*100vw;
            line-height: 1.5;
            text-align: center;
        }
    }
    &__img {
        @include pc {
            flex: 0 0 auto;
            order: 2;
            width: 309 /720*100%;
        }
        img {
            width: 100%
        }
    }
}

.p-interviewCaption {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 40px;
    @include sp {
        @include vw(40);
        margin-bottom: 80/750*100vw;
        margin-top: 80/750*100vw;
    }
}

.p-interviewList {
    &__item {
        &:not(:last-child) {
            @extend .m-mb40;
        }
    }
    &__ttl {
        @extend .m-txt-fs15F;
        font-weight: bold;
        &--emphasis {
            color: #CF0A2C;
        }
    }
    &__txt {
        @extend .m-txt-fs15F;
    }
}

.p-interviewSource {
    @include pc {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
    }
    @include sp {
        margin-top: 80/750*100vw;
        text-align: center;
    }
    &__img {
        @include pc {
            margin-left: 16px;
            width: 70px;
        }
        @include sp {
            display: inline-block;
            margin-top: 24/750*100vw;
            width: 153/750*100vw;
        }
        img {
            width: 100%;
        }
    }
    &__txt {
        @extend .m-txt-fs14;
    }
}

.p-interviewOutline {
    &__section {
        &:not(:first-child) {
            margin-top: 120px;
            @include sp{
                margin-top: 120/750*100vw;
            }
        }
    }
    &__ttl {
        @extend .u-ft-gs;
        @extend .m-txt-fs24;
        @extend .m-mb16B;
    }
    &__img {
        @extend .m-mb16D;
        img {
            width: 100%;
        }
    }
    &__caption {
        font-weight: bold;
        @extend .m-txt-fs16;
        @extend .m-mb16D;
    }
    &__txt {
        @extend .m-txt-fs14;
        @extend .m-mb16D;
    }
    &__btn {
        .m-btnB {
            width: 100%;
        }
    }
}