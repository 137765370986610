@charset "utf-8";
.m-toTop{
  font-size: 10px;
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100px;
  height: 100px;
  background-color: $color_02;
  text-align: center;

  @include sp{
    @include vw(20);
    width: 132/750*100vw;
    height: 132/750*100vw;
  }

  span{
    color: $color_04;
    display: block;
    padding-top: 60px;
    transition: .3s;

    @include sp{
      padding-top: 65/750*100vw;
    }
  }

  &:after{
    content: '';
    position: absolute;
    top: 42%;
    left: 44%;
    border-top: solid 2px $color_04;
    border-left: solid 2px $color_04;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    display: inline-block;
    transition: .3s;

    @include sp{
      top: 30%;
      width: 14/750*100vw;
      height: 14/750*100vw;
    }
  }

  &:hover{
    opacity: 1;

    &:after{
      top: 30%;

      @include sp{
        top: 20%;
      }
    }

    span{
      transition: .3s;
      letter-spacing: 0.12em;
    }
  }

}
