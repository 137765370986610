@charset "utf-8";

.m-ttlA{
  font-size: 30px;
  color: $color_05;
  line-height: 1.43;

  @include sp{
    // @include vw(56);
    @include vw(50);
  }

  // 白
  &--wh{
    color: $color_04;
  }

  // 黒
  &--bk{
    color: $color_05;
  }

  // RGB=65.64.66
  &--gy{
    color: #414042;
  }
}

.m-ttlB{
  font-size: 20px;
  color: $color_05;

    // 白
    &--wh{
      color: $color_04;
    }
    &--fw{
      font-weight: lighter
    }

  @include sp{
    @include vw(56);
  }
}

.m-ttlC{
  font-size: 30px;
  line-height: 1.43;

  @include sp{
    @include vw(40);
  }

  // 日本語サブタイトル
  &__sub{
    font-size: 18px;
    display: block;

    @include sp{
      @include vw(30);
    }
  }
}

.m-ttlD{
  font-size: 30px;

  @include sp{
    @include vw(40);
  }

  // 日本語サブタイトル
  &__sub{
    font-size: 20px;
    display: block;

    @include sp{
      @include vw(30);
    }
  }
}

.m-ttlE{
  font-size: 28px;

  @include sp{
    @include vw(40);
  }

   // 日本語サブタイトル
  &__sub{
    font-size: 20px;
    display: block;

    @include sp{
      @include vw(30);
    }
  }
}

.m-ttlF{
  font-size: 28px;
  line-height: 1.41;

  @include sp{
    @include vw(30);
    line-height: 1.43;
  }

   // 日本語サブタイトル
  &__sub{
    font-size: 20px;
    display: block;

    @include sp{
      @include vw(30);
    }
  }
}

.m-ttlG{
  font-size: 32px;
  line-height: 0.93;

  @include sp{
    @include vw(40);
    line-height: 1.425;
  }

   // 日本語サブタイトル
  &__sub{
    font-size: 28px;
    display: block;

    @include sp{
      @include vw(30);
      line-height: 1.66;
    }
  }
}

.m-ttlH{
  font-size: 28px;

  @include sp{
    @include vw(37);
  }

   // 日本語サブタイトル
  &__sub{
    font-size: 20px;
    display: block;

    @include sp{
      @include vw(30);
    }
  }
}

.m-ttlTp{
  font-size: 25px;

  @include sp{
    @include vw(37);
  }

}


.m-lineTtl{
  width: 100%;
  position: relative;
  border-bottom: 1px solid #707070;
  padding-bottom: 16px;
  font-size: 20px;

  @include sp{
    @include vw(30);
    padding-bottom: 16/750*100vw;
  }

  // &::before{
  //   content: '';
  //   position: absolute;
  //   width: 80px;
  //   bottom: -1px;
  //   left: 0;
  //   border-bottom: 1px solid #CF0A2C;
  //   position: absolute;

  //   @include sp{
  //     width: 80/750*100vw;
  //   }
  // }

  &__subtitle {
    font-weight: bold;
  }

  // 右に余白
  &__head{
    margin-right: 16px;

    @include sp{
      margin-right: 24/750*100vw;
    }
  }

  &--dc{
    &::before{
      content: '';
      position: absolute;
      width: 80px;
      bottom: -1px;
      left: 0;
      border-bottom: 1px solid $color_08;
      position: absolute;
    }
  }

  &--ps{
    border-bottom: 1px solid $color_06;

    &::before{
      content: '';
      position: absolute;
      width: 80px;
      bottom: -1px;
      left: 0;
      border-bottom: 1px solid $color_10;
      position: absolute;
    }
  }

  &--mk{
    &::before{
      content: '';
      position: absolute;
      width: 80px;
      bottom: -1px;
      left: 0;
      border-bottom: 1px solid $color_09;
      position: absolute;
    }
  }
}

// 横並び用
.m-ttlFlex{
  display: flex;
  align-items: center;

  @include sp{
    align-items: flex-start;
  }
}

.m-ttlLabel{
  color: #CF0A2C;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #CF0A2C;
  display: inline-block;
  padding: 17px 24px 16px;
  margin-right: 16px;
  white-space: nowrap;

  @include sp{
    padding: 27/750*100vw 40/750*100vw 26/750*100vw 38/750*100vw;
    margin-right: 30/750*100vw;
  }
}