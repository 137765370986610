@charset "utf-8";

.m-iconTtl{
  position: relative;
  padding: 100px 0;
  width: 100%;

  @include sp{
    padding: 138/750*100vw 0 80/750*100vw;
  }

  &__dp{
    padding-bottom: 50px;
    @include sp{
      padding-bottom: 50/750*100vw;
    }
  }

  &__dpp{
    padding-bottom: 50px;
    @include sp{
      padding-bottom: 50/750*100vw;
    }
  }

  &__gs{
    @include sp{
      padding: 0vw 0 80/750*100vw;
    }
    }

  &__inner{
    max-width: 990px;
    width: 100%;
    text-align: center;
    margin: 0 auto;

    @include sp{
      max-width: 100%;
    }
  }

  &__icon{
    width: 86px;
    height: 86px;
    margin: 0 auto 24px;

    @include sp{
      width: 118/750*100vw;
      height: 118/750*100vw;
      margin: 0 auto 50/750*100vw;
    }

    &--afterSupport{
      background: url(/assets/img/support/support_repair.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--manual{
      background: url(/assets/img/support/support_download.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--dp{
      background: url(/assets/img/support/support_discontinued-product.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--dpp{
      background: url(/assets/img/support/support_discontinued-product.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pro{
      background: url(/assets/img/support/support_pro.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--faq{
      background: url(/assets/img/support/support_faq.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--club{
      background: url(/assets/img/support/support_club.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--repairCarton{
      background: url(/assets/img/support/support_carton.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--goldstandard{
      background: url(/assets/img/melitta-philosophy/goldstandard/melitta_goldstandard.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--filtration_system{
      background: url(/assets/img/melitta-philosophy/filtration_system/melitta_filtration_system.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--filter_paper{
      background: url(/assets/img/melitta-philosophy/filter_paper/melitta_filter_paper.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__ttl{
    margin-bottom: 24px;

    @include sp{
      @include vw(40);
      margin-bottom: 20/750*100vw;
    }
  }

  &__txt{
    font-size: 15px;
    line-height: 2 !important;

    @include sp{
      @include vw(26); 
    }

    .red{
      color: #CF0A2C;
    }

    .maincolor{
      color: #3476b8;
    }
  }

  &__rcMenu{
    display:flex;
    justify-content: center;
    width:100%;
    text-align: left;
    margin-top:20px;
    
    li{
      list-style-type: disc;
    }
    span{
      border-bottom: solid 1px black;
      
    }
  }

}

// 高さ低いバージョン
.m-iconTtlB{
  position: relative;
  padding: 120px 0 60px;
  width: 100%;

  @include sp{
    padding: 120/750*100vw 0 80/750*100vw;
  }

  &__inner{
    max-width: 990px;
    width: 100%;
    text-align: center;
    margin: 0 auto;

    @include sp{
      max-width: 100%;
    }
  }

  &__icon{
    width: 104px;
    height: 92px;
    margin: 0 auto 20px;

    @include sp{
      width: 130/750*100vw;
      height: 117/750*100vw;
      margin: 0 auto 50/750*100vw;
    }

    &--video{
      background: url(/assets/img/enjoyment/enjoyment_video.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

  }

  &__ttl{
    margin-bottom: 16px;

    @include sp{
      @include vw(40);
      margin-bottom: 20/750*100vw;
    }
  }

  &__txt{

    @include sp{
      @include vw(26); 
    }

    .red{
      color: #CF0A2C;
    }
  }

}