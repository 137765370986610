@charset "utf-8";


// 年表
.m-historyFilter{
    @extend .u-bg-000000;
    @extend .u-color-ffffff;
    max-width: 100%;

    @include pc{
        padding-top: 32px;
    }

    @include sp{
        padding-top: 40/750*100vw;
    }
}

.m-historyFilter__leadCont{
    @include pc{
        text-align: center;
        margin-bottom: 18px;
    }
    @include sp{
        text-align: left;
        padding-left: 100/750*100vw;
        
        margin-bottom: 80/750*100vw;
    }
}

.m-historyFilter__lead{
    font-weight: bold;

    @include sp{
        @include vw(30);
    }
}

.m-historyFilter__cont{
    @include pc{
        padding: 250px 0 0;
        max-width: 990px;
        margin: 0 auto;
    }

    @include sp{
        width: 650/750*100vw;
        margin-left: auto;
        margin-right: 30/750*100vw;
    }
    
}

.m-historyFilter__line {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include sp{
        flex-direction: column;
    }

    &--spReverse{
        @include sp{
            .m-historyFilter__left{
                order: 2;
            }
            .m-historyFilter__right{
                order: 1;
            }
        }
    }

    &:first-of-type{
        position: relative;
        @include pc{
            &::before{
                content: '';
                border-right: 1px solid #696969;
                position: absolute;
                height: 250px;
                width: 1px;
                left: 50%;
                top: -250px;
            }
        }
        @include sp{
            &::before{
                content: '';
                border-right: 1px solid #696969;
                position: absolute;
                height: 264/750*100vw;
                width: 1px;
                left: -1px;
                top: -264/750*100vw;
            }
        }
    }
}

.m-historyFilter__left{
    width: 50%;
    position: relative;
    text-align: left;
    
    @include sp{
        width: 100%;
        border-left: 1px solid #696969;
        padding-left: 50/750*100vw;
    }

    &--line{

        @include pc{
            &::before{
                content: '';
                border-top: 1px solid #696969;
                position: absolute;
                height: 1px;
                width: 100%;
                left: 0;
                top: 0;
            }

            &::after{
                content: '●';
                position: absolute;
                right: -9px;
                top: -11px;
                font-size: 16px;
                color: #CF0A2C;
                z-index: 1;
            }
        }
    }

    &--blank01{
        @include pc{
            padding-top: 236px;
        }
    }
}

.m-historyFilter__right{
    border-left: 1px solid #696969;
    position: relative;

    @include pc{
        width: 50%;
        padding-left: 83px;
    }

    @include sp{
        width: 100%;
        padding-left: 50/750*100vw;
    }

    &--line{
        @include pc{
            &::before{
                content: '';
                border-top: 1px solid #696969;
                position: absolute;
                height: 1px;
                width: 100%;
                left: 0;
                top: 0;
            }

            &::after{
                content: '●';
                position: absolute;
                left: -9px;
                top: -11px;
                font-size: 16px;
                color: #CF0A2C;
                z-index: 1;
            }
        }
    }

    &--blank01{
        @include pc{
            padding-top: 186px;
        }
    }

    &--blank02{
        @include pc{
            padding-top: 236px;
        }
    }

    &--pb{
        @include sp{
            padding-bottom: 130/750*100vw;
        }
    }

    .m-historyFilter__txtCont{
        @include pc{
            margin-left: auto;
        }
    }
}

.m-historyFilter__year{
    @extend .u-ft-gs;
    position: absolute;

    @include pc{
        top: -130px;
        font-size: 80px;
        white-space: nowrap;
    }
    
    @include sp{
        @include vw(120);
        position: relative;

        &::before{
            content: '';
            border-bottom: 1px solid #696969;
            position: absolute;
            height: 1px;
            width: 650/750*100vw;
            left: -50/750*100vw;
            bottom: 0;
        }

        &::after{
            content: '●';
            position: absolute;
            left: -65/750*100vw;
            bottom: -36/750*100vw;
            font-size: 48/750*100vw;
            color: #CF0A2C;
            z-index: 1;
        }
    }

    span{
        font-weight: bold;

        @include pc{
            font-size: 20px;
            padding-left: 14px;
        }

        @include sp{
            @include vw(30);
            padding-left: 16/750*100vw;
            display: inline-block;
            line-height: 1.33;
        }
    }

    &--en{
        font-size: 50px;
        top: -90px;

        @include sp{
            @include vw(80);
            top: 0;
        }
    }
}

.m-historyFilter__txtCont{
    @include pc{
        max-width: 412px;
    }

    @include sp{
        max-width: 588/750*100vw;
    }
}

.m-historyFilter__txt{
    line-height: 1.5;

    @include pc{
        padding-top: 16px;
        font-size: 20px;
    }

    @include sp{
        @include vw(26);
        padding-top: 32/750*100vw;
    }
}

.m-historyFilter__img{

    @include pc{
        width: 372px;
        margin-top: -186px;
        padding-bottom: 186px;
    }

    @include sp{
        width: 372/750*100vw;
        margin: 40/750*100vw auto 48/750*100vw 70/750*100vw;
        padding-bottom: 0;
    }

    &--left{
        @include pc{
            margin-right: auto;
        }
    }

    &--right{
        @include pc{
            margin-left: auto;
        }
    }
    
    
    img{
        width: 100%;
    }
}