@charset "utf-8";
.u{
    &-m0{
        margin: 0 !important;
    }
    &-mt00{
        margin-top: 0;
    }
    &-pci{
        display:inline-block;
        @include sp{
            display:none;
        }
    }
    &-spi{
        display:none;
        @include sp{
            display:inline-block;
        }
    }
    &-pc{
        display:block;
        @include sp{
            display:none;
        }
    }
    &-sp{
        display:none;
        @include sp{
            display:block;
        }
    }
    &-fs10{
        font-size: 10px;
        @include sp{
            @include vw(10);
        }
    }
    &-shadow{
        box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.16);
    }

    &-shadow02{
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    }


}
