@charset "utf-8";
.l-linkList{
    width: 300px;
    margin-top: 24px;

    @include sp{
        width: 100%;
        mask-type: 76/750*100vw;
    }

    &__flex{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 24px;

        @include sp{
            display: block;
        }
    }

    &__flex_af{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 24px;

        @include sp{
            flex-direction: column;
            align-items:flex-start;
        }
    }

    &__share{
        display: flex;
        align-items: center;

        span{
            display: inline-block;
            font-size: 14px;
            margin-right: 20px;
        }

        a{
            display: inline-block;
            &:first-of-type{
                margin-right: 20px;

                @include sp{
                    margin-right: 42.5/750*100vw;
                }
            }
        }
    }
}