@charset "utf-8";

.p-recipe{
    padding-bottom: 100px;

    @include sp{
        padding-bottom: 130/750*100vw;
    }

    &__ttl{
        padding: 120px 0 80px;
        width: 100%;

        @include sp{
            padding: 180/750*100vw 0 100/750*100vw;
        }
    }
}

.p-recipeOutline{
    margin-bottom: 80px;

    @include sp{
        margin-bottom: 80/750*100vw;
    }
    
    &__inner{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include sp{
            display: block;
        }
    }

    &__img{
        margin-right: 40px;

        @include sp{
            margin-right: 0;
            margin-bottom: 24/750*100vw;
        }
    }

    &__txtBox{
        max-width: 475px;
        width: 100%;

        @include sp{
            width: 100%;
        }
    }

    &__top{
        border-bottom: 1px solid #707070;
        padding-bottom: 16px;

        @include sp{
            padding-bottom: 24/750*100vw;
        }
    }

    &__head{
        font-size: 16px;
        line-height: 1.43;
        margin-bottom: 24px;

        @include sp{
            @include vw(24);
            margin-bottom: 24/750*100vw;
        }
    }

    &__ttl{
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 24px;

        @include sp{
            @include vw(40);
            margin-bottom: 24/750*100vw;
        }
    }

    &__time{
        font-size: 15px;
        font-weight: bold;

        @include sp{
            @include vw(26);
        }
    }

    &__bottom{
        padding-top: 16px;

        @include sp{
            padding-top: 24/750*100vw;
        }
    }

    &__txt{
        font-size: 15px;
        line-height: 2;
        margin-bottom: 24px;

        @include sp{
            @include vw(26);
            margin-bottom: 24/750*100vw;
            line-height: 1.5;
        }
    }
    &__cook{
        font-size: 15px;
        font-weight: bold;

        @include sp{
            @include vw(26);
        }
    }
}

.p-recipeStep{
    &__inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 40px;

        @include sp{
            padding-top: 40/750*100vw;
        }
    }

    &__item{
        max-width: 300px;
        margin-bottom: 40px;

        @include sp{
            max-width: 100%;
            margin-bottom: 40/750*100vw;
        }
    }

    &__head{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 16px;

        @include sp{
            @include vw(30);
            margin-bottom: 25/750*100vw;
        }
        
        .red{
            font-size: 16px;
            color: #CF0A2C;
            display: inline-block;
            margin-left: 19px;

            @include sp{
                @include vw(30);
                margin-left: 21/750*100vw;
            }
        }
    }

    &__img{
        margin-bottom: 16px;

        @include sp{
            margin-bottom: 24/750*100vw;
        }
    }

    &__txt{
        font-size: 15px;
        line-height: 2;

        @include sp{
            @include vw(26);
            line-height: 1.5;
        }
    }
}

.p-recipeProfile{
    margin-bottom: 20px;

    &__inner{
        display: flex;
        align-items: center;

        @include sp{
            display: block;
        }
    }

    &__img{
        // width: 372 /990*100%;
        width: 372px;

        @include sp{
            width: 100%;
        }
    }

    &__txtCont{
        background-color: $color_07;
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 618 /990*100%;
        width: 618 /990*100%;
        height: 229px;
        @extend .u-color-ffffff;

        @include sp{
            width: 100%;
            height: auto;
            padding: 40/750*100vw 0 80/750*100vw;
        }
    }

    &__txtInner{
        width: 515 /618*100%;

        @include sp{
            width: 588/750*100vw;
        }
    }

    &__cook{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;

        @include sp{
            @include vw(30);
        }
    }

    &__txt{
        font-size: 14px;
        line-height: 1.42;

        @include sp{
            @include vw(24);
            line-height: 1.5;
            letter-spacing: 0.05em;
        }
    }

}

.p-recipeProfileSign{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__txt{
        font-size: 14px;
        @extend .u-ft-gs;
        
        @include sp{
            @include vw(24);
        }
    }

    &__img{
        width: 70px;
        margin-left: 24px;

        @include sp{
            width: 153/750*100vw;
            margin-left: 25/750*100vw;
        }

        img{
            width: 100%;
        }
    }
}