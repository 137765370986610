@charset "utf-8";

.p-mailmag{
    padding: 80px 0 160px;
    @include sp{
        padding: 100/750*100vw 0 160/750*100vw;
    }

    &__box{
        margin-bottom: 10px;

        iframe{
            width: 990px;
            height: 325px;

            @include sp{
                width: 100%;
                height: 702/750*100vw;
            }
        }
    }

    &__inner{
        margin: 0 auto;
        max-width: 784px;
        padding: 65px 0 80px;
        width: 100%;

        @include sp{
            max-width: 100%;
            padding: 40/740*100vw 0 80/750*100vw;
            width: 588/750*100vw;
        }
    }

    &__caution{
        text-align: right;
        margin-bottom: 24px;
        @include sp{
            margin-bottom: 40/750*100vw;
        }
        span{
            color: #CF0A2C;
            font-size: 12px;
        }
    }

    &__txtCont{
        border-top: 1px solid #C7C7C7;
        border-bottom: 1px solid #C7C7C7;
        padding: 24px 0;
        @include sp{
            padding: 40/750*100vw 0;
        }
    }

    &__txt{
        font-size: 14px;
        @include sp{
            @include vw(24);
        }
    }
}