@charset "utf-8";

.m-card{
  &__imgWrap{
      position: relative;
      overflow: hidden;
      padding-top: 60.8%;

      @include sp{
        height: auto;
      }

  }
  &__img{
      position: absolute;
      top:50%;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: translateY(-50%);
  }
  &__body{
      padding: 0 10px;
      height: 114px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;


      @include sp{
        padding: 0 15/750*100vw;
        height: 224/750*100vw;
      }
  }
  &__txt{
      color: $color_05;
      font-size: 20px;
      font-weight: bold;

      @include sp{
        @include vw(36);
      }
  }

  &__txt2 {
    color: $color_05;
    font-size: 18px;
    font-weight: bold;

    @include sp{
      @include vw(36);
    }
}

}

.m-cardM{
  &__iframeWrap{
      position: relative;
      overflow: hidden;
      width:100%;
      padding-top: 56.25%;

        iframe{
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
        }
  }

  &__body{
      padding: 0 0px;
      display: flex;
      text-align: center;
      justify-content: flex-start;
      align-items: center;


      @include sp{
        padding: 0 15/750*100vw;
      }
  }
  &__txt{
      display: inline-block;
      text-align: left;
      color: $color_04;
      font-size: 14px;
      word-break: break-all;
      padding-top:10px;

    &--ff{
      font-family: $font-family-basic;
    }

      @include sp{
        @include vw(24);

      }
  }
}

.m-cardB{
  width: 308px;
  margin-bottom: 32px;

  @include sp{
    width: 332/750*100vw;
    margin-bottom: 24/750*100vw;
  }

  &__imgWrap{
      position: relative;
      overflow: hidden;
      padding-top: 61.7%;

      @include sp{
        height: auto;
      }

  }
  &__img{
      position: absolute;
      top:50%;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: translateY(-50%);
  }
  &__body{
      width: 308px;
      height: 270px;
      background-color: $color_04;
      padding: 7% 10% 9% 10%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      @include sp{
        padding: 24/750*100vw 15/750*100vw 40/750*100vw;
        height: 502/750*100vw;
        width: 332/750*100vw;
      }

      &--high{
        @include sp{
          height: 586/750*100vw;
        }
      }
  }
  &__date{
    font-size: 16px;
    color: $color_06;
    margin-bottom: 8px;
    line-height: 1.75;

    @include sp{
      @include vw(24);
      margin-bottom: 16/750*100vw;
      line-height: 1.16;
    }
  }
  &__txt{
      color: $color_05;
      font-size: 14px;
      line-height: 1.71;

      @include sp{
        @include vw(24);
      }

      a{
        text-decoration: underline;
        font-weight: bold;
        margin-top: 8px;
        display: inline-block;

        @include sp{
          margin-top: 28/750*100vw;
        }
      }
  }
}


.m-cardS{

  &__imgWrap{
      position: relative;
      // overflow: hidden;
      height: 134px;

      @include sp{
        height: 208/750*100vw;
      }
  }
  &__img{
      position: absolute;
      top:50%;
      width: 100%;
      transform: translateY(-50%);
  }
  &__body{
      padding: 16px 0;
      text-align: center;

      @include sp{
        padding: 40/750*100vw 0;
      }
  }
  &__txt{
      color: $color_05;
      font-size: 14px;
      white-space: normal;
      @include sp{
        white-space: normal;
        // text-align: left;
      }
  }
  &__txt2{
    color: $color_05;
    font-size: 14px;
    white-space: normal;
    @include sp{
      padding: 0 15/750*100vw;
      white-space: normal;
      // text-align: left;
    }
}
}

.smart-wrap{
  display: none;
  @include sp{
    display: inline;
  }
}

.space{
  @include sp{
    margin-right: 1em;
  }
 }
