@charset "utf-8";

.m-moreAbout{
    text-align: center;
    padding: 100px 0 160px;

    @include sp{
        padding: 100/750*100vw 0 160/750*100vw;
    }

    &__ttl{
        @extend .u-ft-gs;
        font-size: 40px;
        line-height: 1.425;
        margin-bottom: 40px;

        @include sp{
            @include vw(50);
        }

        span{
            font-size: 16px;
            font-weight: bold;
            display: block;
        }
    }

    &__inner{
        display: flex;
        justify-content: space-between;

        @include sp{
            display: block;
        }

        .m-zoomBoxB{
            max-width: 475px;
        }
    }
}