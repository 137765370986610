@charset "utf-8";

.m-iconBox{
  position: relative;
  width: 300px;
  height: 300px;
  padding: 56px 0 30px;
  text-align: center;
  margin-bottom: 40px;

  @include sp{
    margin-bottom: 40/750*100vw;
  }

  &:last-of-type{
    margin-bottom: 0;
  }

  @include pc{
    &:nth-last-of-type(2){
      margin-bottom: 0;
    }
  }

  &:hover{
    opacity: 1;
  }

  @include sp{
    width: 690/750*100vw;
    height: 690/750*100vw;
    margin-bottom: 40/750*100vw;
  }

  &--w476{
    max-width: 476px;
    width: 100%;
    height: 293px;
    padding: 50px 0 0;
    
    @include sp{
      height: 500/750*100vw;
      padding: 74/750*100vw 0 0;
      margin-bottom: 40/750*100vw;
    }
  }

  &--w990{
    max-width: 990px;
    width: 100%;
    height: 293px;
    padding: 50px 0 0;

    @include sp{
      height: 500/750*100vw;
      padding: 74/750*100vw 0 0;
    }
  }

  &--hAuto{
    max-width: 990px;
    width: 100%;
    height: auto;
    padding: 0;

    @include sp{
      height: 400/750*100vw;
      padding: 0;
    }
  }

  &__icon{
    margin: 0 auto 44px;
    width: 65px;
    height: 85px;

    @include sp{
      width: 214/750*100vw;
      height: 280/750*100vw;
      margin: 0 auto 60/750*100vw;
    }

    &--engineer{
      background: url(/assets/img/recruit/recruit_engineer.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--sales{
      background: url(/assets/img/recruit/recruit_sales.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--manager{
      background: url(/assets/img/recruit/recruit_manager.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__supportIcon{
    margin: 0 auto 28px;
    width: 85px;
    height: 85px;

    @include sp{
      margin: 0 auto 30/750*100vw;
      width: 110/750*100vw;
      height: 110/750*100vw;
    }

    &--faq{
      background: url(/assets/img/support/support_faq.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--parts{
      background: url(/assets/img/support/support_parts.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--inquiry{
      background: url(/assets/img/support/support_inquiry.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--repair{
      background: url(/assets/img/support/support_repair.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    // 生産終了製品
    &--download{
      background: url(/assets/img/support/support_discontinued-product.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--club{
      background: url(/assets/img/support/support_club.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--downloadG{
      background: url(/assets/img/support/support_download_g.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--search{
      background: url(/assets/img/support/support_search.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--repairCarton{
      background: url(/assets/img/support/support_carton.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

  }

  &__txtBox{
    // margin-bottom: 16px;

    @include sp{
      // margin-bottom: 40/750*100vw;
    }

    &--support{
      margin-bottom: 4px;

      @include sp{
        margin-bottom: 16/750*100vw;
      }
    }
  }

  &__ttl{
    font-size: 18px;
    font-weight: bold;

    @include sp{
      @include vw(30);
    }

  }

  &__subTtl{
    font-size: 14px;
    line-height: 1.71;
    height: 45px;

    @include sp{
      @include vw(26);
    }
  }

  &__more{
    transform: translateX(-50%);
  }
    
}


.m-iconBoxB{
    max-width: 476px;
    width: 100%;
    height: 293px;
    padding: 50px 0 0;
    margin-bottom: 40px;
    text-align: center;
    
    @include sp{
      height: 426/750*100vw;
      padding: 50/750*100vw 0 0;
      margin-bottom: 40/750*100vw;
    }
  
  &__icon{
    margin: 0 auto 28px;
    width: 70px;
    height: 85px;

    @include sp{
      width: 97/750*100vw;
      height: 118/750*100vw;
      margin: 0 auto 30/750*100vw;
    }

    &--downloadG{
      background: url(/assets/img/support/support_download_g.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--search{
      background: url(/assets/img/support/support_search.svg);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__txtBox{
    margin-bottom: 57px;

    @include sp{
      margin-bottom: 80/750*100vw;
    }
  }

  &__ttl{
    font-size: 18px;
    font-weight: bold;

    @include sp{
      @include vw(30);
    }

  }

  &__subTtl{
    font-size: 14px;
    line-height: 1.71;

    @include sp{
      @include vw(26);
    }
  }

  &__more{
    transform: translateX(-50%);
  }
    
}