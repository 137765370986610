@charset "utf-8";

.m-list{
    
    &__item{
        padding-left: 20px;
        text-indent: -10px;

        
        a{
            text-decoration: underline;
        }
    }

    &__num{
        display: inline-block;
        margin-right: 10px;
    }
}