@charset "utf-8";

.p-history{
    padding: 130px 0 50px;

    @include sp{
        padding: 160/750*100vw 0 60/750*100vw;
    }
    
    &.extract {
        padding-top: 60px;
    }

    &.about {
        padding-top: 60px;
        padding-bottom: 20/750*100vw;
    }

    &__ttl {
        text-align: center;
        font-size: 28px;
        line-height: 2;

        @include sp{
            width: 92vw;
            margin-left: auto;
            margin-right: auto;
            font-size: 17px;
            line-height: 1.5;
        }
    }

    &__ttl2 {
        font-size: 40px;
        text-align: center;
        font-size: 34px;
        line-height: 2;

        @include sp{
            width: 92vw;
            margin-left: auto;
            margin-right: auto;
            font-size: 28px;
            line-height: 1.5;
        }
    }


    &__box {
        width: 660px;
        margin: 50px auto 0;
        padding: 40px;
        border: 1px solid #000;
        font-size: 15px;
        line-height: 2.5;
        font-weight: bold;

        @include sp{
            width: 92vw;
            margin-top: 20px;
            padding: 3vh 2vh;
            font-size: 14px;
            line-height: 2;
        }
    }

    &__box2 {
        width: 790px;
        margin: 30px auto 0;
        padding: 40px;
        border: 1px solid #000;
        font-size: 15px;
        line-height: 2.5;

        @include sp{
            width: 92vw;
            margin-top: 10px;
            padding: 3vh 2vh;
            font-size: 14px;
            line-height: 2;
        }

        span.kome {
            display: block;
            font-size: 12px;
            line-height: 2;
            text-indent: -1em;
            margin-left: 1em;
        }
    }

    &__box3 {
        width: 660px;
        margin: 30px auto 0;
        padding: 40px;
        border: 1px solid #000;
        font-size: 15px;
        line-height: 2.5;

        @include sp{
            width: 92vw;
            margin-top: 10px;
            padding: 3vh 2vh;
            font-size: 14px;
            line-height: 2;
        }
    }

}



.p-historyMelitta {
    position: relative;
    width: 1050px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 100px;
    border-left: 1px solid #000;

    @include sp{
        width: 92vw;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &.first {
        margin-top: 200px;
        @include sp{
            margin-top: 20px;
        }
    }

    &.last {
        border-left: none;
        padding-bottom: 0;
    }

    &__ttl {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: start;

        &:before {
            content: "";
            display: inline-block;
            border-top: 1px dashed #000;
            width: 65px;
            height: 1px;
        }

        @include sp{

            &:before {
                width: 20px;
            }
        }
    }

    &__ttlicon {
        width: calc(100% - 36px);
        margin-top: -12px;
        padding-left: 40px;
        background-image: url(/assets/img/about/history/melitta_history_cup_icon.png);
        background-size: 36px;
        background-position: 0 0;
        background-repeat: no-repeat;
        font-size: 20px;

        @include sp{
            margin-top: -10px;
            font-size: 15px;
            padding-left: 30px;
            background-size: 25px;

        }

    }

    &__box1 {

        @include sp{
            display: flex;
            flex-direction: column-reverse;
        }

    }

    &__txtbox1 {
        position: relative;
        overflow: hidden;
        width: 600px;
        margin-left: 65px;
        padding: 60px;
        background-image: url(/assets/img/about/history/melitta_history_txtbg.png);
        background-repeat: repeat;

        @include sp{
            width: 90%;
            margin: 3vh auto 0;
            padding: 15vw 8vw;
            font-size: 9px;
        }

        p {
            font-size: 15px;
            line-height: 2.5;

            @include sp{
                font-size: 12px;
                line-height: 2;
            }
        }

    }

    &__box2 {
        width: 980px;
        display: flex;
        justify-content: space-between;
        margin: 0 0 0 auto;
        padding-bottom: 150px;

        &.last {
            padding-bottom: 0;
            margin: 30px 0 0 auto;
        }

        @include sp{
            width: 100%;
            margin: 0 auto;
            padding: 0;
            flex-direction: column;

            &.last {
                margin: 50px 0 0 auto;
            }
        }

    }

    &__box2--grid {
        width: 980px;
        margin: 0 0 0 auto;
        padding-bottom: 150px;
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: auto auto;
        grid-template-areas:
            "areaA areaC"
            "areaB areaD";
        
        @include sp{
            width: 100%;
            margin: 0 auto;
            padding: 0;
            grid-template-rows: auto auto auto auto;
            grid-template-columns: auto;
            grid-template-areas:
            "areaA"
            "areaB"
            "areaC"
            "areaD";
        }
    }

    &__grid--areaA { 
        grid-area: areaA;
        display: flex;
        align-items: center;
        justify-content: center;

        picture {
            width: 90%;

            @include sp{
                width: 80%;
                margin: 30px 0;
            }
        }
    }
    &__grid--areaB { grid-area: areaB;}
    &__grid--areaC { grid-area: areaC;}
    &__grid--areaD { grid-area: areaD;}



    &__phtbox1 {
        position: absolute;
        top: -150px;
        right: 50px;
        width: 358px;
        margin-left: auto;
        z-index: 777;

        @include sp{
            position: static;
            width: 70%;
            margin: 0 auto;
        }

        img {
            width: 100%;
        }
    }

    

    &__txtbox2 {
        position: relative;
        overflow: hidden;
        width: 600px;
        padding: 60px;
        background-image: url(/assets/img/about/history/melitta_history_txtbg.png);
        background-repeat: repeat;
        font-size: 15px;
        line-height: 2.5;

        @include sp{
            width: 90%;
            margin: 3vh auto 0;
            padding: 15vw 8vw;
        }

        p {
            font-size: 15px;
            line-height: 2.5;

            @include sp{
                font-size: 12px;
                line-height: 2;
            }
        }


    }

    &__phtbox2 {
        width: 358px;

        @include sp{
            width: 70%;
            margin: 0 auto;
        }

        &.border {
            border: 1px solid #EAEAEA;
        }

        img {
            width: 100%;
        }
    }

    // テキストボックス斜め額
    &__arch {
        &:before {
            position: absolute;
            top: -110px;
            left: -95px;
            content: "";
            width: 150px;
            height: 150px;
            background-color: #9A9182;
            transform: rotate(45deg);
        }

        &:after {
            position: absolute;
            right: -110px;
            bottom: -95px;
            content: "";
            width: 150px;
            height: 150px;
            background-color: #9A9182;
            transform: rotate(45deg);
        }
    }

    // 写真下キャプション　
    &__phtboxcap1 {
        margin-top: 1rem;
        text-align: right;
        color: #707070;
        font-size: 15px;
        line-height: 1.5;

        @include sp{
            text-align: center;
            font-size: 11px;
            line-height: 1.8;
        }
    }

    &__phtboxcap2 {
        margin-top: 1rem;
        text-align: left;
        color: #707070;
        font-size: 15px;
        line-height: 1.5;

        @include sp{
            text-align: center;
            font-size: 11px;
            line-height: 1.8;
        }
    }

    &__marker {
        font-weight: bold;
        background: linear-gradient(transparent 50%, #F5FD97 20%);
    }

    &__mt-1txt {
        margin-top: 1.5em;
    }

    &__mt-1txt-sp {
        @include sp{
            margin-top: 1.5em;
        }
    }

    &__marker--all {
        @include sp{
            width: 100%;
        }
    }

}


.p-historyFilter {
    width: 1050px;
    margin: 100px auto 0;

    @include sp{
        width: 92vw;
        margin-top: 50px;
    }

    &__LineBox {
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-top: 2px solid #000;
        background-image: url(/assets/img/about/history/filter_history_line.png);
        background-repeat: repeat-y;
        background-size: 2px;
        background-position: top center;

        &.reverse {
    
            @include sp{
                flex-direction: row-reverse;
            }
        }

        &.nonborder {
            border-top: none;
            padding: 50px 0 200px;
            @include sp{
                padding-bottom: 150px;
            }
        }

        &.nonborder2 {
            border-top: none;
            padding-bottom: 30px;
        }

        

        &.txt1974 {
            flex-direction: column;
            @include sp{
                flex-direction: row;
            }
        }

        &.last {
            background-image: none;
        }
    }


    &__LineText {
        width: 50%;
        padding: 50px 40px 150px 75px;

        @include sp{
            padding: 30px 13px 10vh 0;
        }

        &.wrp1936 {
            padding-bottom: 100px;
        }

    }

    &__LineText--reverse {
        width: 50%;
        padding: 50px 40px 150px 75px;

        @include sp{
            padding: 30px 0 10vh 13px;
            border-right: none;
        }

        &.wrp1910,
        &.wrp1932 {
            padding-bottom: 0;
        }

        &.last {
            padding-bottom: 0;
        }
    }

    &__LinePht {
        width: 50%;
        padding-bottom: 100px;


        &.wrp1936 {
            padding-bottom: 50px;
        }
    }

    &__LinePht--reverse {
        width: 50%;
        padding-bottom: 100px;

        &.wrp1910,
        &.wrp1932 {
            padding-bottom: 0;
        }

        &.last {
            padding-bottom: 0;
        }
    }

    &__LineText1974_1 {
        width: 50%;
        padding: 50px 40px 10px 75px;

        @include sp{
            padding: 30px 10px 10vh 0;
        }
    }

    &__LineText1974_2 {
        width: 50%;
        padding: 0 40px 150px 75px;

        @include sp{
            padding-left: 18px;
            padding-right: 0;
        }
    }

    &__point {
        position: absolute;
        width: 22px;
        height: 22px;
        background-color: #D0121B;
        border: 2px solid #000;
        border-radius: 100vh;
        top: -11px;
        left: calc(50% - 11px);
        z-index: 300;
    }

    &__year {
        position: absolute;
        top: -72px;
        left: 60px;

        @include sp{
            top: -43px;
            left: 0;
        }

        img {
            width: 205px;

            @include sp{
                width: 120px;
            }
        }
    }

    &__topic1 {
        padding-left: 18px;
        background-image: url(/assets/img/about/history/filter_history_arw.svg);
        background-size: 13px;
        background-repeat: no-repeat;
        background-position: 0 5px;
        font-weight: 700;
        font-size: 21px;

        @include sp{
            font-size: 14px;
        }

    }

    &__topic2 {
        position: relative;
        width: 975px;
        margin: 0 0 0 auto;
        font-size: 21px;
        font-weight: bold;
        border-top: 1px solid #EAEAEA;
        z-index: -999;
        
        @include sp{
            width: 90vw;
            font-size: 14px;
        }

        &.second {
            margin-top: 3rem;
        }

        p {
            position: absolute;
            top: -15px;

            @include sp{
                top: -10px;
            }
        }

        span {
            display: inline-block;
            background-color: #fff;
            padding-right: 5px;
            font-size: 21px;

            @include sp{
                display: inline-block;
                font-size: 14px;

            }
        }
    }

    &__topicbox {
        margin-top: 1.5rem;
        padding: 2.5rem 2rem;
        border: 1px solid #707070;
        font-size: 15px;
        line-height: 1.8;

        @include sp{
            padding: 1.2rem 1rem;
            font-size: 11px;
        }
    }

    &__productpht {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        img {
            width: 200px; 

            @include sp{
                width: 120px;
            }
        }

        &.mt-pht1908 {
            @include sp{
                margin-top: 80px;
            }
        }

        &.mt-pht1910 {
            @include sp{
                margin-top: 100px;
            }
        }

        &.mt-pht1932 {
            @include sp{
                margin-top: 100px;
            }
        }

        &.mt-pht1936 {
            @include sp{
                margin-top: 140px;
            }
        }

        &.mt-pht1954 {
            @include sp{
                margin-top: 100px;
            }
        }

        &.mt-pht1963 {
            @include sp{
                margin-top: 90px;
            }
        }
    }
}

.p-historyAbout {
    width: 660px;
    margin: 0 auto 130px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @include sp{
        width: 80vw;
        margin-top: 10px;
        flex-direction: column;
        gap: 50px;
    }

    &__box {
        width: 290px;

        @include sp{
            width: 100%;
        }
    }

    &__linkbox {
        display: block;
    }

    &__linkpht {
        width: 100%;
    }
    
    &__linktxt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        font-weight: bold;

        &:after {
            content: "";
            width: 25px;
            height: 8px;
            background-image: url(/assets/img/about/history/about_arw.svg);
            background-repeat: no-repeat;
            background-size: contain;

        }
    }

    &__txt {
        margin-top: 15px;
        font-size: 14px;
        line-height: 1.8;

    }

}