@charset "utf-8";

.m-infoSlide {

    .image{
        display: block;
        width: 100%;
        height: 100%;
    }

    /* slide-box */
    .slide-box {
        position: relative;
        margin: 0;
        padding: 0;
        width: 990px;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    /* slide */
    .infoSlide {
        position: relative;
        margin: 0;
        padding: 0;
        height: 358px;

        @media screen and (max-width: 767px) {
            height: 1000/750*100vw;
        }
    }

    .infoSlide .item {
        position: relative;
        height: 100%;
    }

    .infoSlide .item a {
        display: block;
    }

    .infoSlide .item::before {
        display: block;
        // padding-top: 39%;
        content: "";
    }

    .infoSlide .item .image {
        position: relative;
        // overflow: hidden;
    }

    .infoSlide .item img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: auto;
        width: auto;
        height: 100%;
        margin: 0 auto;
        transform: translate(-50%,-50%);

        @include sp{
            margin:0 0 auto 0;
        }
    }

    .infoSlide>.slick-list,
    .infoSlide>.slick-list>.slick-track,
    .infoSlide>.slick-list>.slick-track>.slick-slide>div {
        position: relative;
        height: 100%;
    }

    /* slide-navigation */
    .infoSlide-navigation {
        line-height: 0;
        position: relative;
        margin: 0;
        padding-top: 16px;
        width: 505px;

        @media screen and (max-width: 767px) {
            width: 600/750*100vw;
            padding-top: 19/750*100vw;
            position: absolute;
            top: 435/750*100vw;
        }
    }

    .infoSlide-navigation .item {
        position: relative;
        cursor: pointer;
    }

    .infoSlide-navigation .item .image {
        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 0;
        width: 87px;
        height: 55px;
        border: 2px solid $color_06;
        color: transparent;

        @media screen and (max-width: 767px) {
            width: 105/750*100vw;
            height: 66/750*100vw;
        }
    }

    // 選択中
    .infoSlide-navigation .slick-current .image{
        border: 2px solid $color_07;
    }

    .infoSlide-navigation .item .image::before {
        display: block;
        padding-top: 35%;
        content: "";
    }

    .infoSlide-navigation .item .image::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        opacity: 0.5;
    }

    .infoSlide-navigation .slick-current .image::after {
        opacity: 0;
    }

    .infoSlide-navigation .item .image {
        position: relative;
        overflow: hidden;
        background-color: $color_04;
    }

    .infoSlide-navigation .item .image img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: auto;
        width: auto;
        height: 55px;
        text-align: center;
        transform: translate(-50%,-50%);

        @media screen and (max-width: 767px) {
            height: 66/750*100vw;
        }
    }

    @media screen and (max-width: 767px) {

        .infoSlide-navigation .item .image::before {
        padding-top: 50%;
        }
    }

    /* slick */

    .slick-vertical .slick-slide {
        border: 0 solid transparent;
    }

    .slick-arrow {
        z-index: 998;
    }

    .slick-prev {
        top: 25px;
        bottom: 0;
        left: auto;
        right: -158px;
        margin: auto;
    }

    .slick-next {
        top: 25px;
        bottom: 0;
        left: auto;
        right: -60px;
        margin: auto;
    }

    .slick-initialized {

        .slick-next,
        .slick-prev {
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        background: 0 0;
        color: transparent;
        padding: 0;
        border: none;
        outline: 0;
        }

        // メイン画像内のアイコン
        .slick-prev {
            left: -35px;
            right: auto;

            @media screen and (max-width: 767px) {
                left: 40/750*100vw;
                bottom: auto;
                top: 220/750*100vw;
            }

            &::before {
                content: '';
                display: block;
                width: 35px;
                height: 35px;
                transform: rotate(45deg);
            }

            @media screen and (max-width: 767px) {

                &::before{
                    width: 40/690*100vw;
                    height: 40/690*100vw;
                }
            }
        
        }

        .slick-next {
            left: 576px;
            right: auto;

            @media screen and (max-width: 767px) {
                right: 55/750*100vw;
                left: auto;
                bottom: auto;
                top: 220/750*100vw;
            }

            &::before {
                content: '';
                display: block;
                width: 35px;
                height: 35px;
                transform: rotate(45deg);
            }

            @media screen and (max-width: 767px) {

                &::before{
                    width: 40/690*100vw;
                    height: 40/690*100vw;
                }
            }
        }
    }


    .infoSlide-navigation {

        // サムネイル内のアイコン
        .slick-prev {
            left: 0;
            top: 25px;
            bottom: 0;
            right: -550px;

        @media screen and (max-width: 767px) {
            left: auto;
            right: -60/750*100vw;
            top: 60/750*100vw;
        }

        &::before {
            width: 25px;
            height: 25px;
            transform: rotate(45deg);
            
            @media screen and (max-width: 767px) {
                width: 20/750*100vw;
                height: 20/750*100vw;
            }
            }
        }

        .slick-next {
            top: 25px;
            bottom: 0;
            left: 0;
            right: -600px;

            @media screen and (max-width: 767px) {
                left: auto;
                right: -90/750*100vw;
                top: 60/750*100vw;
            }

        &::before {
            width: 25px;
            height: 25px;
            transform: rotate(45deg);

            @media screen and (max-width: 767px) {
                width: 20/750*100vw;
                height: 20/750*100vw;
            }
            }
        }
    }

    .redImage {
        display: none;
    }
    }


.item .slick-slide .slick-current .slick-active {
    .image{
        border: solid 1px red;
    }
}

.m-infoSlide {
    .slick-slider .slick-track {
        margin-left: 0 !important;
    }
}

// 矢印の色

// アイコン黒
.m-infoSlide--bk{
    .slick-initialized {
    
        // メイン画像内のアイコン
        .slick-prev {
            &::before {
                border-bottom: solid 1px $color_07;
                border-left: solid 1px $color_07;
            }
        }
    
        .slick-next {
    
            &::before {
                border-top: solid 1px $color_07;
                border-right: solid 1px $color_07;
            }
        }
    }

    .slide-navigation {
        // サムネイル内のアイコン
        .slick-prev {
            &::before {
                border-bottom: solid 1px $color_07;
                border-left: solid 1px $color_07;
            }
        }
    
        .slick-next {
            &::before {
                border-top: solid 1px $color_07;
                border-right: solid 1px $color_07;
            }
        }
    }
}


// アイコン白
.m-infoSlide--wh{
    .slick-initialized {
    
        // メイン画像内のアイコン
        .slick-prev {
            &::before {
                border-bottom: solid 1px $color_04;
                border-left: solid 1px $color_04;
            }
        }
    
        .slick-next {
    
            &::before {
                border-top: solid 1px $color_04;
                border-right: solid 1px $color_04;
            }
        }
    }

    .slide-navigation {
        // サムネイル内のアイコン
        .slick-prev {
            &::before {
                border-bottom: solid 1px $color_04;
                border-left: solid 1px $color_04;
            }
        }
    
        .slick-next {
            &::before {
                border-top: solid 1px $color_04;
                border-right: solid 1px $color_04;
            }
        }
    }
}