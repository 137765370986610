@charset "utf-8";
// /**
//  * define valiables
//  * 各scssファイルでよく使う値を変数定義
//  */


//ブレイクポイント
$breakPoint: 750;


//サイト全体のフォントカラー
$color_txt: #000;

//リンクの設定
$color_lnk: #000;//リンクのカラー
$link_deco:none;//リンクのデコレーション設定

//フォーム系
$color_key: #15a9d8;
$color_placeholder: #e2e2e2;
$color_bg: #fff;
$color_contents: #eeeceb;
$color_consultant: #d29b00;
$color_researcher: #1b4b7d;

//オリジナル
$color_01: #262626;
$color_02: #d90000;
$color_03: #b3b3b3;
$color_04: #FFFFFF;
$color_05: #000000;
$color_06: #d9d9d9;
$color_07: #444444;
$color_08: #F6C631;
$color_09: #F20C19;
$color_10: #D2E4DD;
$color_11: #E76908;

//baseのfont-size
$base_fs:15;
$imgPath:"/assets/img/";
$font-family-basic:
    "メイリオ",                        // Japanese font for Windows8/7/Vista
    "Meiryo",
    "ヒラギノ角ゴ ProN W3",            // Japanese font for MAC OS X v10.5 or later
    "Hiragino Kaku Gothic ProN",
    "ヒラギノ角ゴ Pro W3",            // Japanese font for the previous MAC OS X v10.4
    "Hiragino Kaku Gothic Pro",
    "ヒラギノ丸ゴ ProN W4",            // Japanese font for MAC OS X v10.5 or later
    "Hiragino Maru Gothic ProN",
    "ヒラギノ丸ゴ Pro W4",            // Japanese font for the previous MAC OS X v10.4
    "Hiragino Maru Gothic Pro",
    "ＭＳ Ｐゴシック",                // Japanese font for the previous WindowsXP
    "MS PGothic",
    Sans-Serif                        // others
;

// 指定フォントの読み込み
@font-face{
    font-family:"Gill Sans Nova W05 SemiBold";
    src:url("/assets/fonts/5166076/18030c4e-991d-4cd3-a4e2-2490a4d28fd0.woff2") format("woff2"),url("/assets/fonts/5166076/268855c5-5ac1-44a2-a838-96f7b848aebe.woff") format("woff");
}
@font-face{
    font-family:"Gill Sans Nova W01 Light";
    src:url("/assets/fonts/1603254/ada1c864-6830-43ca-a5a2-51870437413e.woff2") format("woff2"),url("/assets/fonts/1603254/03d6828c-b347-428e-8c8f-5cab8ef67192.woff") format("woff");
}
@font-face{
    font-family:"Century Gothic W05 Bold";
    src:url("/assets/fonts/5219390/9582dc06-7a4d-4a55-8dfc-aa69cd8b8436.woff2") format("woff2"),url("/assets/fonts/5219390/656df9bb-f6e1-46a8-8a37-1c73ff3bbdf9.woff") format("woff");
}
@font-face{
    font-family:"Century Gothic W05 Regular";
    src:url("/assets/fonts/5321689/d722f0d1-f586-442f-83de-f80218687a92.woff2") format("woff2"),url("/assets/fonts/5321689/d5622205-b4e1-48e5-98a6-4ffe2ad76689.woff") format("woff");
}
// ダラコルテ
@font-face{
    font-family:"DIN Pro Medium";
    src:url("/assets/fonts/2F6BE0/2F6BE0_0_0.woff2") format("woff2"),url("/assets/fonts/2F6BE0/2F6BE0_0_0.woff2") format("woff");
}
@font-face{
    font-family:"dalla_cortecondensed_semibold";
    src:url("/assets/fonts/DallaCorte/DallaCorte-CondensedSemiBold-4.woff2") format("woff2"),url("/assets/fonts/DallaCorte/DallaCorte-CondensedSemiBold-4.woff") format("woff");
}
// ポアステディ
@font-face{
    font-family: 'Montserrat';
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
}
@font-face{
    font-family: 'Source Code Pro';
    @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap');
}
@font-face{
    font-family: 'Pourstaidy';
    src:url("/assets/fonts/Pourstaidy/Pourstaidy.woff2") format("woff2"),url("/assets/fonts/Pourstaidy/Pourstaidy.woff") format("woff");
}
