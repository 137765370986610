@charset "utf-8";

.m-txtBox{
  padding: 64px 0 80px;
  margin-bottom: 10px;

  @include sp{
    padding: 64/750*100vw 0 80/750*100vw;
    margin-bottom: 20/750*100vw;
  }

  &--center{
    text-align: center;
  }

  &__inner{
    width: 783px;
    margin: 0 auto;
    
    @include sp{
      width: 588/750*100vw;
    }

    &--sp600{
      @include sp{
        width: 600/750*100vw;
      }
    }
    &--mt15{
      margin-top:15px;
    }

    &--pt0{
      padding-top: 0px;
    }
    
    &--mb80{
      margin-bottom: 80px;
      @include sp{
        margin-bottom: 80/750*100vw;
      }
    }
  }

  &__sec{
    padding-bottom: 16px;
    border-bottom: 1px solid #C7C7C7;

    @include sp{
      padding-bottom: 40/750*100vw;
    }

    // 上下に余白
    &--p32{
      padding: 32px 0;
    }

    // 幅が狭い時用
    &--pb12{
      padding-bottom: 12px;

      @include sp{
        padding-bottom: 40/750*100vw;
      }
    }

    // 幅が狭い時用
    &--pb0{
      padding-bottom: 0;

      @include sp{
        padding-bottom: 0;
      }
    }

    // 幅が広い時用
    &--pb32{
      padding-bottom: 32px;

      @include sp{
        padding-bottom: 40/750*100vw;
      }
    }
  }

  &__box{
    padding: 24px 0 24px;

    @include sp{
      padding: 40/750*100vw 0 0;
    }
  }

  &__box2{
    padding: 0 0 24px;

    @include sp{
      padding: 0 0 40/750*100vw;
    }
  }

  &__txtCont{
    padding-top: 24px;

    @include sp{
      padding-top: 40/750*100vw;
    }
  }
  &__txtContTp{
    padding-bottom: 24px;
    a{
      text-decoration: underline
    }
    @include sp{
      padding-bottom: 40/750*100vw;
    }
  }


  // SPで左余白がなくなる時用
  &__txtContRight{
    max-width: 728px;
    width: 100%;
    margin-left: auto;
  }

  &__flex{
    display: flex;
    padding-top: 24px;
    align-items: flex-start;

    @include sp{
      padding-top: 40/750*100vw;
    }

    &--center{
      align-items: center;
    }

    &--top{
      align-items: top;
    }

    &--spBlock{
      @include sp{
        display: block;
      }
    }

    &--between{
      justify-content: space-between;
    }

     // 幅が狭い時用
    &--pt16{
      padding-top: 16px;
    }

    &--pt0{
      padding-top: 0px;
    }
  }

  &__left{
    width: 143px;

    &--w55{
      width: 55px;
      line-height: 1.3;

      @include sp{
        width: 96/750*100vw;
      }
    }

    &--w55sp50{
      width: 55px;
      line-height: 1.3;

      @include sp{
        width: 50/750*100vw;
      }
    }

    &--auto{
      width: auto;
    }

  }

  &__right{
    width: 634px;

    @include sp{
      width: 588/750*100vw;
    }

    &--w728{
      width: 728px;

      @include sp{
        width: 492/750*100vw;
      }
    }

    &--w94{
      width: 94px;

      @include sp{
        width: 88%;
      }
    }
    &--w300{
      width: 280px;
      text-align: right;
      margin-top: 10px;

      @include sp{
        width: 88%;
      }
    }
  }



  &__flexs{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top:20px;

    &__flex0{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width:350px; 
      margin-bottom:30px;
      
      @include sp{
        display: flex;
        width:100%;
      }
  
    }

      img{
        width:100%;
        height:206px;
        
        @include sp{
          width: 100%;
          height: auto;
        }
      }
    
    &__m-txt-fs14{
      font-size: 14px;
      line-height: 1.7;
      
      @include sp{
        font-size: 3.2vw;
        line-height: 1.5;
      }
    }

}
  

  a[href$=".pdf"]{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    text-decoration: underline;
  }

  a[href$=".pdf"]::before{ 
    content: '';
    // display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    // background-image: url(https://tsuredure.info/icon/icon-pdf.png);
    background-image: url(/assets/img/common/icon_pdf.png);

    background-size: contain;
  }

  a[href$=".xlsx"]{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    text-decoration: underline;
    // border: 1px solid #1396c0;
    // background-color: #e0f4ff;
    // border-radius: 3px;
    // text-decoration: none;
  }

  a[href$=".xlsx"]::before{ 
    content: '';
    // display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    background-image: url(/assets/img/common/icon_excel.png);
    background-size: contain;
  }

  // 修理依頼から完了までの主な流れリスト
  &__flow-list {
    line-height: 1.6;
    > li {
      margin-left: 1.3em;
      list-style-type: decimal;
      ul {
        li {
          margin-left: 1.3em;
          list-style-type: disc;
        }
      }
    }
  }

  // background gray
  &__bgGray {
    padding: 25px;
    margin-bottom: 50px;
    background-color: #f2f2f2;
    @include sp{
      padding: 40/750*100vw;
      margin-bottom: 90/750*100vw;
    }
  }

  
  &__bgGray__flow-list {
    line-height: 1.6;
    > li {
      margin-left: 1.3em;
      list-style-type: decimal;
      > ul {
        > li {
          margin-left: 1.3em;
          list-style-type: disc;
        }
      }
    }
  }

  &__txtTtl1 {
    font-weight: bold;
  }

  &__j_asterisk {
    margin-left: 1rem;
    text-indent: -1rem;
  }

  &__listDisc {
    margin-left: 1.3em;
    list-style-type: disc;
  }

  // アフターサポート 修理の流れSTEP
  &__flowStep {
    width: 100%;
    display: flex;
    align-items: stretch;

    &__left {
      display: flex;
      width: 15%;
      padding: 18px;
      align-items: center;
      background-color: #e3e3e3;
      @include sp{
        width: 30%;
        padding: 10/750*100vw;
      }

      &--c {
        width: 100%;
        text-align: center;
        font-weight: bold;
      }

      &--arw {
        padding: 10px;
        text-align: center;
        background: none;
        @include sp{
          padding: 10/750*100vw;
        }

        img {
          width: 20%;
          vertical-align: middle;
          @include sp{
            width: 30%;
          }
        }
      }
    }

    &__right {
      display: flex;
      width: 85%;
      padding: 18px;
      align-items: center;
      flex-direction: column;
      justify-content: start; 
      border: 1px solid #e3e3e3;

      p {
        width: 100%;
      }

      &--bold {
        font-weight: bold;
      }

      &--arw {
        padding: 10px;
        border: none;
        @include sp{
          padding: 10/750*100vw;
        }
      }
    }
  }

}
.m-txtBox-repair{
  padding: 64px 0 80px;
  margin-bottom: 10px;

  @include sp{
    padding: 64/750*100vw 0 80/750*100vw;
    margin-bottom: 20/750*100vw;
  }

  &__inner{
    width: 783px;
    margin: 0 auto;
    
    @include sp{
      width: 588/750*100vw;
    }
  }

  &__txtArea{
    padding: 15px 0 15px 15px;
    margin-bottom: 10px;
      
    @include sp{
      width: 588/750*100vw;
      padding: 30/750*100vw 0 0;
    }
  }

  &__sec{
    padding-bottom: 16px;
    border-bottom: 1px solid #C7C7C7;

    @include sp{
      padding-bottom: 40/750*100vw;
    }

    }
    &__txt{
      &--pl{
        display: flex;
        padding-left: 100px;
        @include sp{
          padding:0 65/750*100vw;
          text-align:left;
        }
          }
    }
  }


// 円デザイン
.circle{
  width:900px;
  display: flex;
  justify-content: space-around;
  padding:80px 60px 0px;
  margin:0 auto;

  @include sp{
    width:100%;
    flex-direction: column;
    padding:40/750*100vw 40/750*100vw 40/750*100vw 40/750*100vw;
  }

  &__inner{
    width:200px;
    display: flex;
    justify-content: center;
    margin-bottom:20px;
    flex-wrap:wrap;
    @include sp {
      width:100%;
      margin-bottom:40/750*100vw;
    }

    img{
      width:200px;
      height:200px;
      @include sp {
          width:100%;
          height:100%;
      }
      }
  }
  p{
    padding-top:10px;
    padding-left:1em;
    text-indent:-1em;
    @include sp {
      padding-top:20/750*100vw;
    }
  }
}


.accordion {
  width:100%;
  margin: 20px 0px;
  }
  .toggle {
  display: none;
  }
  .option {
  position: relative;
  margin-bottom: 1em;
  }
  .title,
  .content {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: all 0.3s;
  }
  .title {
  border: solid 1px #e3e3e3;
  background-color: #e3e3e3;
  padding: 1em;
  display: block;
  color: #333;
  font-size:16px;
  font-weight: bold;
  @include sp{
    @include vw(32);
  }
}
  .title::after,
  .title::before {
  content: "";
  position: absolute;
  right: 1.25em;
  top: 1.25em;
  width: 2px;
  height: 0.75em;
  background-color: #999;
  transition: all 0.3s;
  }
  .title::after {
  transform: rotate(90deg);
  }
  .content {
  width:100%;
  max-height: 0;
  overflow: hidden;
  padding-left: 45px;
  padding-right: 20px;
    @include sp{
      padding-left: 40/750*100vw;
      padding-right: 30/750*100vw;
    }
    &--last{
      padding-left: 45px;
      @include sp{
        padding-left: 40/750*100vw;
        padding-right: 20/750*100vw;
      }
    }
  }
  .content ol li {
    list-style-type:  decimal;
  }

  .toggle:checked + .title + .content {
  max-height: 700px;
  transition: all 1.5s;
  }
  .toggle:checked + .title::before {
  transform: rotate(90deg) !important;
  }

  .content p.indent{
    padding-left:1em;
    text-indent:-1em;
  }

  // 外部リンクアイコン
  .link_out {
    display: inline;
  
    &::before {
      content: "　";
      margin:3px;
      background: url("/assets/img/common/icon_link.svg") no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  // 外部リンクアイコン テキスト後ろ
  .link_out_after {
    display: inline;
  
    &::after {
      content: "　";
      margin:3px;
      background: url("/assets/img/common/icon_link.svg") no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
