@charset "utf-8";

.disabled{
    pointer-events: none;
}

// 幅270px
.m-btnA{
    display: block;
    width: 270px;
    padding: 18px 10px;
    text-align: center;
    line-height: 2.1;
    font-family:  "Noto Sans JP";
    font-weight: medium;
    font-size: 15px;

    @include sp{
        @include vw(26);
        width: 486/750*100vw;
        padding: 31/750*100vw 10/750*100vw;
    }

    &--bk{
        background-color: $color_07;
        color: $color_04;
    }

    &.discontinued{
        pointer-events: none;
        background-color: rgb(255,0,0);
    }
}

// 幅357px
.m-btnB{
    display: block;
    width: 357px;
    padding: 18px 10px;
    text-align: center;
    line-height: 2.1;
    font-family:  "Noto Sans JP";
    font-weight: medium;
    font-size: 15px;

    @include sp{
        @include vw(26);
        width: 486/750*100vw;
        padding: 31/750*100vw 10/750*100vw;
    }

    &--bk{
        background-color: $color_07;
        color: $color_04;
    }
    &--cm{
        font-family: $font-family-basic;
    }    
}

// 幅372px
.m-btnC{
    display: block;
    width: 372px;
    padding: 24px 24px;
    text-align: center;
    line-height: 1.8;
    font-family:  "Noto Sans JP";
    font-weight: medium;
    font-size: 15px;

    @include sp{
        @include vw(26);
        width: 486/750*100vw;
        padding: 31/750*100vw 10/750*100vw;
    }

    &--bk{
        background-color: $color_07;
        color: $color_04;
    }
}

.m-searchBtn{

    @include sp{
        @include vw(30);
        position: relative;
        top: 113/750*100vw;
        left: 30/750*100vw;
        width: 690/750*100vw;
        padding: 38/750*100vw 10/750*100vw 39/750*100vw;
        text-align: center;
        line-height: 1.4;
        background-color: $color_07;
        color: $color_04;
        cursor: pointer;

        &.fixed{
            position: fixed;
            top: 370/750*100vw;
            z-index: 2;
        }
    }

    &--relative{
        @include sp{
            position: relative;
            top: auto;
            left: auto;
            margin: 60/750*100vw auto 0;

            &.fixed{
                position: fixed;
                left: 30/750*100vw;
                top: 310/750*100vw;
                z-index: 2;
            }
        }
    }

}


// ボタンを囲うタグ
.m-btnCont{

    margin: 30px auto 0;

    @include sp{
        margin: 45/750*100vw auto 0;
    }

    &--center{
        text-align: center;

        a{
            margin: 0 auto;
        }
    }

    &--spCenter{
        text-align: left;

        @include sp{
            text-align: center;

            a{
                margin: 0 auto;
            }
        }
    }

    &--mt40{
        margin-top: 40px;

        @include sp{
            margin-top: 100/750*100vw;
        }
    }
}

.m-btnCont_rc{
    margin: 0px auto 40px;
    &--center{
        text-align: center;
        a{
            margin: 0 auto;
        }
    }
        @include sp{
            margin: 0vw auto 50/750*100vw;
    }
}