@charset "utf-8";

@media screen and (max-width:750px) {
    .p-filter_paper {
      padding: 21.33333vw 0 0
    }
  
    .u-spp {
      padding: 0 10px 10.66667vw;
    }
}

.p-filter_paper .m-iconTtl__txt {
    text-align: left;
  }
  @media screen and (max-width:750px) {
    .p-filter_paper .m-iconTtl__txt {
      text-align: center;
    }
  }
  
  .p-filter_paper--wrapper {
    width: 90%;
    margin: 0 auto 6.66667vw;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .p-filter_paper_container {
    width: 990px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  @media screen and (max-width:750px) {
    .p-filter_paper_container {
      width: 100%;
    }
  }
  .p-filter_paper_container ol {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 39px 60px;
  }
  @media screen and (max-width:750px) {
    .p-filter_paper_container ol {
      grid-template-columns: repeat(1, 1fr);
      gap: 39px 60px;
    }
  }
  .p-filter_paper_container ol li {
    display: flex;
    flex-direction: column;
  }
  .p-filter_paper_container ol li picture {
    display: block;
  }
  .p-filter_paper_container ol li picture img {
    width: 100%;
  }
  .p-filter_paper_container ol li h3 {
    margin-top: 24px;
    flex-grow: 1;
  }
  .p-filter_paper_container ol li p {
    margin-top: 21px;
  }
  
  .p-lineup-container .three_aloma_zone {
    margin-top: 40px;
    display: block;
  }