@charset "utf-8";

.p-support{
    padding: 100px 0 160px;

    @include sp{
        padding: 100/750*100vw 0 160/750*100vw;
    }

    &__boxCont{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__box{
        // width: 100%;
        // display: inline-block;
        // max-width: 476px;
        // width: 100%;
    }
}