@charset "utf-8";

.p-recruit{
    position: relative;
    // padding: 100px 0 160px;
    padding: 100px 0 50px;

    @include sp{
        padding: 100/750*100vw 0 50/750*100vw;
    }

    &__breadcrumb{
        position: absolute;
        top: 0;
        right: 0;
    }

    &__nopost {
        margin: 60px 0 0;
        text-align: center;
        font-weight: bold;
    }
}

.p-recruitBox{
    margin-bottom: 40px;
    text-align: center;

    @include sp{
        margin-bottom: 40/750*100vw;
    }

    &__inner{
        width: 600px;
        margin: 0 auto;
        padding: 72px 0;

        @include sp{
            width: 588/750*100vw;
            padding: 80/750*100vw 0;
        }
    }

    &__inner2{
        width: 600px;
        margin: 0 auto;
        padding: 72px 0;

        @include sp{
            width: 588/750*100vw;
            padding: 80/750*100vw 0;
        }
    }
}

.p-recruitFlex{
    display: flex;
    justify-content: space-between;

    @include sp{
        display: block;
    }
}

.p-recruitFlex_2{
    padding: 100px 0 30px;

    @include sp{
        // padding: 100/750*100vw 0 160/750*100vw;
        padding: 100/750*100vw 0 0;
    }

    &__boxCont{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 45px;
        align-items: stretch;
    }

    &__boxInterview {
        display: block;
        position: relative;
        width: 300px;
        padding: 30px 30px 70px;

        @include sp{
            width: 100%;
        }
    }

    &__interviewImg {
        img {
            display: block;
            width: auto;
            margin: 0 auto;

        }
    }

    &__interviewGaiyou {
        margin-top: 0.5em;

    }

    &__interviewBusyo {
        font-weight: bold;
    }

    &__interviewName {
        padding-bottom: 10px;
        font-weight: bold;
    }

    &__more {
        position: absolute;
        transform: translateX(30%);
        bottom: 20px;

        @include sp{
            transform: translateX(80%);
            bottom: 30px;
        }
    }
}