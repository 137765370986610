@charset "utf-8";

.p-enjoyment{
    padding: 80px 0 0;

    @include sp{
        padding: 100/750*100vw 0 0;
    }

    &__slide{
        padding-bottom: 200px;

        @include sp{
            padding-bottom: 220/750*100vw;
        }
    }
    
}

.p-enjoymentLeadBg{

    display: block;
    position: sticky;
    top: 0;
    z-index: -1;
    height: 0;
    max-height: 0;
    overflow: visible;

    &::before{
        background: url(/assets/img/enjoyment/lead_bg_enjoyment.jpg);
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: cover;
    }
}

.p-enjoymentLead{

    &__inner{
        text-align: center;
        padding: 160px 0 240px;

        @include sp{
            padding: 160/750*100vw 0;
        }
    }

    &__logo{
        margin-bottom: 64px;

        @include sp{
            width: 247/750*100vw;
            margin: 0 auto 80/750*100vw;
        }
    }

    &__txtBox{
    }

    &__txtSec{
        margin-bottom: 40px;

        @include sp{
            margin-bottom: 40/750*100vw;
        }

        &:last-of-type{
            margin-bottom: 0;
        }
    }
}

.fixed-background {
    // background-attachment: fixed;
}

.p-enjoymentLineLink{
    position: relative;
    cursor: pointer;
    transition:0.5s all;
    text-align: right;
    width: 90px;
    display: inline-block;
    font-size: 16px;
    margin-left: -40px;

    @include sp{
        @include vw(30);
        width: 160/750*100vw;
        margin-left: -70/750*100vw;
    }

    &::after{
        content: '';
        position: absolute;
        top: 50%;
        right: -60px;
        width: 100%;
        height: 1px;
        transform: scaleX(0.4);
        transform-origin: right top;
        background-color: #000;
        transition: all .3s ease;

        @include sp{
            right: -90/750*100vw;
        }
    }

    &.is-active{
        letter-spacing: 0.2em;
        transition:0.5s all;

        &::after{
            transform: scaleX(0.5);
            background-color: $color_02;
        }
    }
}

.p-enjoymentBoxArea{
    @extend .u-bg-f2f2f2;
    padding: 50px 0;

    @include sp{
        padding: 80/750*100vw 0;
    }

    &__sec{
        margin-bottom: 100px;

        @include sp{
            margin-bottom: 80/750*100vw;
        }

        &:last-of-type{
            margin-bottom: 0;
        }
    }
}

.p-enjoymentVideo{
    @extend .u-bg-ffffff;
    padding-bottom: 160px;
    
    @include sp{
        padding-bottom: 160/750*100vw;
    }

    &__sec{
        margin-bottom: 114px;

        @include sp{
            margin-bottom: 120/750*100vw;
        }

        &:last-of-type{
            margin-bottom: 0;
        }
    }

    &__flex{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include sp{
            display: block;
        }
    }

    &__ttl{
        margin-bottom: 40px;

        @include sp{
            margin-bottom: 24/750*100vw;
        }
    }
}

.p-enjoymentWpArchive {
    width: 940px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 0 100px;

    @include sp{
        width: 92vw;
        padding: 0;
    }

    &__topText {
        text-align: center;

        @include sp{
            text-align: left;
        }
    }

    &__cateLinkBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;

        @include sp{
            flex-wrap: wrap;
            gap: 3vw;
        }

        li {
            width: 128px;
            text-align: center;
            background-color: #E2E2E2;

            @include sp{
                width: 28vw;
            }

            a {
                display: block;
                padding: 0.6rem;
            }

            &.current {
                background-color: #444444;
                padding: 0.6rem;
                color: #fff;

            }
        }
    }
    
    &__articleBox {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 50px;
        margin-top: 50px;

        @include sp{
            gap: 20px;
        }
    }

    &__articleList {
        width: 280px;
        position: relative;
        background-color: #fff;

        @include sp{
            width: calc(50% - 10px);;
        }

        a {
            display: block;
            height: 100%;
        }

        &--eyecatch {

            img {
                width: 100%;
            }
        }

        &--detail {
            padding: 15px 15px 35px;

            @include sp{
                padding: 2vw 2vw 30px;
            }
            
        }

        &--cate {
            display: inline-block;
            background-color: #F2F2F2;
            text-align: center;
            padding: .4em 2em .3em;
            border-radius: 100vh;
        
        }

        &--engTitle {
            margin-top: 15px;
            line-height: 1.5;

            @include sp{
                margin-top: 10px;
            }
        }

        &--jpTitle {
            margin-top: 10px;
            font-weight: bold;
            line-height: 1.5;
        }

        &--outlineTxt {
            margin-top: 15px;

            @include sp{
                margin-top: 10px;
                line-height: 1.4em;
            }
            
        }

        &--arw {
            position: absolute;
            bottom: 15px;
            right: 15px;

            img {
                width: 24px;

                @include sp{
                    width: 15px;
                }
            }
        }
    }

    &__noArticle {
        margin-top: 50px;

        @include sp{
            margin-top: 10vw;
        }
    }

    .pagenation {
        margin: 80px 0 0;

        @include sp{
            margin: 50px 0 0;
        }

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;

            li {
                display: inline-block;
                margin: 0 8px;
                background-color: #fff;
                font-weight: bold;


                a {
                    display: block;
                    padding: .5em 1em;

                    @include sp{
                        padding: .1em .8em;
                    }
                }
                &.current {
                    padding: .5em 1em;
                    background-color: #444;
                    color: #fff;

                    @include sp{
                        padding: .1em .8em;
                    }
                }
            }
        }
    }
}

.p-enjoymentWpArticleNav {
    width: 940px;
    margin-left: auto;
    margin-right: auto;
    padding: 100px 0;

    @include sp{
        width: 92vw;
        padding: 10vw 0;
    }

    &__title {
        font-size: 28px;
        font-weight: bold;

        @include sp{
            @include vw(30);
        }
    }

    &__articleBox {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 50px;
        margin-top: 50px;

        @include sp{
            gap: 20px;
            margin-top: 5vw;
        }
    }

    &__articleList {
        width: 280px;
        position: relative;
        background-color: #fff;

        @include sp{
            width: calc(50% - 10px);;
        }

        a {
            display: block;
            height: 100%;
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
        }

        &--eyecatch {

            img {
                width: 100%;
            }
        }

        &--detail {
            padding: 15px 15px 35px;

            @include sp{
                padding: 2vw 2vw 30px;
            }
            
        }

        &--cate {
            display: inline-block;
            background-color: #F2F2F2;
            text-align: center;
            padding: .4em 2em .3em;
            border-radius: 100vh;
        
        }

        &--engTitle {
            margin-top: 15px;
            line-height: 1.5;

            @include sp{
                margin-top: 10px;
            }
        }

        &--jpTitle {
            margin-top: 10px;
            font-weight: bold;
            line-height: 1.5;
        }

        &--outlineTxt {
            margin-top: 15px;

            @include sp{
                margin-top: 10px;
                line-height: 1.4em;
            }
            
        }

        &--arw {
            position: absolute;
            bottom: 15px;
            right: 15px;

            img {
                width: 24px;

                @include sp{
                    width: 15px;
                }
            }
        }
    }

    &__goColumnTop {
        margin-top: 50px;
        text-align: right;

        @include sp{
            margin-top: 5vw;
        }

        a {
            text-decoration: underline;
        }
    }

    
}

.p-enjoymentWpArticleWrp  {
    width: 940px;
    margin-left: auto;
    margin-right: auto;
    padding: 80px 0;

    @include sp{
        width: 92vw;
        padding: 20vw 0 5vw;
    }

    img {

        @include sp{
            // display: block;
            // margin: 5px 0;
            height: auto;
        }

        &.alignleft {
            display: block;
            margin-left: 0;
            margin-right: auto;
        }

        &.aligncenter {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        &.alignright {
            display: block;
            margin-left: auto;
            margin-right: 0;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }

    h1, h2 {
        font-size: 30px;
        @include sp{
            @include vw(45);
        }
    }

    h3 {
        font-size: 26px;
        @include sp{
            @include vw(40);
        }
    }

    h4 {
        font-size: 22px;
        @include sp{
            @include vw(35);
        }
    }

    h5 {
        font-size: 18px;
        @include sp{
            @include vw(30);
        }
    }

    h6 {
        font-size: 15px;
    }

    p {
        margin: 1em 0;
    }

    ul {
        margin-left: 1.5em;

        li {
            list-style-type: disc;
        }
    }

    ol {
        margin-left: 1.5em;

        li {
            list-style-type: decimal;
        }
    }

    em {
        font-style: italic;
    }


    // 引用タグ デフォルト効果を無効にする
    // blockquote {
    //     background-color: #fff;
    //     padding: 0;
    //     border: none;
    //     &::before {
    //         content: "";
    //     }
    // }

    .wp-caption-text {
        font-size: 0.8em;
        margin-top: 3px;
    }

    // キャプション付きの画像SP版
    .wp-caption {
        max-width:100%;
    }

}

// 記事内目次
.p-enjoymentIndex {
    width: fit-content;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #000;

    &__title {
        padding: 0 0 15px;
        text-align: center;
        border-bottom: 1px solid #000;
        font-size: 22px;
        font-weight: bold;

        @include sp{
            @include vw(35);
        }
    }

    > ul{
        margin-top: 20px;
        margin-left: 0;
        
        
        > li {
            list-style-type: none;
            

            > ul {
                margin-left: 1.5em;
                padding: 0.8em 0;

                @include sp{
                    margin-left: 1em;
                }

                li {
                    list-style-type: none;

                    > ul {
                        margin-left: 1.5em;
                        padding: 0.8em 0;

                        @include sp{
                            margin-left: 1em;
                        }
    
                        li {
                            list-style-type: none;
                        }
                    }
                }
            }
        }
    }

    a {
        border-bottom: 1px solid #000;
    }
}

// ページ内リンク 位置調整（Gナビに隠れてしまうのを解消）

.p-enjoymentAnchorWrp {
    width: 1px;
}

.p-enjoymentAnchor {
    position: relative;
    display: block;
    margin-top: -50px;
    padding-top: 50px;
    width: 1px;
}

