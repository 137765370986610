@charset "utf-8";

.m-stepCont{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.m-step{
    max-width: 470px;
    @include sp{
        width: 333/750*100vw;
    }

    &__inner{

    }

    &__img{
        // margin-bottom: 16px;
    }

    &__ttl{
        @include sp{
            margin-bottom: 16/750*100vw;
        }
    }

}


