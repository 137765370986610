@charset "utf-8";

.p-coffeemaker{
    &__ttl{
        padding: 120px 0 97px;

        @include sp{
            padding: 180/750*100vw 0 80/750*100vw;
        }
    }
}

.p-coffeemakerPoint{
    padding: 80px 0 0px;

    @include sp{
        padding: 50/750*100vw 0vw 0vw;
    }

    &__block{
        display: flex;
        flex-wrap:wrap;
        justify-content: space-between;

        &--etc{
            flex-direction: column;
        }

        &--aroma{
            padding: 50px 50px;

            @include sp{
                padding: 50/750*100vw 50/750*100vw
            }
        }
    }


    &__selectType{
        display: flex;
        flex-wrap:wrap;
        width:100%;
        @include sp{
            flex-direction: column;
            width:100%;
        }
    }

    &__sec{
        padding-bottom: 0px;

        &:last-of-type{
            padding-bottom: 0;
        }
    }

    &__txtCont{
        max-width: 100%;
        &--578{
            max-width: 690px;
            @include sp{
                max-width: 100%;
            }
        }
        ul li {
            margin-left: 1rem;
            text-indent: -1rem;
          }
        ul li::before {
        display: inline;
        content: "・";
        }
        a{
            text-decoration: underline;
        }
    }
}

.p-coffeemakerNumBox{
    max-width: 240px;
    width: 100%;
    height: 240px;
    color: $color_04;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-top: 12px;

    @include sp{
        max-width: 333/750*100vw;
        height: 100%;
        margin-bottom: 24/750*100vw;
        padding-bottom: 48/750*100vw;
        align-items: stretch;

        &:last-of-type{
            margin-bottom: 0;
        }

        &:nth-last-of-type(2){
            margin-bottom: 0;
        }
    }

    &:nth-of-type(3){
        @include sp{
            padding-bottom: 88/750*100vw;
        }
    }

    &__num{
        font-size: 40px;

        @include sp{
            @include vw(55);
        }
    }

    &__ttl{
        font-size: 15px;
        margin-bottom: 16px;
        line-height: 1.875;

        @include sp{
            @include vw(26);
            margin-bottom: 16/750*100vw;
        }

        span{
            font-size: 16px;
            display: block;

            @include sp{
                @include vw(26);
            }
        }
    }

    &__txt{
        font-size: 14px;
        line-height: 1.428;

        @include sp{
            @include vw(24);
            width: 240/750*100vw;
            margin: 0 auto;
            line-height: 1.66;
        }

        &.w194{
            width: 194px;

            @include sp{
                width: 279/750*100vw;
            }
        }
    }
}

.m-img{
    width:300px;
    img{
        max-width:95%;
    }
    @include sp{
        width:100%;
        img{
            max-width:100%;
        }
    
    }
}

