@charset "utf-8";

.m-dialBox{
  width: 100%;
  padding: 32px 0;
  margin: 40px 0;
  text-align: center;

  &--after{
    padding: 40px 0;
    @include sp{
      padding: 80/750*100vw 0;
    }
  }

  &__ttlCont{
    margin-bottom: 30px;
    @include sp{
      margin-bottom: 40/750*100vw;
    }
    &--mb10{
      margin-bottom: 10px;
    }
  }

  &__ttl{
    margin-bottom: 8px;
    @include sp{
      margin-bottom: 8/750*100vw;
    }
  }

  &__flex{
    display: flex;
    max-width: 570px;
    width: 100%;
    // justify-content: space-between;
    justify-content: space-around;
    margin: 0 auto;
    @include sp{
      display: block;
      max-width: 457/750*100vw;
    }
  }

  &__item{
    // text-align: left;
    text-align: center;
  }
  &__item_sec{
    width:90%;
    text-align: center;
    display: -webkit-inline-box;
  }


  &__num{
    margin: 0 auto 10px;

    @include sp{
      margin: 0 auto 40/750*100vw;
      width: 486/750*100vw;
    }
  }

  &__head{
    font-size: 18px;
    // font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
    @include sp{
      @include vw(24);
    }
    span{
      font-weight: 300;
    }
  }

  &__head_sec{
    width: 100%;
    padding: 10px;
    margin-top:2px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px;
    color:#fff;
    @include sp{
      @include vw(23);
    }
    span{
      font-weight: 300;
    }
  }

  &__privacy_policy{
    width:70%;
    margin:0 auto;
    padding:10px 0;

    @include sp{
      width:100%;
      padding: 40/750*100vw 0;
      margin:0 auto;
    }
  }

  a.dial{
    font-weight: bold;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
    padding-left: 52px;

    @include sp{
      @include vw(51);
      padding-left: 90/750*100vw;
    }
    

    &::after{
      position: absolute;
      content: '';
      background: url(/assets/img/common/dial_icon.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 46px;
      height: 27px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include sp{
        width: 80/750*100vw;
        height: 46/750*100vw;
      }
    }

    @include sp{
      @include vw(50);
    }

    @include pc{
      pointer-events: none;
    }
  }

  #dial{
    // font-weight: bold;
    font-size: 20px;
    color:#fff;
    line-height: 1.5;
    position: relative;
    padding-left: 35px;

    @include sp{
      @include vw(26);
      padding-left: 80/750*100vw;
    }
    
    &::after{
      position: absolute;
      content: '';
      background: url(/assets/img/common/dial_icon_w.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 30px;
      height: 18px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include sp{
        width: 66/750*100vw;
        height: 40/750*100vw;
      }
    }

    @include sp{
      @include vw(40);
    }

    @include pc{
      pointer-events: none;
    }
  }

  &__box2 {
    margin-top: 15px;
  }

}

// EK43/EK43S Allround Grinder
.m-dialBox-EK{
  width: 100%;
  padding: 32px 0;
  margin: 20px 0 40px;
  text-align: center;

  &--after{
    padding: 40px 0;
    @include sp{
      margin-bottom: 50/750*100vw;
      padding: 80/750*100vw 0 40/750*100vw;
    }
  }

  &__ttlCont{
    margin-bottom: 30px;
    @include sp{
      // margin-bottom: 40/750*100vw;
    }
    &--mb10{
      margin-bottom: 10px;
    }
  }

  &__ttl{
    margin-bottom: 8px;
    @include sp{
      margin-bottom: 8/750*100vw;
    }
  }

  &__flex{
    display: flex;
    max-width: 570px;
    width: 100%;
    // justify-content: space-between;
    justify-content: space-around;
    margin: 0 auto;
    @include sp{
      display: block;
      max-width: 457/750*100vw;
    }
  }

  &__item{
    // text-align: left;
    text-align: center;
  }
  &__item_sec{
    width:90%;
    text-align: center;
    display: -webkit-inline-box;
  }


  &__num{
    margin: 0 auto 10px;

    @include sp{
      margin: 0 auto 40/750*100vw;
      width: 486/750*100vw;
    }
  }
  &__head{
    font-size: 18px;
    // font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
    @include sp{
      @include vw(24);
    }
    span{
      font-weight: 300;
    }
  }

  &__head_sec{
    width: 100%;
    padding: 10px;
    margin-top:2px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px;
    color:#fff;
    @include sp{
      @include vw(23);
    }
    span{
      font-weight: 300;
    }
  }

  &__privacy_policy{
    width:70%;
    margin:0 auto;
    padding:10px 0;

    @include sp{
      width:100%;
      padding: 40/750*100vw 0;
      margin:0 auto;
    }
  }

  a.dial{
    font-weight: bold;
    font-size: 30px;
    line-height: 1.5;
    position: relative;
    padding-left: 52px;

    @include sp{
      @include vw(51);
      padding-left: 90/750*100vw;
    }
    

    &::after{
      position: absolute;
      content: '';
      background: url(/assets/img/common/dial_icon.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 46px;
      height: 27px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include sp{
        width: 80/750*100vw;
        height: 46/750*100vw;
      }
    }

    @include sp{
      @include vw(50);
    }

    @include pc{
      pointer-events: none;
    }
  }

  #dial{
    // font-weight: bold;
    font-size: 20px;
    color:#fff;
    line-height: 1.5;
    position: relative;
    padding-left: 35px;

    @include sp{
      @include vw(26);
      padding-left: 80/750*100vw;
    }
    
    &::after{
      position: absolute;
      content: '';
      background: url(/assets/img/common/dial_icon_w.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 30px;
      height: 18px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include sp{
        width: 66/750*100vw;
        height: 40/750*100vw;
      }
    }

    @include sp{
      @include vw(40);
    }

    @include pc{
      pointer-events: none;
    }
  }

}
