@charset "utf-8";

.m-recipe{
    &__cont{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #c7c7c7;

        @include sp{
            display: block;
            padding: 24/750*100vw 0;
        }
    }

    &__material{
        width: 160px;

        @include sp{
            width: 100%;
            margin-bottom: 16/750*100vw;
        }
    }

    &__quantity{
    }

    &__txtCont{
        margin-top: 24px;

        @include sp{
            margin-top: 16/750*100vw;
        }
    }
}

.m-recipeB{
    &__cont{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #c7c7c7;
        padding: 16px 0;

        @include sp{
            padding: 16/750*100vw 0;
        }

        &:first-of-type{
            padding: 24px 0 16px;

            @include sp{
                padding: 40/750*100vw 0 16/750*100vw;
            }
        }
    }

    &__flex{
        display: flex;
        justify-content: space-between;

        @include sp{
            display: block;
        }
    }

    &__wrap{
        max-width: 578px;
        width: 100%;
    }

    &__img{
        padding-top: 24px;
        margin-left: 40px;
        max-width: 372px;

        @include sp{
            padding-top: 24/750*100vw;
            margin-left: 0;
            max-width: 100%;
        }
    }

    &__material{
        width: 170px;

        @include sp{
            width: 295/750*100vw;
        }

        &.wide{
            width: 309px;

            @include sp{
                width: 538/750*100vw;
            }
        }
    }

    &__quantity{

    }

    &__txtCont{
        margin-top: 24px;

        @include sp{
            margin-top: 16/750*100vw;
        }
    }
}