@charset "utf-8";

.m-hint{
    &__inner{
        padding: 68px 64px 64px;

        @include sp{
            padding: 60/750*100vw 51/750*100vw;
        }
    }

    &__ttlInner{
    }

    &__ttlLeft{
        color: #CF0A2C;
        margin-right: 24px;

        @include sp{
            margin-right: 27/750*100vw;
        }
    }

    &__left{
        max-width: 308px;

        @include sp{
            margin-bottom: 40/750*100vw;
        }
    }

    &__right{
    }

    &__rightInner{
        max-width: 515px;
    }

    &__item{
        width: 153px;
        text-align: center;
        margin-right: 28px;

        @include sp{
            width: 173/750*100vw;
            margin-right: 31/750*100vw;
        }
        
        &:last-of-type{
            margin-right: 0;
        }
    }

    &__img{
        margin-bottom: 10px;

        @include sp{
            margin-bottom: 16/750*100vw;
        }
    }
}