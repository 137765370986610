@charset "utf-8";

.m-zoomSlide{

    &__inner{
        max-width: 100%;
        margin: 0 auto;

        @include sp{
            max-width: 720/750*100vw;
        }
    }

    &__item{
        max-width: 492/1366*100vw;
        display: block;
        overflow: hidden;

        @include sp{
            max-width: 537/750*100vw;
        }

        a{
            display: block;
            
            &:hover{
                opacity: 1;

                img{
                    transform:scale(1.15);
                    transition:.6s all ease-out;
                }
            }
        }

        &::after{
            content: '';
            border-bottom: solid 2px $color_04;
            border-right: solid 2px $color_04;
            width: 12px;
            height: 12px;
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translateX(-50%) rotate(45deg);
            transition-duration: .3s;

            @include sp{
                width: 15/750*100vw;
                height: 15/750*100vw;
            }
        }

        &.is-active{
            &::after{
                bottom: 10px;
            }
        }
    }

    &__img{

        img{
            width: 100%;
            transition:.6s all ease-out;

            &.is-active{
                transform:scale(1.15);
                transition:.6s all ease-out;
            }
        }
    }

    &__ttlBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        width: 100%;
    }

    &__ttl{
        white-space: nowrap;
    }

    &__memo{
        display: block;
        color: $color_04;
    }
}
