@charset "utf-8";

@media screen and (max-width: 750px) {
    .p-filtration_system {
        padding: 21.33333vw 0 0;
    }
}

.p-filtration_system .m-iconTtl__txt {
    text-align: left;
  }
  @media screen and (max-width:750px) {
    .p-filtration_system .m-iconTtl__txt {
      text-align: center;
    }
  }
  
  .p-filtration_system--wrapper {
    width: 90%;
    margin: 0 auto 6.66667vw;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .p-filtration_system_container {
    width: 990px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  @media screen and (max-width:750px) {
    .p-filtration_system_container {
      width: 100%;
    }
  }
  .p-filtration_system_container ol {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 39px 60px;
  }
  @media screen and (max-width:750px) {
    .p-filtration_system_container ol {
      grid-template-columns: repeat(1, 1fr);
      gap: 39px 60px;
    }
  }
  .p-filtration_system_container ol li picture {
    display: block;
  }
  @media screen and (max-width:750px) {
    .p-filtration_system_container ol li picture {
      padding: 0 20px;
    }
  }
  .p-filtration_system_container ol li picture img {
    width: 100%;
  }
  .p-filtration_system_container ol li h3 {
    margin-top: 24px;
  }
  @media screen and (max-width:750px) {
    .p-filtration_system_container ol li h3 {
      text-align: center;
    }
  }
  .p-filtration_system_container ol li p {
    margin-top: 21px;
  }
  
  .p-filtration_system_1x2 {
    border: 1px solid #000;
    border-radius: 10px;
    padding: 8px 47px;
    max-width: 800px;
    margin: 41px auto 0;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width:750px) {
    .p-filtration_system_1x2 {
      padding: 5px 8px;
    }
  }
  .p-filtration_system_1x2--title {
    display: block;
    padding: 10px 18px 10px 0;
    border-right: 1px solid #000;
    margin-right: 19px;
  }
  @media screen and (max-width:750px) {
    .p-filtration_system_1x2--title {
      padding: 10px 9px 10px 0;
      margin-right: 9px;
    }
    .p-filtration_system_1x2--title img {
      width: 80px;
    }
  
    .p-filtration_system_1x2 .m-txt-fs18 {
      font-size: 13px;
    }
  }
  
  .p-lineup-container {
    padding-top: 0;
  
  }
  @media screen and (max-width:750px) {
    .p-lineup-container {
      padding-top: 19px;
      width: 90%;
      margin: 0 auto;
    }
  }
  .p-lineup {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px 22px;
    margin-top: 29px;
  }
  @media screen and (max-width:750px) {
    .p-lineup {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
  }
  .p-lineup .m-prodItem {
    width: 100%;
    margin: 0;
  }
  .p-lineup .m-prodItem__logo--ml::before {
    width: 72px;
    height: inherit;
    aspect-ratio: 58 / 38;
  }
  @media screen and (max-width:750px) {
    .p-lineup .m-prodItem__logo--ml::before {
      width: 44px;
    }
  }
  .p-lineup .m-prodItem__imgWrap {
    height: 224px;
  }
  @media screen and (max-width:750px) {
    .p-lineup .m-prodItem__imgWrap {
      height: 116px;
    }
  }
  .p-lineup .m-prodItem__body {
    text-align: left;
  }