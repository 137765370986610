@charset "utf-8";

.u-ft-gs {
    font-family: "Gill Sans Nova W05 SemiBold" !important;
    // font-weight: 100 !important;
}

.u-ft-gsl {
    font-family: "Gill Sans Nova W01 Light" !important;
}

.u-ft-ctgb {
    font-family: "Century Gothic W05 Bold" !important;
}

.u-ft-ctgr {
    font-family: "Century Gothic W05 Regular" !important;
}

.u-ft-dinP {
    font-family: "DIN Pro Medium" !important;
}

.u-ft-dc {
    font-family: "dalla_cortecondensed_semibold" !important;
}

.u-ft-mont {
    font-family: "Montserrat" !important;
}

.u-ft-scP {
    font-family: "Source Code Pro" !important;
}


.u-ft-scPb {
    font-family: "Source Code Pro" !important;
    font-weight: 700;
}

.u-ft-scPsb {
    font-family: "Source Code Pro" !important;
    font-weight: 600;
}

.u-ft-ps {
    font-family: "Pourstaidy" !important;
}

.u-ft-noto {
    font-family: "Noto Sans JP" !important;
}

.u-ft-os{
    font-family: "Open Sans" !important;
}


