@charset "utf-8";

// 家庭用製品取扱説明書ダウンロード
.m-itemA{
    width: 22.6%;
    position: relative;
    margin-bottom: 3%;
    display: block;

    @include sp{
        width: 48%;
        margin-bottom: 24/750*100vw;
    }

    &:after{
        content: '';
        position: absolute;
        background: url(/assets/img/common/red_arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        left: 50%;
        bottom: 16px;
        width: 17px;
        height: 17px;
        transform: translateX(-50%);

        @include sp{
            width: 36/750*100vw;
            height: 36/750*100vw;
            bottom: 25/750*100vw;
        }
    }

    &__imgWrap{
        position: relative;
        overflow: hidden;
        padding-top: 60.8%;

        @include sp{
            height: auto;
        }

    }
    &__img{
        position: absolute;
        top:50%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: translateY(-50%);
    }
    &__body{
        height: 126px;
        text-align: center;

        @include sp{
            height: 201/750*100vw;
        }
    }
    &__txt{
        color: $color_05;
        font-size: 14px;
        padding-top: 18px;

        @include sp{
            @include vw(24);
            padding-top: 10/750*100vw;
        }
    }

    

    &--dp{
        &::after{
            content: none;
        }
    }
}

// 生産終了製品
.m-itemB{
    width: 22.6%;
    position: relative;
    margin-bottom: 3%;
    display: block;

    @include sp{
        width: 48%;
        margin-bottom: 24/750*100vw;
    }


    &::before{
        content: '販売終了';
        position: absolute;
        top: 0;
        left: 0;
        width: 66px;
        height: 28px;
        background-color: #CF0A2C;
        color: #fff;
        z-index: 1;
        padding: 4px 4px 0px;
        text-align: center;
        font-size: 14px;
        line-height: 1.7;

        @include sp{
            @include vw(22);
            padding: 6/750*100vw 9/750*100vw 0;
            width: 106/750*100vw;
            height: 45/750*100vw;
        }
    }

    &__imgWrap{
        position: relative;
        overflow: hidden;
        padding-top: 60.8%;

        @include sp{
            height: auto;
        }

    }
    &__img{
        position: absolute;
        top:50%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: translateY(-50%);
    }
    &__body{
        height: 126px;
        text-align: center;

        @include sp{
            height: 201/750*100vw;
        }
    }
    &__txt{
        color: $color_05;
        font-size: 14px;
        padding-top: 18px;

        @include sp{
            @include vw(24);
            padding-top: 10/750*100vw;
        }
    }

    &__linkCont{
        position: absolute;
        bottom: 16px;
        left: 12%;

        @include sp{
            left:80/750*100vw;
            bottom: 30/750*100vw;
        }

        a{
            width: 200px;

            @include sp{
                width: 248/750*100vw;
            }

            &::after{
                width: 70px;
                right: -50px;

                @include sp{
                    right: -85/750*100vw;
                }
            }
        }
    }
}

// 業務用製品情報ダウンロード
.m-itemC{
    width: 22.6%;
    position: relative;
    margin-bottom: 3%;
    display: block;

    @include sp{
        width: 48%;
        margin-bottom: 24/750*100vw;
    }

    &__imgWrap{
        position: relative;
        overflow: hidden;
        padding-top: 60.8%;

        @include sp{
            height: auto;
        }

    }
    &__img{
        position: absolute;
        top:50%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: translateY(-50%);
    }
    &__body{
        height: 80px;
        text-align: center;

        @include sp{
            height: 100/750*100vw;
        }
    }
    &__txt{
        color: $color_05;
        font-size: 14px;
        padding-top: 18px;

        @include sp{
            @include vw(24);
            padding-top: 10/750*100vw;
        }
    }
    &__linkCont{
        position: absolute;
        bottom: 16px;
        left: 12%;

        @include sp{
            left:80/750*100vw;
            bottom: 30/750*100vw;
        }

        a{
            width: 200px;

            @include sp{
                width: 248/750*100vw;
            }

            &::after{
                width: 70px;
                right: -50px;

                @include sp{
                    right: -85/750*100vw;
                }
            }
        }
    }

    &__list{
        background-color: $color_07;
    }

    &__item{
        position: relative;
        border-bottom: 1px solid $color_04;
        height: 49px;

        @include sp{
            height: 90/750*100vw;
        }

        &:last-of-type{
            border-bottom: none;
        }

        &::after{
            content: '';
            position: absolute;
            background: url(/assets/img/common/red_arrow_s.svg);
            background-repeat: no-repeat;
            background-size: cover;
            right: 16px;
            top: 50%;
            width: 14px;
            height: 14px;
            transform: translateY(-50%);

            @include sp{
                right: 20/750*100vw;
                width: 20/750*100vw;
                height: 20/750*100vw;
            }
        }

        a{
            color: $color_04;
            font-size: 13px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0 15% 0 5%;

            @include sp{
                @include vw(23);
            }
        }

        span{
            color: $color_04;
            display: block;
            font-size: 10px;
            line-height: 1.2;

            @include sp{
                @include vw(20);
            }
        }

        &--wh{
            background-color:$color_04;

            a{
                color: #000;
                pointer-events: none;
            }

            &::after{
                content: none;
            }
        }

        &--dc{
            background-color:$color_08;

            &::after{
                background: url(/assets/img/common/white_arrow_s.svg);
                background-size: cover;

                @include sp{
                    right: 20/750*100vw;
                    width: 20/750*100vw;
                    height: 20/750*100vw;
                }
            }

            a{
                color: $color_04;
            }
        }

        &--ps{
            background-color:$color_05;

            &::after{
                background: url(/assets/img/common/white_arrow_s.svg);
                background-size: cover;

                @include sp{
                    right: 20/750*100vw;
                    width: 20/750*100vw;
                    height: 20/750*100vw;
                }
            }

            a{
                color: $color_04;
            }
        }

        &--mk{
            background-color: $color_09;

            &::after{
                background: url(/assets/img/common/white_arrow_s.svg);
                background-size: cover;

                @include sp{
                    right: 20/750*100vw;
                    width: 20/750*100vw;
                    height: 20/750*100vw;
                }
            }

            a{
                color: $color_04;
            }
        }
    }
}


.m-itemCont{

    &__inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        @include sp{
        }

        &::before{
            content:"";
            display: block;
            width:22.6%;
            order:1;
        }

        &::after{
            content:"";
            display: block;
            width:22.6%;
        }
    }
}

.m-itemContB{

    &__inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        @include sp{
        }

        &::before{
            content:"";
            display: block;
            width:31.1%;
            order:1;
        }

        &::after{
            content:"";
            display: block;
            width:31.1%;
        }
    }
}