@charset "utf-8";

.m-anchorListCont{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include sp{
    flex-direction: column;
  }
}

.m-anchorList{
  width: 30%;
  margin-top: 80px;

  @include sp{
    width: 484/750*100vw;
    margin: 0 auto 40/750*100vw;
  }

  &--4rows{
    width: 22.6%;

    @include sp{
      width: 484/750*100vw;
      margin: 0 auto 40/750*100vw;
    }
  }

&--wide{
  width: 100%;
}

  &__head{
    padding: 18px 0 15px;
    font-weight: bold;
    font-size: 18px;

    @include sp{
      @include vw(30);
      padding: 24/750*100vw 0 21/750*100vw;
    }
    
    &--img{
      padding: 0;
    }
  }

  &__item{
    position: relative;
    display: block;
    width: 100%;
    border-bottom: 1px solid #C7C7C7;
    font-size: 15px;
    text-align: left;
    padding: 16px 0 15px;
    line-height: 1.2;

    @include sp{
      @include vw(24);
      font-weight: bold;
      padding: 30/750*100vw 0;
    }

    &--w475{
      @include pc{
        max-width: 475px;
      }
    }

    span{
      font-size: 10px;
      color: #747474;
      line-height: 1.4;

      @include sp{
        @include vw(20);
      }
    }

    &::after{
      position: absolute;
      right: 16px;
      top: 50%;
      content: '';
      border-bottom: solid 2px #C7C7C7;
      border-right: solid 2px #C7C7C7;
      width: 9px;
      height: 9px;
      transform: rotate(45deg) translateY(-50%);
    }
  }

  &__ttl{
    @include sp{
      @include vw(30);
      margin-bottom: 14/750*100vw;
    }
  }
}

.m-anchorPc{
  @include pc{
    display: block;
  }

  @include sp{
    display: none;
  }
}

// SP
.m-anchorSp{
  @include pc{
    display: none;
  }
  
  @include sp{
    position: fixed;
    z-index: 99;
    width: 666/750*100vw;
    height: 200vh; 
    top: 370/750*100vw;
    transition: all 0.7s;
    transform: translate(-666/750*100vw);

    &.is-open{
        transform: translate(0);
    }
  }

  &__searchCont{
    background-color: #fff;
  }
  
  &__searchScroll{
    overflow-y: scroll;
    height: 100vh;
    border-right: 1px solid #C7C7C7;
    background-color: #fff;
    margin-right: 5px;
  
    @include sp{
        overflow:visible;
        height: 200vh; 
        border-right: none;
        margin-right: 0;
    }
  
    &::-webkit-scrollbar {
        width: 6px;
        height: 282px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #C7C7C7;
        height: 282px;
        width: 6px;
        margin-right: 6.5px;
        border: solid 5px transparent;
    }
  
  }
  
  &__searchInner{
    width: 262px;
    margin: 0 auto;
    padding: 77px 0 80px;
    background-color: #fff;
  
    @include sp{
        padding: 0 10/750*100vw 244/750*100vw 0;
        width: 100%;
        overflow-y: scroll;
        height: 200vh;        }
  }
  
  &__searchBg{
    @include sp{
        display: none;
        background-color: rgba(0,0,0,.65);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
    }
  }
  
  &__searchTti{
    @include sp{
        border-bottom: 1px solid $color_06;
        width: 666/750*100vw;
        padding: 30/750*100vw 0 25/750*100vw 50/750*100vw;
        position: relative;
        text-align: left;
    }
  }
  
  &__closeBtn{
    display: inline-block;
    width: 70/670*100vw;
    height: 70/670*100vw;
    position: absolute;
    right: 30/670*100vw;
    top: 30%;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
  
    span{
        display: inline-block;
        vertical-align: middle;
        color: $color_05;
        line-height: 1;
        width: 70/670*100vw;
        height: 1px;
        background: currentColor;
        border-radius: 0.1em;
        transform: rotate(45deg);
  
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: inherit;
            border-radius: inherit;
            transform: rotate(90deg);
        }
    }
  }
  
  &__searchItemCont{
    @include sp{
        padding-top: 40/750*100vw;
        padding-bottom: 600/750*100vw;
        width: 100%;
        margin: 0 auto;
        overflow-y: scroll;
        height: 100vh;
        border-right: 1px solid #C7C7C7;
    }
  }
  
  &__searchItem{
    margin-bottom: 10px;
  
    @include sp{
        margin-bottom:18/750*100vw;
    }
  }
  
}
