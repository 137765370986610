@charset "utf-8";
.l-supportBanner {
    width: 100%;
    padding: 100px 0;
    background-color: #F4F3F4;
    @include sp{
        padding: 50px 0;
    }

    &__inner {
        width: 940px;
        margin: 0 auto;
        @include sp{
            width: 100%;
        }
    }

    &__linkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 940px;
        height: 395px;
        overflow: hidden;
        position: relative;
        

        @include sp{
            width: 100%;
            height: 35vh;
            background-image: url("/assets/img/common/customer_support_sp2.jpg");
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:hover .l-supportBanner__linkboxBg {
            transform: scale(1.1);
        }

        &:hover .l-supportBanner__linktxt1--txt1 {
            letter-spacing: 0.05em;
        }

        &:hover .l-supportBanner__linktxt1--txt2 {
            letter-spacing: 0.2em;
        }

        &:hover .l-supportBanner__linktxt1:after {
            width: 90px;
        }

        &:hover .l-supportBanner__linktxt2 {
            letter-spacing: 0.2em;
            gap: 20px;
        }
        

    }

    &__linkboxBg {
        width: 940px;
        height: 395px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("/assets/img/common/customer_support2.jpg");
        background-position: center center;
        transition: .3s all;
        z-index: 8;

        @include sp{
            background: none;
            width: 0;
            height: 0;
        }

    }

    &__linktxt {
        position: absolute;
        color: #fff;
        z-index: 10;
    }

    &__linktxt1 {
        color: #fff;
        
        &--txt1 {
            display: block;
            text-align: center;
            font-size: 40px;
            font-weight: bold;
            transition: .3s all;

            @include sp{
                font-size: 20px;
            }
            
        }

        &--txt2 {
            display: block;
            text-align: center;
            font-size: 20px;
            letter-spacing: 0.12em;
            transition: .3s all;

            @include sp{
                font-size: 15px;
            }

        }

        &:after {
            content: "";
            display: block;
            width: 80px;
            height: 1px;
            margin: 5px auto;
            border-bottom: 1px solid #fff;
            transition: .3s all;

            @include sp{
                width: 60px;
            }
        }
    }

    &__linktxt2 {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 40px;
        text-align: center;
        font-size: 18px;
        z-index: 9;
        letter-spacing: 0.12em;
        transition: .3s all;

        @include sp{
            margin-top: 20px;
            font-size: 14px;
        }

        &:after {
            display: inline-block;
            width: 25px;
            height: 20px;
            margin-top: 5px;
            content: "";
            background-image: url(/assets/img/top/anv50th_history_arw.svg);
            background-repeat: no-repeat;
            background-size: contain;
            transition: .3s all;
        }
    }

    &__snsmailbox {
        display: flex;
        align-items: start;
        margin-top: 90px;
        flex-direction: row;

        @include sp{
            margin-top: 30px;
            flex-direction: column;
        }

        &--left {
            width: 50%;
            padding: 30px 0;
            border-right: 1px solid #707070;

            @include sp{
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                border-right: none;
                border-bottom: 1px solid #707070;
            }
        }

        &--right {
            width: 50%;
            padding: 30px 0;

            @include sp{
                width: 80%;
                margin-left: auto;
                margin-right: auto;
            }

        }
    }

    &__snsTtl {
        text-align: center;
        font-size: 23px;
        font-weight: bold;

        @include sp{
            font-size: 20px;
        }
    }

    &__snsmailtxt {
        margin: 10px auto 0;
        font-size: 14px;
        text-align: center;
    }

    &__snsmailboxInner {
        width: 287px;
        margin-left: auto;
        margin-right: auto;

        @include sp{
            width: 100%;
        }
    }

    &__snsIconBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 25px;
        width: 190px;
        margin: 30px auto 0;

        a:hover {
            opacity: 0.6;
        }

        &__item {
            img {
                width: 100%;
            }

            &--fb{
                width: 15px;
                @include sp{
                    width: 36/750*100vw;
                }
            }

            &--inst{
                width: 28px;
                @include sp{
                    width: 66/750*100vw;
                }
            }

            &--yt{
                width: 42px;
                @include sp{
                    width: 91/750*100vw;
                }
            }

            &--tw{
                width: 30px;
                @include sp{
                    width: 66/750*100vw;
                }
            }

        }
    }

    &__mailTtl {
        display: flex;
        align-items: center;
        gap: 25px;
        width: fit-content;
        justify-content: center;

        @include sp{
            gap: 15px;
            margin-left: auto;
            margin-right: auto;
        }

        &--icon {
            display: inline-block;
            width: 46px;
            height: 36px;
            background-image: url(/assets/img/common/customer_support_mail.svg);
            background-repeat: no-repeat;
            background-size: contain;

            @include sp{
                width: 30px;
                height: 25px;
            }
        }

        &--txt {
            display: inline-block;
            font-size: 23px;
            font-weight: bold;
            vertical-align: middle;

            @include sp{
                font-size: 20px;
            }
        }
    }

    &__mailBtn {
        display: block;
        position: relative;
        width: 100%;
        margin-top: 15px;
        padding: 10px 0;
        border-radius: 100vh;
        border: 1px solid #000;
        background-color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #000;
        transition: .3s all;

        &:hover {
            color: #fff;
            background-color: #000;
        }

        &:hover .l-supportBanner__mailBtn--arw:after {
            background-image: url(/assets/img/common/menu_arw1_wh.svg);
        }

        &--arw:after {
            position: absolute;
            top: calc(50% - 5px);
            right: 20px;
            display: inline-block;
            width: 20px;
            height: 10px;
            content: "";
            background-image: url(/assets/img/common/menu_arw1.svg);
            background-repeat: no-repeat;
            background-size: contain;
            transition: .3s all;
        }
    }
}
