@charset "utf-8";

.p-faq{
    padding: 100px 0 160px;

    @include sp{
        padding: 100/750*100vw 0 160/750*100vw;
    }

    &__main{
        margin-bottom: 80px;

        @include sp{
            margin-bottom: 100/750*100vw;
        }
    }

    &__sec{
        margin-bottom: 10px;

        @include sp{
            margin-bottom: 24/750*100vw;
        }
    }
}

.p-faqInquiry{
    width: 100%;

    &__inner{
        max-width: 782px;
        margin: 0 auto;
        padding: 60px 0 80px;

        @include sp{
            max-width: 615/750*100vw;
            padding: 80/750*100vw 0;
        }
    }

    &__ttl{
        @extend .m-txt-bold;
        @extend .m-lineTtl;
        @extend .m-mb24;
    }

    &__txt{
        @extend .m-txt-fs14B;
    }

    &__note{
        @extend .m-txt-fs14;
        text-align: center;
        margin-top: 20px;

        @include sp{
            margin-top: 40/750*100vw;
        }
    }
}