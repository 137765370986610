@charset "utf-8";
.l-main{
    padding-top: 80px;

    @include sp{
        // padding-top: 266/750*100vw;
        padding-top: 79px;
        min-width: 100%;
    }

    &__no-kv {
        @include sp{
            padding-top: 80px;
            // padding-top: 280/750*100vw;
        }
    }
}
