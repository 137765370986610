@charset "utf-8";

.m-videoCont{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include sp {
    display: block;
  }
}

.m-video {
  width: 475px;

  @include sp {
    width: 690/750*100vw;
  }

  &:nth-of-type(odd) {
    @include sp {
      margin-bottom: 80/750*100vw;
    }
  }

  

  &__box {}

  &__thumbCont {
    position: relative;
  }

  &__thumb {
    display: block;
    margin-bottom: 24px;
    overflow: hidden;
    width: 475px;
    height: 290px;

    @include sp {
      width: 690/750*100vw;
      height: 420/750*100vw;
    }

    img {
      width: 100%;
      transition: all .4s ease-out;
    }

    &:hover {
      opacity: 1;

      img {
        transform: scale(1.15);
        transition: all .4s ease-out;
      }

      .m-video__icon {
        background: url(/assets/img/common/icon_video_on.svg) no-repeat center center;
        width: 71px;
        height: 71px;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.is-active {
      opacity: 1;

      img {
        transform: scale(1.15);
        transition: all .4s ease-out;
      }

      .m-video__icon {
        background: url(/assets/img/common/icon_video_on.svg) no-repeat center center;
        width: 71px;
        height: 71px;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }


  }

  &__icon {
    background: url(/assets/img/common/icon_video.svg) no-repeat center center;
    width: 71px;
    height: 71px;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include sp {
      width: 147/750*100vw;
      height: 147/750*100vw;
    }
  }

  &__ttl {
    margin-bottom: 40px;

    @include sp {
      margin-bottom: 24/750*100vw;
    }
  }

  &__head {
    margin-bottom: 10px;
  }

  &__lead {
    margin-bottom: 24px;
    white-space: nowrap;
  }

  &__txt {
    letter-spacing: 0;
  }
}
