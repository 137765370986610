@charset "utf-8";
// 製品ページのスライドの色切り替えボタン

.mainColorBtn{
  position: relative;
  display: block;
  width: 26px;
  height: 26px;
  // margin-right: 54px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  text-align: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
  border-radius: 50%;
  

  @include sp{
    // margin-right: 90/750*100vw;
    // margin-bottom: 130/750*100vw;
  }

  &--txt {
    // position: absolute;
    // top: 39px;
    // left: 50%;
    padding-top: 14px;
    // transform: translate(-50%, 0);
    display: block;
    margin: 0 5px;
    text-wrap: nowrap;
    font-size: 15px;
    line-height: 1.2;
    font-weight: bold;
    text-align: center;

    @include sp{
      font-size: 12px;
    }
    
  }

  &.color4{
    @include sp{
      margin-right: 83/750*100vw;
      margin-bottom: 140/750*100vw;
    }

  }

  &.select{
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 38px;
      height: 38px;
      border: 1px solid #707070;
      border-radius: 50%;
    }
  }

  &::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 38px;
    height: 38px;
    border: 1px solid transparent;
    border-radius: 50%;
  }

  &::after{
    // position: absolute;
    // top: 39px;
    // left: 50%;
    // transform: translateX(-50%);
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    line-height: 1.2;

    @include sp{
      @include vw(24);
    }
  }

  // 色の種類に応じて以下に追加する

  &--black{
    background-color: #000;
    border: 1px solid #FFF;

    &::after{
      content: 'ブラック';
    }
  }

  &--white{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: 'ホワイト';
    }
  }

  &--jetblack{
    background-color: rgb(90,90,100);

    &::after{
      content: 'ジェット\Aブラック';
      white-space: pre;
    }
  }

  &--rubyred{
    background-color: rgb(225,30,50);

    &::after{
      content: 'ルビー\Aレッド';
      white-space: pre;
    }
  }

  &--offWhite{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: 'オフホワイト';
    }
  }

  &--citronGreen{
    background-color: rgb(120,110,60);

    &::after{
      content: 'シトロン\Aグリーン';
      white-space: pre;
    }
  }

  &--turquoiseBlue{
    background-color: rgb(85,115,130);

    &::after{
      content: 'ターコイズ\Aブルー';
      white-space: pre;
    }
  }

  &--copper{
    background-color: rgb(207,150,130);

    &::after{
      content: 'カッパー';
    }
  }

  &--stainless{
    background-color: rgb(205,205,205);
    border: 1px solid #C7C7C7;

    &::after{
      content: 'ステンレス';
    }
  }

  &--smokyBlack{
    background-color: rgb(0, 0, 0);

    &::after{
      content: 'スモーキー\Aブラック';
      white-space: pre;
    }
  }

  &--metallicBlue{
    background-color: rgb(90, 140, 170);

    &::after{
      content: 'メタリック\Aブルー';
      white-space: pre;
    }
  }

  &--natural{
    background-color: #dcc8a0;

    &::after{
      content: 'ナチュラル';
    }
  }

  &--dark{
    // background-color: #64553c;
      background-color: rgb(50, 50, 50);
      border: 1px solid #C7C7C7;

    &::after{
      content: 'ダーク';
      white-space: pre;
    }
  }

  &--mahogany{
    background-color: #69413c;

    &::after{
      content: 'マホガニー';
    }
  }

  &--cooper{
    background-color: #cf9682;

    &::after{
      content: 'カッパー';
    }
  }

   &--darkWalnut{
    //  background-color: #d37515;
    background-color: rgb(50, 50, 50);
    border: 1px solid #C7C7C7;

    &::after{
       content: 'ダーク\Aウォルナット';
       color: #fff;
       white-space: pre;
     }
   }
   &--whiteOak{
    //  background-color: #f9dcaa;
    background-color: #e3e3e3;

     &::after{
       content: 'ホワイト\Aオーク';
       color: #fff;
       white-space: pre;
      //  font-size: 13px;
     }
   }
   &--dynamicDark{
    //  background-color: #888b8a;
    background-color: rgb(50, 50, 50);
    border: 1px solid #C7C7C7;

    &::after{
       content: 'ダイナミック\Aダーク';
       color: #fff;
       white-space: pre;
      //  font-size: 13px;
     }
   }
   &--dynamicWhite{
     background-color: #e1e1e2;

     &::after{
       content: 'ダイナミック\Aホワイト';
       color: #fff;
       white-space: pre;
      //  font-size: 13px;
      margin-left:7px;
     }
   }
   &--totalDark{
     background-color: #1f1f1f;
     border: 1px solid #fff;

     &::after{
       content: 'トータル\Aダーク';
       color: #fff;
       white-space: pre;
      //  font-size: 13px;
     }
   }
   &--totalWhite {
     background-color: #e3e3e3;

     &::after{
       content: 'トータル\Aホワイト';
       color: #fff;
       white-space: pre;
      //  font-size: 13px;
     }
   }

   &--sparklingRed {
    background-color: #b13c42;

    &::after{
      content: 'スパーク\Aリング\Aレッド';
      color: #fff;
      white-space: pre;
      // font-size: 13px;
    }
  }
  &--arcticWhite {
    background-color: #e1e5eb;

    &::after{
      content: 'アーク\Aティック\Aホワイト';
      color: #fff;
      white-space: pre;
      // font-size: 13px;
    }
  }
  &--blackTextW {
    background-color: #000;
    border: 1px solid #fff;

    &::after{
      content: 'ブラック';
      color: #fff;
      // font-size: 13px;
    }
  }
  &--matteBlack {
    background-color: #171717;
    border: 1px solid #fff;

    &::after{
      content: 'マット\Aブラック';
      color: #fff;
      white-space: pre;
      // font-size: 13px;
    }
  }
  &--Black54 {
    background-color: #000;
    border: 1px solid #FFF;

    &::after{
      content: 'ブラック\A 54mm';
      color: #fff;
      white-space: pre;
      // font-size: 13px;
    }
  }
  &--Black58 {
    background-color: #000;
    border: 1px solid #FFF;

    &::after{
      content: 'ブラック\A 58mm';
      color: #fff;
      white-space: pre;
      // font-size: 13px;
    }
  }
  &--matteWhite {
    background-color: #f4f4f4;
    border: 1px solid #fff;

    &::after{
      content: 'マット\Aホワイト';
      color: #fff;
      white-space: pre;
      // font-size: 13px;
    }
  }
  &--White54 {
    background-color: #fff;
    border: 1px solid #C7C7C7;

    &::after{
      content: 'ホワイト\A 54mm';
      color: #fff;
      white-space: pre;
      // font-size: 13px;
    }
  }
  &--White58 {
    background-color: #fff;
    border: 1px solid #C7C7C7;

    &::after{
      content: 'ホワイト\A 58mm';
      color: #fff;
      white-space: pre;
      // font-size: 13px;
    }
  }
  &--rose {
    background-color: #ffc7cc;

    &::after{
      content: 'ローズ';
      color: #fff;
    }
  }
  &--oak {
    background-color: #d5ae79;
    &::after{
      content: 'オーク';
      color: #fff;
    }
  }
  &--walnut {
    background-color: #9a775f;
    &::after{
      content: 'ウォルナット';
      color: #fff;
    }
  }


   // 色 + 種類

  // ナチュラルホワイト40枚
  &--nWhite40{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: 'ナチュラル\Aホワイト\A 40枚';
      white-space: pre;
    }
  }

  // ナチュラルホワイト80枚
  &--nWhite80{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: 'ナチュラル\Aホワイト\A 80枚';
      white-space: pre;
    }
  }

  // ナチュラルホワイト100枚
  &--nWhite100{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: 'ナチュラル\Aホワイト\A 100枚';
      white-space: pre;
    }
  }

  // ナチュラルブラウン40枚
  &--nBrown40{
    background-color: rgb(200,175,130);

    &::after{
      content: 'ナチュラル\Aブラウン\A 40枚';
      white-space: pre;
    }
  }

  // ナチュラルブラウン80枚
  &--nBrown80{
    background-color: rgb(200,175,130);

    &::after{
      content: 'ナチュラル\Aブラウン\A 80枚';
      white-space: pre;
    }
  }

  // ナチュラルブラウン100枚
  &--nBrown100{
    background-color: rgb(200,175,130);

    &::after{
      content: 'ナチュラル\Aブラウン\A 100枚';
      white-space: pre;
    }
  }

  // 1×1 40枚
  &--nBrown11_40{
    background-color: rgb(200,175,130);

    &::after{
      content: '1×1\A 40枚';
      white-space: pre;
    }
  }

  // 1×2 40枚
  &--nBrown12_40{
    background-color: rgb(200,175,130);

    &::after{
      content: '1×2\A 40枚';
      white-space: pre;
    }
  }

  // 1×2 100枚
  &--nBrown12_100{
    background-color: rgb(200,175,130);

    &::after{
      content: '1×2\A 100枚';
      white-space: pre;
    }
  }

  // 1×4 40枚
  &--nBrown14_40{
    background-color: rgb(200,175,130);

    &::after{
      content: '1×4\A 40枚';
      white-space: pre;
    }
  }

  // 70mm（MJ-1001）
  &--70mm（MJ-1001）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '70mm\A（MJ-1001）';
    }
  }

  // 75mm（MJ-1002）
  &--75mm（MJ-1002）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '75mm\A（MJ-1002）';
    }
  }

  // 1.5oz (43ml)（MJ-1005）
  &--15oz（43ml）（MJ-1005）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '1.5oz (43ml)\A（MJ-1005）';
    }
  }

  //2oz (57ml)（MJ-1015）
  &--2oz（57ml）（MJ-1015）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '2oz (57ml)\A（MJ-1015）';
    }
  }

    // 300cc
  &--300cc（HC7019）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '300cc\A（HC7019）';
    }
  }

   // 600cc
  &--600cc（HC7020）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '600cc\A（HC7020）';
    }
  }

   // 1000cc
  &--1000cc（HC7021）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '1000cc\A（HC7021）';
    }
  }

  // 300cc
  &--300cc（HC7068）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '300cc\A（HC7068）';
    }
  }

    // 600cc
  &--600cc（HC7069）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '600cc\A（HC7069）';
    }
  }

    // 1000cc
  &--1000cc（HC7070）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: '1000cc\A（HC7070）';
    }
  }

   // 300ml
   &--300ml{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: '300ml';
    }
  }

   // 500ml
   &--500ml{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: '500ml';
    }
  }

  // 600ml
   &--600ml{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: '600ml';
    }
  }

  // 800ml
  &--800ml{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: '800ml';
    }
  }

  // 1000ml
  &--1000ml{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: '1000ml';
    }
  }

  // アロマフィルター
  &--aroma11{
    background-color: rgb(225,205,190);

    &::after{
      content: '1×1';
    }
  }

  &--aroma12{
    background-color: rgb(225,205,190);

    &::after{
      content: '1×2';
    }
  }

  &--aroma14{
    background-color: rgb(225,205,190);

    &::after{
      content: '1×4';
    }
  }

  // クリアフィルター
  &--clear11{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: '1×1';
    }
  }

  &--clear12{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: '1×2';
    }
  }

  &--clear14{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: '1×4';
    }
  }

  // ステンレスキャニスター
  &--sakura{
    background-color: #be8c64;

    &::after{
      content: 'さくら蓋';
    }
  }

  &--clear{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      content: '透明蓋';
    }
  }

  // バスケットフィルターペーパー仕様
  &--バスケットフィルターペーパー仕様{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: 'バスケットフィルター\Aペーパー仕様';
    }
  }

  // バスケットフィルターペーパー（M151B）
  &--バスケットフィルターペーパー（M151B）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: 'バスケット\Aフィルター\Aペーパー\A（M151B）';
    }
  }

  // ピラミッドフィルターペーパー（M150P）
  &--ピラミッドフィルターペーパー（M150P）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: 'ピラミッド\Aフィルター\Aペーパー\A（M150P）';
    }
  }

  // バスケットフィルターペーパー（GWB）
  &--バスケットフィルターペーパー（GWB）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: 'バスケット\Aフィルター\Aペーパー\A（GWB）';
    }
  }

  // ピラミッドフィルターペーパー（GWC）
  &--ピラミッドフィルターペーパー（GWC）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: 'ピラミッド\Aフィルター\Aペーパー\A（GWC）';
    }
  }

  // バスケットフィルターペーパー（TWB）
  &--バスケットフィルターペーパー（TWB）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: 'バスケット\Aフィルター\Aペーパー\A（TWB）';
    }
  }

  // ピラミッドフィルターペーパー（TWC）
  &--ピラミッドフィルターペーパー（TWC）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: 'ピラミッド\Aフィルター\Aペーパー\A（TWC）';
    }
  }

  // W176xD188xH120（MJ-1003）
  &--W176xD188xH120（MJ-1003）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: 'W176xD188\AxH120\A（MJ-1003）';
    }
  }

  // W167xD267xH125（MJ-1004）
  &--W167xD267xH125（MJ-1004）{
    background-color: #FFF;
    border: 1px solid #C7C7C7;

    &::after{
      white-space:pre;
      content: 'W167xD267\AxH125\A（MJ-1004）';
    }
  }

    // グラインツ
    &--mc-1301{
      background-color: #FFF;
      border: 1px solid #C7C7C7;

      &::after{
        content: '35g x3\A（MC-1301）';
        white-space: pre;
      }
    }

    &--mc-1302{
      background-color: #FFF;
      border: 1px solid #C7C7C7;

      &::after{
        content: '430g\A（MC-1302）';
        white-space: pre;
      }
    }

    // コーヒーフィルター ブラウン追加
    &--brown{
      background-color: #20120A;

      &::after{
        content: 'ブラウン';
      }
    }

    // セミオートコーヒーマシーン　ダークオーク追加
    &--darkOak{
      background-color: rgb(50, 50, 50);
      border: 1px solid #C7C7C7;

      &::after{
        content: 'ダーク\Aオーク';
        white-space: pre;
      }
    }

    // ステンレス（文字色_白）
    &--stainless_wh{
      background-color: rgb(205,205,205);
      border: 1px solid #C7C7C7;

      &::after{
        content: 'ステンレス';
      }
    }

    // エスプレッソマシーン　ネイビーブルー追加
    &--navyBlue{
      background-color: #1f2e55;
      border: 1px solid #1f2e55;

      &::after{
        content: 'ネイビー\Aブルー';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　ネイビーブルーオーク追加
    &--navyBlueOak{
      background-color: #1f2e55;
      border: 1px solid #1f2e55;

      &::after{
        content: 'ネイビー\Aブルー\Aオーク';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　ネイビーブルーウォルナット追加
    &--navyBlueWalnut{
      background-color: #1f2e55;
      border: 1px solid #1f2e55;

      &::after{
        content: 'ネイビー\Aブルー\Aウォルナット';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　オリーブグリーン追加
    &--oliveGreen{
      background-color: #5f651e;
      border: 1px solid #5f651e;

      &::after{
        content: 'オリーブ\Aグリーン';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　オリーブグリーンオーク追加
    &--oliveGreenOak{
      background-color: #5f651e;
      border: 1px solid #5f651e;

      &::after{
        content: 'オリーブ\Aグリーン\Aオーク';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　オリーブグリーンウォルナット追加
    &--oliveGreenWalnut{
      background-color: #5f651e;
      border: 1px solid #5f651e;

      &::after{
        content: 'オリーブ\Aグリーン\Aウォルナット';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　トータルブラック追加
    &--totalBlack{
      background-color: rgb(50, 50, 50);
      border: 1px solid #C7C7C7;
      // background-color: #000;
      // border: 1px solid #000;

      &::after{
        content: 'トータル\Aブラック';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　ホワイトウォルナット追加
    &--whiteWalnut{
      // background-color: #d37515;
      // border: 1px solid #d37515;
      background-color: #e3e3e3;

      &::after{
        content: 'ホワイト\Aウォルナット';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　ブレイズレッド追加
    &--blazeRed{
      background-color: #E81300;
      border: 1px solid #E81300;

      &::after{
        content: 'ブレイズ\Aレッド';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　アイスブルー追加
    &--iceBlue{
      background-color: #CCCCFF;
      border: 1px solid #CCCCFF;

      &::after{
        content: 'アイス\Aブルー';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　ミントグリーン追加
    &--mintGreen{
      background-color: #89c997;
      border: 1px solid #89c997;

      &::after{
        content: 'ミント\Aグリーン';
        white-space: pre;
      }
    }

    // エスプレッソマシーン　ムーングレイ追加
    &--moonGray{
      background-color: #d4d9dc;
      border: 1px solid #d4d9dc;

      &::after{
        content: 'ムーン\Aグレイ';
        white-space: pre;
      }
    }

    // 10杯用
    &--For10cups{
      background-color: #fff;
      border: 1px solid #C7C7C7;

      &::after{
        content: '10杯用';
        white-space: pre;
      }

    }
    // 6杯用
    &--For6cups{
      background-color: #fff;
      border: 1px solid #C7C7C7;

      &::after{
        content: '6杯用';
        white-space: pre;
      }

    }
    // X54 クローム追加
    &--chrome{
      background-color: #C7C7C7;
      border: 1px solid #C7C7C7;

      &::after{
        content: 'クローム';
        white-space: pre;
      }
    }
    // グレイ
    &--gray{
      background-color: rgb(205,205,205);
      border: 1px solid #C7C7C7;

      &::after{
        content: 'グレー';
      }
    }
    
    // For PUQpress Mini tamper 58.3mm
    &--tamper583{
      background-color: #fff;
      border: 1px solid #fff;

      &::after{
        content: '58.3mm';
      }
    }

    // For PUQpress Mini tamper 58.0mm
    &--tamper580{
      background-color: #fff;
      border: 1px solid #fff;

      &::after{
        content: '58.0mm';
      }
    }

    // For PUQpress Mini tamper 57.3mm
    &--tamper573{
      background-color: #fff;
      border: 1px solid #fff;

      &::after{
        content: '57.3mm';
      }
    }

    // For PUQpress Mini tamper 54.3mm
    &--tamper543{
      background-color: #fff;
      border: 1px solid #fff;

      &::after{
        content: '54.3mm';
      }
    }

    // For PUQpress Mini tamper 53.0mm
    &--tamper530{
      background-color: #fff;
      border: 1px solid #fff;

      &::after{
        content: '53.0mm';
      }
    }

    // For DALLA CORTE シングルフィルター23mm
    &--single23mm {
      background-color: #fff;
      border: 1px solid #fff;
      &::after{
        content: '23mm';
      }
    }

    // For DALLA CORTE シングルフィルター25mm
    &--single25mm {
      background-color: #fff;
      border: 1px solid #fff;
      &::after{
        content: '25mm';
      }
    }

    // For DALLA CORTE ダブルフィルター24mm
    &--double24mm {
      background-color: #fff;
      border: 1px solid #fff;
      &::after{
        content: '24mm';
      }
    }

    // For DALLA CORTE ダブルフィルター26mm
    &--double26mm {
      background-color: #fff;
      border: 1px solid #fff;
      &::after{
        content: '26mm';
      }
    }

    // For DALLA CORTE ダブルフィルター28mm
    &--double28mm {
      background-color: #fff;
      border: 1px solid #fff;
      &::after{
        content: '28mm';
      }
    }

    // For DALLA CORTE ダブルフィルター30mm
    &--double30mm {
      background-color: #fff;
      border: 1px solid #fff;
      &::after{
        content: '30mm';
      }
    }


}
