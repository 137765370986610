@charset "utf-8";

.p-news{
    padding: 100px 0 60px;

    @include sp{
        padding: 100/750*100vw 0 60/750*100vw;
    }

    &__sec{
        margin-bottom: 68px;

        @include sp{
            margin-bottom: 76/750*100vw;
        }
    }

    &__flex{
        display: flex;
        justify-content: space-between;

        @include sp{
            display: block;
            padding-top: 110/750*100vw;
        }
    }
}
