@charset "utf-8";
.l-showroombnr{
    width: 480px;
    margin-top: 30px;

    @include sp{
        width: 100%;
        margin-top: 80/750*100vw;
    }

    img {
        width: 100%;
    }
}