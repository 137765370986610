@charset "utf-8";

.p-manual{
    padding: 100px 0 160px;

    @include sp{
        padding: 100/750*100vw 0 160/750*100vw;
    }

    &__sec{
        margin-bottom: 50px;

        @include sp{
            margin-bottom: 90/750*100vw;
        }
    }

    &__flex{
        display: flex;
        justify-content: space-between;

        @include sp{
            display: block;
            padding-top: 110/750*100vw;
        }
    }
}
