@charset "utf-8";

.u-color-ffffff {
    color: #ffffff !important;
}

.u-color-000000 {
    color: #000000 !important;
}

.u-color-CF0A2C {
    color: #CF0A2C !important;
}
