@charset "utf-8";

.p-afterSupport{
    padding: 100px 0 160px;

    @include sp{
        padding: 100/750*100vw 0 160/750*100vw;
    }

    // ページ内リンク
    &__pageLink {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        list-style: none;
        @include sp{
            flex-direction: column;
            align-items: start;
            font-size: 15px;
        }

        &--pc {
            @include sp{
                display: none;
            }
        }

        &--sp {
            @include pc{
                display: none;
            }
        }

        li {
            padding:0 15px;
            a {
                position: relative;
                z-index: 2;
                @include sp{
                    font-size: 12px;
                }
            }
        }
    }

    &__catTitle {
        width: 783px;
        padding: 1rem 3rem;
        margin: 0 auto;
        text-align: center;
        background-color: $color_07;
        color: $color_04;
        @include sp{
            width: 78.4vw;
            padding: 0.5rem 3rem;
        }
    }

    &__catTitleTxt {
        margin-top: 10px;
        text-align: center;
    }

    // リンク先、固定ヘッダによるズレの調整
    &__link-location-adjust {
        position: relative;
        display: block;
        margin-top: -135px;
        padding-top: 135px;
        width: 1px; // 前要素の選択不可を回避
        // z-index: 1;
        @include sp{
            margin-top: -142px;
            padding-top: 142px;
        }

    }

    &__yellow-marker-bg {
        background-color: #ff3;
    }
}

.p-afterSupport.p-afterSupport__page-link-navi {
    padding-top: 40px;
    @include sp{
        padding: 35/750*100vw 0 160/750*100vw;
    }
}

.p-afterSupportTopNavi {
    display: flex;
    justify-content: center;
    @include sp{
        width: 90%;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }

    &__link {
        display: block;
        width: 250px;
        padding: 0.5rem 2rem;
        margin: 10px;
        background-color: $color_07;
        color: $color_04;
        @include sp{
            width: 80%;
            padding: 0.5rem 0.5rem;
            margin-bottom: 5px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}