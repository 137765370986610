@charset "utf-8";

.p-about{
    padding: 120px 0 160px;

    @include sp{
        padding: 160/750*100vw 0;
    }

    &__slide{
        padding-bottom: 100px;

        @include sp{
            padding-bottom: 100/750*100vw;
        }
    }
    
}

.p-aboutInfo{
    margin-bottom: 120px;

    @include sp{
        margin-bottom: 120/750*100vw;
    }

    &:first-of-type{
        margin-bottom: 40px;

        @include sp{
            margin-bottom: 40/750*100vw;
        }
    }
    &:nth-of-type(2){
        margin-bottom: 40px;

        @include sp{
            margin-bottom: 40/750*100vw;
        }
    }

    &__cont{
        border-top: 1px solid #C7C7C7;

        @include sp{
            border-top: 1px solid #707070;
        }
    }
    &__head{
        padding-bottom: 16px;

        @include sp{
            padding-bottom: 40/750*100vw;
        }
    }
    &__lineInner{
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid #C7C7C7;
        position: relative;

        @include sp{
            padding: 40/750*100vw 0;
            display: block;
            border-bottom: 1px solid #707070;
        }
    }
    &__ttl{
        @extend .m-txt-fs14;
        max-width: 165px;
        width: 100%;

        @include sp{
            max-width: 100%;
        }
    }
    &__txt{
        @extend .m-txt-fs14;
    }
    &__map{
        @extend  .m-lineLinkCont;
        @extend  .u-ft-gs;
        max-width: 94px;
        width: 100%;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        @include sp{
            position: absolute;
            top: 40/750*100vw;
            right: 0;
        }
    }
    &__lineLink{
        @extend  .m-lineLinkS;
        @include sp{
            @include vw(24);
        }
    }
    &__policystatement{
        padding-left: 20px;
        @include sp{
            padding-left: 40/750*100vw;
            padding-right: 40/750*100vw;
        }

        p.indent{
            padding-left:1em;
            // text-indent:-1em;
          }
    }
}

.p-aboutLink{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 990px;
    margin: 0 auto;
    @include sp{
        display: block;
        width: 690/750*100vw;
    }
}
