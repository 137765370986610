@charset "utf-8";

.m-headBox{
    margin-bottom: 10px;
    @include sp{
        margin-bottom: 20/750*100vw;
    }
        
    &__inner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 768px;
        padding: 77px 0;
        margin: 0 auto;

        @include sp{
            display: block;
            width: 588/750*100vw;
            padding: 80/750*100vw 0 120/750*100vw;
        }
    }

    &__img{

        &--engineer{
            width: 149px;
            height: 195px;
            margin-top: -24px;

            @include sp{
                width: 213/750*100vw;
                height: 279/750*100vw;
                margin: 0 auto 60/750*100vw;
            }
        }

        &--sales{
            width: 149px;
            height: 156px;

            @include sp{
                width: 266/750*100vw;
                height: 278/750*100vw;
                margin: 0 auto 60/750*100vw;
            }
        }

        &--manager{
            width: 149px;
            height: 163px;

            @include sp{
                width: 254/750*100vw;
                height: 278/750*100vw;
                margin: 0 auto 60/750*100vw;
            }
        }

        img{
            width: 100%;
        }
    }

    &__txtBox{
        width: 574px;

        @include sp{
            width: 100%;
        }
    }

    &__ttl{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 16px;

        @include sp{
            @include vw(30);
            text-align: center;
            margin-bottom: 40/750*100vw;
        }

        span{
            font-size: 16px;
            display: inline-block;
            margin-left: 16px;
            font-weight: 300;

            @include sp{
                @include vw(26);
                margin-left: 0;
                display: block;
            }
        }
    }
}
